import React, { FC } from "react";
import useIsUserLoggedIn from "../views/_behaviors/useIsUserLoggedIn";
import { Navigate, generatePath } from "react-router-dom";
import ROUTES from "./routes";

interface AuthenticationMiddlewareProps {
  children: JSX.Element;
  locale: string;
}

const AuthenticationMiddleware: FC<AuthenticationMiddlewareProps> = ({ children, locale }) => {
  const isUserLoggedIn = useIsUserLoggedIn();

  return isUserLoggedIn === undefined ? null : isUserLoggedIn ? (
    children
  ) : (
    <Navigate to={generatePath(ROUTES.LOGIN, { locale })} />
  );
};

export default AuthenticationMiddleware;
