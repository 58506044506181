import {
  OrderLinePreviewResponseDto,
  OrderLineResponseDto,
} from "../../core/infrastructure/projection/orderLine/model/responseMapper";
import { ProductDto } from "../../core/infrastructure/projection/product/model/responseMapper";
import { ProductDto as RepositoryProductDto } from "../../core/infrastructure/domain/product/model/responseMapper";
import { SaleCurrency } from "../../core/projection/currency/model/Currency";
import Market from "../../core/projection/market/model/Market";
import {
  PricePropertiesEnum,
  PricePropertiesInterface,
  Prices,
  SellingPrice,
  SellingPrices,
} from "../../core/projection/prices/model/Prices";
import { OrderLineResponseDto as RepositoryOrderLineResponseDto } from "../../core/infrastructure/domain/orderLine/model/responseMapper";

type SellingPriceDto =
  | OrderLineResponseDto
  | ProductDto
  | OrderLinePreviewResponseDto
  | RepositoryOrderLineResponseDto
  | RepositoryProductDto;

const convertPricesToDTO = (prices: Prices): PricePropertiesInterface => {
  return Object.keys(SaleCurrency).reduce((acc, key) => {
    const objectKey = key as keyof typeof SaleCurrency;
    const propertyKey = PricePropertiesEnum[objectKey];
    const propertyValue = prices[objectKey];

    acc[propertyKey] = propertyValue;

    return acc;
  }, {} as PricePropertiesInterface);
};

// TODO: We are using any there are two different types of DTOs for OrderLineResponseDto
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertDTOToPrices = (dto: any): Prices => {
  return Object.keys(SaleCurrency).reduce((acc, key) => {
    const objectKey = key as keyof typeof SaleCurrency;
    const propertyKey = PricePropertiesEnum[objectKey];
    const propertyValue = dto[propertyKey];

    acc[objectKey] = propertyValue;

    return acc;
  }, {} as Prices);
};

const convertDTOToSellingPrices = (dto: SellingPriceDto): SellingPrices => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { selling_prices } = dto;

  return selling_prices.reduce((acc: SellingPrices, sellingPrice) => {
    acc[sellingPrice.market] = sellingPrice.price_amount;

    return acc;
  }, {} as SellingPrices);
};

const convertSellingPricesToDTO = (sellingPrices: SellingPrices): SellingPrice[] => {
  return Object.keys(sellingPrices).map(key => {
    const market = key as Market;
    const price = sellingPrices[market];

    return {
      market,
      price_amount: price,
    };
  });
};

const generateFakerDataPrices = (value: number): Prices => {
  return Object.keys(SaleCurrency).reduce((acc, key) => {
    const objectKey = key as keyof typeof SaleCurrency;
    acc[objectKey] = value;

    return acc;
  }, {} as Prices);
};

const generateFakerDtoDataPrices = (value: number): PricePropertiesInterface => {
  return Object.keys(SaleCurrency).reduce((acc, key) => {
    const objectKey = key as keyof typeof SaleCurrency;
    const propertyKey = PricePropertiesEnum[objectKey];

    acc[propertyKey] = value;

    return acc;
  }, {} as PricePropertiesInterface);
};

export {
  convertDTOToPrices,
  convertPricesToDTO,
  convertDTOToSellingPrices,
  convertSellingPricesToDTO,
  generateFakerDtoDataPrices,
  generateFakerDataPrices,
};
