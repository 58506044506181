import { QueryHandler } from "@lookiero/messaging.js";
import OrderLineView from "../../model/OrderLineView";
import CountOrderLinesByCriteria from "./CountOrderLinesByCriteria";

class CountOrderLinesByCriteriaHandler implements QueryHandler<CountOrderLinesByCriteria> {
  private readonly orderLineView: OrderLineView;

  public constructor(orderLineView: OrderLineView) {
    this.orderLineView = orderLineView;
  }

  public async handle({ criteria }: CountOrderLinesByCriteria): Promise<number> {
    return await this.orderLineView.countByCriteria(criteria);
  }
}

export default CountOrderLinesByCriteriaHandler;
