import React from "react";
import { MessagingRoot } from "@lookiero/messaging.js";
import { EnvironmentProvider } from "../../_behaviors/useEnvironment";
import Environment from "@src/core/projection/environment/model/Environment";
import { UseInjectComponentProvider } from "@src/shared/ui/behaviors/useInjectComponent/useInjectComponent";
import { QueryParametersProvider } from "@src/ui/componentLibrary/_behaviors/useQueryParameters";
import { RoleProvider } from "@src/ui/componentLibrary/_behaviors/useRole";
import { UseItemSelectionProvider } from "@src/ui/componentLibrary/_behaviors/useItemSelection";
import QueryClientRetriever, { MessagingQueryClient } from "../../queryClientRetriever/QueryClientRetriever";
import UserProvider from "../../_behaviors/userProvider";
import Routing from "@src/ui/_routing/Routing";
import { Loader } from "@lookiero/react-ui-kit";

import "./root.css";
import NotificationsContainer from "../../notifications/NotificationsContainer";

interface RootProps {
  readonly MessagingRootComponent: MessagingRoot;
  readonly environment: Environment;
  readonly queryClientRetriever?: (queryClient: MessagingQueryClient) => void;
}

const Root: React.FC<RootProps> = ({ MessagingRootComponent, environment, queryClientRetriever }) => {
  return (
    <EnvironmentProvider environment={environment}>
      <MessagingRootComponent>
        {queryClientRetriever ? <QueryClientRetriever retriever={queryClientRetriever} /> : null}
        <UseInjectComponentProvider>
          <QueryParametersProvider>
            <RoleProvider>
              <UseItemSelectionProvider>
                <UserProvider>
                  <div className="root" data-testid="root-node">
                    <React.Suspense fallback={<Loader />}>
                      <Routing />
                    </React.Suspense>
                    <NotificationsContainer />
                  </div>
                </UserProvider>
              </UseItemSelectionProvider>
            </RoleProvider>
          </QueryParametersProvider>
        </UseInjectComponentProvider>
      </MessagingRootComponent>
    </EnvironmentProvider>
  );
};

export default Root;
