import HttpClient from "@src/shared/delivery/HttpClient";
import NecklineView from "@src/core/projection/neckline/model/NecklineView";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpNecklineView implements NecklineView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async searchByFamily(familyId: string): Promise<string[]> {
    try {
      const response = await this.httpClient.post("/search-necklines-by-family", { family_id: familyId });

      if (!response.ok) {
        throw new Error("obtener la lista de escotes del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener la lista de escotes del servicio");
    }
  }
}

export default HttpNecklineView;
