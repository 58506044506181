import { QueryHandler } from "@lookiero/messaging.js";
import User from "../../model/User";
import UserView from "../../model/UserView";
import GetCurrentUser from "./GetCurrentUser";

class GetCurrentUserHandler implements QueryHandler<GetCurrentUser> {
  private readonly view: UserView;

  public constructor(view: UserView) {
    this.view = view;
  }

  public handle(): Promise<User> {
    return this.view.getCurrentUser();
  }
}

export default GetCurrentUserHandler;
