import React, { useEffect } from "react";
import Notification from "@src/core/projection/notification/model/Notification";
import NotificationsConfig from "../_config/notifications";
import Notifications from "@src/ui/componentLibrary/organisms/Notifications/Notifications";
import useListNotifications from "./behaviors/useListNotifications";
import useRemoveNotification from "./behaviors/useRemoveNotification";

const NotificationsContainer: React.FC = () => {
  const { notifications } = useListNotifications();
  const { removeNotification } = useRemoveNotification();

  useEffect(() => {
    const now = Date.now();
    const timeouts = notifications.map((notification: Notification) =>
      setTimeout(
        () => removeNotification(notification.id),
        Math.max(0, (notification.timestamp || 0) + NotificationsConfig.DEFAULT_DURATION - now),
      ),
    );
    return (): void => timeouts.forEach((timeout: NodeJS.Timeout) => clearTimeout(timeout));
  }, [notifications, removeNotification]);

  return <Notifications notifications={notifications} onCancel={removeNotification} />;
};

export default NotificationsContainer;
