import { QueryHandler } from "@lookiero/messaging.js";
import ListBrands from "./ListBrands";
import Brand from "../../model/Brand";
import BrandView from "../../model/BrandView";

class ListBrandsHandler implements QueryHandler<ListBrands> {
  private brandView: BrandView;

  public constructor(brandView: BrandView) {
    this.brandView = brandView;
  }

  public async handle(/*query: ListBrands*/): Promise<Brand[]> {
    return await this.brandView.list();
  }
}

export default ListBrandsHandler;
