import { AggregateRoot } from "@lookiero/messaging.js";
import ProviderCreated from "./ProviderCreated";

class Provider extends AggregateRoot {
  public id: string;
  public name: string;
  public address: string;
  public tin: string;
  public country: string;
  public currency: string;
  public operationType: string;
  public paymentMethod: string;

  private constructor(
    id: string,
    name: string,
    address: string,
    tin: string,
    country: string,
    currency: string,
    operationType: string,
    paymentMethod: string,
  ) {
    super();
    this.id = id;
    this.name = name;
    this.address = address;
    this.tin = tin;
    this.country = country;
    this.currency = currency;
    this.operationType = operationType;
    this.paymentMethod = paymentMethod;
  }

  public static create(
    id: string,
    name: string,
    address: string,
    tin: string,
    country: string,
    currency: string,
    operationType: string,
    paymentMethod: string,
  ): Provider {
    const instance = new Provider(id, name, address, tin, country, currency, operationType, paymentMethod);

    instance.record(new ProviderCreated({ providerId: instance.id }));

    return instance;
  }
}

export default Provider;
