import { QueryHandler } from "@lookiero/messaging.js";
import Channel from "../../model/Channel";
import ChannelView from "../../model/ChannelView";
import ListChannels from "./ListChannels";

class ListChannelsHandler implements QueryHandler<ListChannels> {
  private channelView: ChannelView;

  public constructor(channelView: ChannelView) {
    this.channelView = channelView;
  }

  public async handle(/*query: ListChannels*/): Promise<Channel[]> {
    return await this.channelView.list();
  }
}

export default ListChannelsHandler;
