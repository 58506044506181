import { QueryHandler } from "@lookiero/messaging.js";
import ListSegments from "./ListSegments";
import SegmentView from "../../model/SegmentView";
import { Segment } from "../../model/Segment";

class ListSegmentsHandler implements QueryHandler<ListSegments> {
  private segmentView: SegmentView;

  public constructor(segmentView: SegmentView) {
    this.segmentView = segmentView;
  }

  public async handle(): Promise<Segment> {
    return await this.segmentView.list();
  }
}

export default ListSegmentsHandler;
