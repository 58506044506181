import { Query } from "@lookiero/messaging.js";
import { SearchPurchasesCriteria } from "../../model/PurchaseView";

class CountPurchasesByCriteria extends Query {
  public readonly criteria: SearchPurchasesCriteria;

  public constructor(criteria: SearchPurchasesCriteria) {
    super();
    this.criteria = criteria;
  }

  protected messageName(): string {
    return "CountPurchasesByCriteria";
  }
}

export default CountPurchasesByCriteria;
