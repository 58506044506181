import React from "react";
import { createRoot } from "react-dom/client";
import { MessagingRoot } from "@lookiero/messaging.js";
import * as serviceWorker from "./serviceWorker";
import { TYPES } from "./core/_config/ioc.types";
import bootstrap from "./core/_config/ioc.config";
import Environment from "./core/projection/environment/model/Environment";
import "@lookiero/react-ui-kit/lib/styles/styles.css";
import Root from "./ui/views/app/root/Root";
import { BrowserRouter } from "react-router-dom";

import "./ui/index.css";

(async function (): Promise<void> {
  const container = await bootstrap();

  const rootContainer = document.getElementById("root") as HTMLElement;
  const root = createRoot(rootContainer);

  root.render(
    <BrowserRouter>
      <Root
        environment={container.get<Environment>(TYPES.Environment)}
        MessagingRootComponent={container.get<MessagingRoot>(TYPES.MessagingRootComponent)}
      />
    </BrowserRouter>,
  );
})();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
