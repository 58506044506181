import Bundle from "@src/core/domain/bundle/model/Bundle";

type CreateBundleRequestDTO = {
  id: string;
  location: string;
  number_of_pallets: number;
  tags: string[] | null;
  note: string;
  purchases: string[];
};

type EditBundleRequestDTO = {
  id: string;
  location: string;
  number_of_pallets: number;
  tags: string[] | null;
};

type EditBundleNoteRequestDTO = {
  id: string;
  note: string;
};

const createBundleRequestDtoFromBundle = (bundle: Bundle): CreateBundleRequestDTO => ({
  id: bundle.id,
  location: bundle.location,
  number_of_pallets: bundle.numberOfPallets,
  tags: bundle.tags,
  note: bundle.note,
  purchases: bundle.purchases.map(({ id }) => id),
});

const editBundleRequestDtoFromBundle = (bundle: Bundle): EditBundleRequestDTO => ({
  id: bundle.id,
  location: bundle.location,
  number_of_pallets: bundle.numberOfPallets,
  tags: bundle.tags || null,
});

const editBundleNoteRequestDtoFromBundle = (bundle: Bundle): EditBundleNoteRequestDTO => ({
  id: bundle.id,
  note: bundle.note,
});

export { createBundleRequestDtoFromBundle, editBundleRequestDtoFromBundle, editBundleNoteRequestDtoFromBundle };
