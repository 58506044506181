import React, { ReactNode, useContext, useEffect } from "react";
import { invariant } from "ts-invariant";
import { UseQueryResult } from "react-query";
import { DomainEvent, useAsk, useDispatch } from "@lookiero/messaging.js";
import Brand from "../../../core/projection/brand/model/Brand";
import ListBrands from "../../../core/projection/brand/query/listBrands/ListBrands";
import BrandCreated from "@src/core/domain/brand/model/BrandCreated";
import BrandOrigin from "../../../core/projection/brand/model/BrandOrigin";
import ListBrandOrigins from "../../../core/projection/brand/query/listBrandOrigins/ListBrandOrigins";
import Family from "../../../core/projection/family/model/Family";
import ListFamilies from "../../../core/projection/family/query/listFamilies/ListFamilies";
import Season from "../../../core/projection/season/model/Season";
import ListSeasons from "../../../core/projection/season/query/listSeasons/ListSeasons";
import ListOrderStatuses from "../../../core/projection/orderStatus/query/listOrderStatuses/ListOrderStatuses";
import ListOrderLineStatusesForPurchaseReception from "@src/core/projection/orderLineStatus/query/listOrderLineStatusesForPurchaseReception/ListOrderLineStatusesForPurchaseReception";
import Color from "../../../core/projection/color/model/Color";
import ListColors from "../../../core/projection/color/query/listColors/ListColors";
import Provider from "../../../core/projection/provider/model/Provider";
import ListProviders from "../../../core/projection/provider/query/listProviders/ListProviders";
import ProviderCreated from "@src/core/domain/provider/model/ProviderCreated";
import ProviderOptions from "@src/core/projection/provider/model/ProviderOptions";
import SearchProviderOptions from "../../../core/projection/provider/query/searchProviderOptions/SearchProviderOptions";
import ListManufacturingCountries from "@src/core/projection/manufacturingCountry/query/listManufacturingCountries/ListManufacturingCountries";
import Recommendation from "@src/core/projection/recommendation/model/Recommendation";
import ListRecommendations from "@src/core/projection/recommendation/query/listRecommendations/ListRecommendations";
import Channel from "@src/core/projection/channel/model/Channel";
import ListChannels from "@src/core/projection/channel/query/listChannels/ListChannels";
import ListBundleTags from "@src/core/projection/bundleTag/query/listTags/ListBundleTags";
import ListOrderLineRiskLevels from "@src/core/projection/orderLine/query/ListOrderLineRiskLevels/ListOrderLineRiskLevels";
import ListBundleSteps from "@src/core/projection/bundleSteps/query/ListBundleSteps";
import instanceOfClass from "@src/shared/_types/instanceOfClass";
import ThrowError from "@src/core/domain/error/command/throwError/ThrowError";

type StaticResourcesContextType = {
  readonly brands: UseQueryResult<Brand[], Error>;
  readonly brandOrigins: UseQueryResult<BrandOrigin[], Error>;
  readonly families: UseQueryResult<Family[], Error>;
  readonly seasons: UseQueryResult<Season[], Error>;
  readonly colors: UseQueryResult<Color[], Error>;
  readonly orderStatuses: UseQueryResult<string[], Error>;
  readonly orderLineStatusesForPurchaseReception: UseQueryResult<string[], Error>;
  readonly providers: UseQueryResult<Provider[], Error>;
  readonly providerOptions: UseQueryResult<ProviderOptions, Error>;
  readonly manufacturingCountries: UseQueryResult<string[], Error>;
  readonly recommendations: UseQueryResult<Recommendation[], Error>;
  readonly channels: UseQueryResult<Channel[], Error>;
  readonly riskLevels: UseQueryResult<string[], Error>;
  readonly bundleTags: UseQueryResult<string[], Error>;
  readonly bundleSteps: UseQueryResult<string[], Error>;
};

const StaticResourcesContext = React.createContext<StaticResourcesContextType>(
  null as unknown as StaticResourcesContextType,
);

type StaticResourcesProviderProps = {
  readonly children: ReactNode;
  readonly onFetch?: () => void;
};

const StaticResourcesProvider: React.FC<StaticResourcesProviderProps> = ({
  children,
  onFetch,
}: StaticResourcesProviderProps) => {
  const dispatch = useDispatch();
  const brands = useAsk<Brand[], Error>({
    query: new ListBrands(),
    invalidation: (event: DomainEvent) => instanceOfClass(event, [BrandCreated]),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });

  const brandOrigins = useAsk<BrandOrigin[], Error>({
    query: new ListBrandOrigins(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const families = useAsk<Family[], Error>({
    query: new ListFamilies(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const seasons = useAsk<Season[], Error>({
    query: new ListSeasons(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const colors = useAsk<Color[], Error>({
    query: new ListColors(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const orderStatuses = useAsk<string[], Error>({
    query: new ListOrderStatuses(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const orderLineStatusesForPurchaseReception = useAsk<string[], Error>({
    query: new ListOrderLineStatusesForPurchaseReception(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const providers = useAsk<Provider[], Error>({
    query: new ListProviders(),
    invalidation: (event: DomainEvent) => instanceOfClass(event, [ProviderCreated]),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const providerOptions = useAsk<ProviderOptions, Error>({
    query: new SearchProviderOptions(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const manufacturingCountries = useAsk<string[], Error>({
    query: new ListManufacturingCountries(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const recommendations = useAsk<Recommendation[], Error>({
    query: new ListRecommendations(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const channels = useAsk<Channel[], Error>({
    query: new ListChannels(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const riskLevels = useAsk<string[], Error>({
    query: new ListOrderLineRiskLevels(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const bundleTags = useAsk<string[], Error>({
    query: new ListBundleTags(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const bundleSteps = useAsk<string[], Error>({
    query: new ListBundleSteps(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  useEffect(() => {
    if (
      [
        brands.isFetching,
        brandOrigins.isFetching,
        families.isFetching,
        seasons.isFetching,
        colors.isFetching,
        orderStatuses.isFetching,
        orderLineStatusesForPurchaseReception.isFetching,
        providers.isFetching,
        providerOptions.isFetching,
        manufacturingCountries.isFetching,
        recommendations.isFetching,
        channels.isFetching,
        riskLevels.isFetching,
        bundleTags.isFetching,
        bundleSteps.isFetching,
      ].every(val => val === false)
    ) {
      onFetch && onFetch();
    }
  }, [
    brands,
    brandOrigins,
    families,
    seasons,
    colors,
    orderStatuses,
    orderLineStatusesForPurchaseReception,
    providers,
    providerOptions,
    onFetch,
    manufacturingCountries,
    recommendations,
    channels,
    riskLevels,
    bundleTags,
    bundleSteps,
  ]);

  const value = {
    brands,
    brandOrigins,
    families,
    seasons,
    colors,
    orderStatuses,
    orderLineStatusesForPurchaseReception,
    providers,
    providerOptions,
    manufacturingCountries,
    recommendations,
    channels,
    riskLevels,
    bundleTags,
    bundleSteps,
  };

  return <StaticResourcesContext.Provider value={value}>{children}</StaticResourcesContext.Provider>;
};

const useQueryStaticResources = (): StaticResourcesContextType => {
  const values = useContext<StaticResourcesContextType>(StaticResourcesContext);

  invariant(
    values,
    "Your are trying to use the useQueryStaticResources hook without wrapping your app with the <StaticResourcesProvider>.",
  );

  return values;
};

export { StaticResourcesProvider };

export default useQueryStaticResources;
