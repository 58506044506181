import { QueryHandler } from "@lookiero/messaging.js";
import SearchFeaturesByFamilyForRestockCriteria from "./SearchFeaturesByFamilyForRestockCriteria";
import Feature from "../../model/Feature";
import FeatureView from "../../model/FeatureView";

class SearchFeaturesByFamilyForRestockCriteriaHandler
  implements QueryHandler<SearchFeaturesByFamilyForRestockCriteria>
{
  private featureView: FeatureView;

  public constructor(featureView: FeatureView) {
    this.featureView = featureView;
  }

  public async handle({ familyId }: SearchFeaturesByFamilyForRestockCriteria): Promise<Feature[]> {
    return await this.featureView.searchByFamilyForRestockCriteria(familyId);
  }
}

export default SearchFeaturesByFamilyForRestockCriteriaHandler;
