import HttpClient from "../../../../../shared/delivery/HttpClient";
import FeatureView from "../../../../projection/feature/model/FeatureView";
import Feature from "../../../../projection/feature/model/Feature";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpFeatureView implements FeatureView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async searchByFamily(familyId: string): Promise<Feature[]> {
    try {
      const response = await this.httpClient.post("/search-features-by-family", { family_id: familyId });

      if (!response.ok) {
        throw new Error("obtener los features del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener los features del servicio");
    }
  }

  public async searchByFamilyForRestockCriteria(familyId: string): Promise<Feature[]> {
    try {
      const response = await this.httpClient.post("/search-features-by-family-for-restock-criteria", {
        family_id: familyId,
      });

      if (!response.ok) {
        throw new Error("obtener los features del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener los features del servicio");
    }
  }

  public async searchFeaturesForBuyingAssistant(): Promise<Feature[]> {
    try {
      const response = await this.httpClient.post("/search-features-for-buying-assistant", {});

      if (!response.ok) {
        throw new Error("obtener los features del servicio");
      }

      const result = await response.json();

      return result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener los features del servicio");
    }
  }
}

export default HttpFeatureView;
