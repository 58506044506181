import { DomainEvent, useAsk } from "@lookiero/messaging.js";
import UserLoggedIn from "@src/core/domain/user/model/UserLoggedIn";
import UserLoggedOut from "@src/core/domain/user/model/UserLoggedOut";
import IsUserLoggedIn from "@src/core/projection/user/query/isUserLoggedIn/IsUserLoggedIn";

const useIsUserLoggedIn = (): boolean | undefined => {
  const { data } = useAsk<boolean, Error>({
    query: new IsUserLoggedIn(),
    invalidation: (event: DomainEvent) => event instanceof UserLoggedIn || event instanceof UserLoggedOut,
  });
  return data;
};

export default useIsUserLoggedIn;
