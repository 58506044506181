import { Command } from "@lookiero/messaging.js";

class RemoveOrderLine extends Command {
  public readonly orderLineId: string;

  public constructor(orderLineId: string) {
    super();
    this.orderLineId = orderLineId;
  }

  public messageName(): string {
    return "RemoveOrderLine";
  }
}

export default RemoveOrderLine;
