import ManufacturingCountryView from "@src/core/projection/manufacturingCountry/model/ManufacturingCountryView";
import HttpClient from "@src/shared/delivery/HttpClient";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpManufacturingCountryView implements ManufacturingCountryView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<string[]> {
    try {
      const response = await this.httpClient.post("/search-manufacturing-countries", {});

      if (!response.ok) {
        throw new Error("obtener los paises de fabricación del servicio");
      }

      const { result } = await response.json();

      return result.manufacturing_countries;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError
        ? error
        : new Error("obtener los paises de fabricación del servicio");
    }
  }
}

export default HttpManufacturingCountryView;
