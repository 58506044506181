import { DomainEvent } from "@lookiero/messaging.js";

class PurchaseReceived extends DomainEvent {
  public readonly purchaseId: string;

  public constructor(purchaseId: string) {
    super();
    this.purchaseId = purchaseId;
  }

  public messageName(): string {
    return "PurchaseReceived";
  }
}

export default PurchaseReceived;
