import OrderLineStatusView from "@src/core/projection/orderLineStatus/model/OrderLineStatusView";
import HttpClient from "@src/shared/delivery/HttpClient";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpOrderLineStatusView implements OrderLineStatusView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async listForPurchaseReception(): Promise<string[]> {
    const errorMessage = "obtener los estados de las lineas de pedido (para recepcionar compras) del servicio";

    try {
      const response = await this.httpClient.post("/list-order-line-statuses-for-purchase-reception", {});

      if (!response.ok) {
        throw new Error(errorMessage);
      }

      const { result } = await response.json();

      return result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error(errorMessage);
    }
  }
}

export default HttpOrderLineStatusView;
