import { Command } from "@lookiero/messaging.js";
import FormFeatureValue from "../../../../../ui/componentLibrary/_common/FormFeatureValue";

export interface EditOrderLineFromBuyingAssistantParameters {
  readonly orderLineId: string;
  readonly familyId: string;
  readonly providerProductReference: string;
  readonly medias: string[];
  readonly features: { id: string; values: FormFeatureValue[] }[];
  readonly neckline: string;
  readonly colors: string[];
  readonly targetChannels: string[];
  readonly riskLevel: number | null;
}

class EditOrderLineFromBuyingAssistant extends Command {
  public readonly orderLineId: string;
  public readonly familyId: string;
  public readonly providerProductReference: string;
  public readonly medias: string[];
  public readonly features: { id: string; values: FormFeatureValue[] }[];
  public readonly neckline: string;
  public readonly colors: string[];
  public readonly targetChannels: string[];
  public readonly riskLevel: number | null;

  public constructor({
    orderLineId,
    familyId,
    providerProductReference,
    medias,
    features,
    neckline,
    colors,
    targetChannels,
    riskLevel,
  }: EditOrderLineFromBuyingAssistantParameters) {
    super();
    this.orderLineId = orderLineId;
    this.familyId = familyId;
    this.providerProductReference = providerProductReference;
    this.medias = medias;
    this.features = features;
    this.neckline = neckline;
    this.colors = colors;
    this.targetChannels = targetChannels;
    this.riskLevel = riskLevel;
  }

  public messageName(): string {
    return "EditOrderLineFromBuyingAssistant";
  }
}

export default EditOrderLineFromBuyingAssistant;
