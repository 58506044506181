import HttpClient from "../../../../../shared/delivery/HttpClient";
import OrderLineView, {
  OrderLineCriteria,
  PagedOrderLineCriteria,
} from "../../../../projection/orderLine/model/OrderLineView";
import OrderLine from "../../../../projection/orderLine/model/OrderLine";
import {
  orderLineResponseDtoToOrderLinePreviewProjection,
  orderLineResponseDtoToOrderLineProjection,
  suggestDeliveryEstimationDtoToDate,
} from "./responseMapper";
import OrderLinePreview from "../../../../projection/orderLine/model/OrderLinePreview";
import { orderLineCountCriteriaDto, orderLineSearchCriteriaDto } from "./requestBuilder";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpOrderLineView implements OrderLineView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async countByCriteria(criteria: OrderLineCriteria): Promise<number> {
    try {
      const countCriteriaDto = orderLineCountCriteriaDto(criteria);
      const response = await this.httpClient.post("/count-order-lines-by-criteria", countCriteriaDto);

      if (!response.ok) {
        throw new Error("Count OrderLines by Criteria");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      console.log(error);
      if (error instanceof UnAuthorizedUserError) {
        throw error;
      }
      return 0;
    }
  }

  public async searchByCriteria(criteria: PagedOrderLineCriteria): Promise<OrderLinePreview[]> {
    try {
      const searchCriteriaDto = orderLineSearchCriteriaDto(criteria);
      const response = await this.httpClient.post("/search-order-lines-by-criteria", searchCriteriaDto);

      if (!response.ok) {
        throw new Error("Search OrderLines by Criteria");
      }

      const result = await response.json();

      return result.result.map(orderLineResponseDtoToOrderLinePreviewProjection);
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("Search OrderLines by Criteria");
    }
  }

  public async ofId(orderLineId: string): Promise<OrderLine | undefined> {
    try {
      const response = await this.httpClient.post("/view-order-line-by-id", { order_line_id: orderLineId });

      if (!response.ok) {
        throw new Error("obtener la línea de pedido del servicio");
      }

      const { result } = await response.json();
      return result ? orderLineResponseDtoToOrderLineProjection(result) : undefined;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener la línea de pedido del servicio");
    }
  }

  public async suggestDeliveryEstimation(orderId: string): Promise<Date | null> {
    try {
      const response = await this.httpClient.post("/suggest-delivery-estimation", { order_id: orderId });

      if (!response.ok) {
        throw new Error("obtener la fecha de entrega estimada del servicio");
      }

      const result = await response.json();

      return suggestDeliveryEstimationDtoToDate(result.result);
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError
        ? error
        : new Error("obtener la fecha de entrega estimada del servicio");
    }
  }

  public async listOrderLineRiskLevels(): Promise<string[]> {
    try {
      const response = await this.httpClient.post("/list-order-line-risk-levels", {});

      if (!response.ok) {
        throw new Error("obtener lista de niveles de riesgo de order lines");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError
        ? error
        : new Error("obtener lista de niveles de riesgo de order lines");
    }
  }
}

export default HttpOrderLineView;
