import { QueryHandler } from "@lookiero/messaging.js";
import ListFamilies from "./ListFamilies";
import Family from "../../model/Family";
import FamilyView from "../../model/FamilyView";

class ListFamiliesHandler implements QueryHandler<ListFamilies> {
  private familyView: FamilyView;

  public constructor(familyView: FamilyView) {
    this.familyView = familyView;
  }

  public async handle(/*query: ListFamilies*/): Promise<Family[]> {
    return await this.familyView.list();
  }
}

export default ListFamiliesHandler;
