import HttpClient from "../../../../../shared/delivery/HttpClient";
import RestockProductView, { RestockCriteria } from "../../../../projection/restockProduct/model/RestockProductView";
import RestockProduct from "../../../../projection/restockProduct/model/RestockProduct";
import { restockProductResponseDtoToRestockProduct } from "./responseMapper";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpRestockProductView implements RestockProductView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async listForCriteria({
    providerProductReference,
    brandId,
    familyId,
    features,
  }: RestockCriteria): Promise<RestockProduct[]> {
    try {
      const response = await this.httpClient.post("/search-products-by-restock-criteria", {
        provider_product_reference: providerProductReference === "" ? null : providerProductReference,
        brand_id: brandId,
        family_id: familyId,
        features,
      });

      if (!response.ok) {
        throw new Error("obtener los productos de restock del servicio");
      }

      const result = await response.json();

      return result.result.map(restockProductResponseDtoToRestockProduct);
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener los productos de restock del servicio");
    }
  }

  public async ofGroup(group: string): Promise<RestockProduct | undefined> {
    try {
      const response = await this.httpClient.post("/view-product-by-group", { group });

      if (!response.ok) {
        throw new Error("obtener el producto de restock del servicio");
      }

      const result = await response.json();

      return result.result ? restockProductResponseDtoToRestockProduct(result.result) : undefined;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener el producto de restock del servicio");
    }
  }
}

export default HttpRestockProductView;
