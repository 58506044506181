import { QueryHandler } from "@lookiero/messaging.js";
import ListOrderStatuses from "./ListOrderStatuses";
import OrderStatusView from "../../model/OrderStatusView";

class ListOrderStatusesHandler implements QueryHandler<ListOrderStatuses> {
  private orderStatusView: OrderStatusView;

  public constructor(orderStatusView: OrderStatusView) {
    this.orderStatusView = orderStatusView;
  }

  public async handle(/*query: ListOrderStatuses*/): Promise<string[]> {
    return await this.orderStatusView.list();
  }
}

export default ListOrderStatusesHandler;
