import { Command } from "@lookiero/messaging.js";

type StartOrderParameters = {
  readonly orderId: string;
  readonly segment: string;
  readonly seasonId: string;
  readonly brandId: string;
  readonly recommendationId: string;
  readonly b2b: boolean;
};

class StartOrder extends Command {
  public readonly orderId: string;
  public readonly segment: string;
  public readonly seasonId: string;
  public readonly brandId: string;
  readonly recommendationId: string;
  public readonly b2b: boolean;

  public constructor({ orderId, segment, seasonId, brandId, recommendationId, b2b }: StartOrderParameters) {
    super();
    this.orderId = orderId;
    this.segment = segment;
    this.seasonId = seasonId;
    this.brandId = brandId;
    this.recommendationId = recommendationId;
    this.b2b = b2b;
  }

  public messageName(): string {
    return "StartOrder";
  }
}

export default StartOrder;
