import HttpClient from "../../../../../shared/delivery/HttpClient";
import ColorView from "../../../../projection/color/model/ColorView";
import Color from "../../../../projection/color/model/Color";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpColorView implements ColorView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Color[]> {
    try {
      const response = await this.httpClient.post("/search-colors", {});

      if (!response.ok) {
        throw new Error("obtener los colores del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener los colores del servicio");
    }
  }
}

export default HttpColorView;
