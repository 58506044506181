import { EventBus } from "@lookiero/messaging.js";
import User from "@src/core/projection/user/model/User";
import UserView from "@src/core/projection/user/model/UserView";
import StorageClient from "@src/shared/delivery/StorageClient";

class InMemoryUserView implements UserView {
  private eventBus!: EventBus;

  private static readonly USER_KEY = "user";

  private readonly userStorageClient: StorageClient;

  constructor(storage: StorageClient) {
    this.userStorageClient = storage;
  }

  public async getCurrentUser(): Promise<User> {
    return await this.userStorageClient.get(InMemoryUserView.USER_KEY);
  }

  public async isUserLoggedIn(): Promise<boolean> {
    return this.userStorageClient.has(InMemoryUserView.USER_KEY);
  }
}

export default InMemoryUserView;
