import { SaleCurrency } from "../../currency/model/Currency";
import Market from "../../market/model/Market";

enum PricePropertiesEnum {
  EUR = "selling_price_eur",
  GBP = "selling_price_gbp",
  SEK = "selling_price_sek",
  CHF = "selling_price_chf",
}

type PricePropertiesInterface = {
  [key in PricePropertiesEnum]: number;
};

type Prices = Record<SaleCurrency, number>;

type SellingPrice = {
  market: Market;
  price_amount: number;
};

type SellingPrices = Record<Market, number>;

export type { Prices, PricePropertiesInterface, SellingPrice, SellingPrices };
export { PricePropertiesEnum };
