import { QueryHandler } from "@lookiero/messaging.js";
import ListBrandOrigins from "./ListBrandOrigins";
import BrandOriginView from "../../model/BrandOriginView";
import BrandOrigin from "../../model/BrandOrigin";

class ListBrandOriginsHandler implements QueryHandler<ListBrandOrigins> {
  private brandOriginView: BrandOriginView;

  public constructor(brandOriginView: BrandOriginView) {
    this.brandOriginView = brandOriginView;
  }

  public async handle(/*query: ListBrandOrigins*/): Promise<BrandOrigin[]> {
    return await this.brandOriginView.list();
  }
}

export default ListBrandOriginsHandler;
