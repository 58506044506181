import { Query } from "@lookiero/messaging.js";

type ViewPurchaseByIdParams = {
  readonly purchaseId: string;
};

class ViewPurchaseById extends Query {
  public readonly purchaseId: string;

  public constructor({ purchaseId }: ViewPurchaseByIdParams) {
    super();
    this.purchaseId = purchaseId;
  }

  public messageName(): string {
    return "ViewPurchaseById";
  }
}

export default ViewPurchaseById;
