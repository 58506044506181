import HttpClient from "../../../../../shared/delivery/HttpClient";
import SizeView from "../../../../projection/size/model/SizeView";
import Size from "../../../../projection/size/model/Size";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpSizeView implements SizeView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async listForFamily(familyId: string): Promise<Size[]> {
    try {
      const response = await this.httpClient.post("/search-sizes-by-family", { family_id: familyId });

      if (!response.ok) {
        throw new Error("obtener las tallas del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener las tallas del servicio");
    }
  }
}

export default HttpSizeView;
