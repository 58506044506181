import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";
import ErrorThrown from "@src/core/domain/error/model/ErrorThrown";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

const genericMessage = (generic = ""): string =>
  `<p>Ha habido un error${generic}.</p><p>Vuelve a intentarlo, por favor. Si persiste el error, contacta con el equipo técnico.</p>`;
const actionMessage = (action: string): string => genericMessage(` a la hora de <b>${action}</b>`);

class CreateNotificationWhenError implements ProcessManager<ErrorThrown> {
  private commandBus!: CommandBus;

  public async process({ error }: ErrorThrown): Promise<void> {
    if (error instanceof UnAuthorizedUserError) {
      return;
    }
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.ERROR,
        content: actionMessage(error.message),
      }),
    );
  }
}

export default CreateNotificationWhenError;
