import { EventBus } from "@lookiero/messaging.js";
import Product from "@src/core/domain/product/model/Product";
import ProductRepository from "@src/core/domain/product/model/ProductRepository";
import HttpClient from "@src/shared/delivery/HttpClient";
import { productDtoToProduct } from "./responseMapper";
import { editProductDtoFromProduct } from "./requestBuilder";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpProductRepository implements ProductRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async get(id: string): Promise<Product | undefined> {
    try {
      const response = await this.httpClient.post("/view-product-by-id", { id });

      if (!response.ok) {
        throw new Error("obtener producto del servicio");
      }

      const { result } = await response.json();

      return result ? productDtoToProduct(result) : undefined;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener producto del servicio");
    }
  }

  public async edit(product: Product): Promise<void> {
    try {
      const response = await this.httpClient.post("/edit-product", editProductDtoFromProduct(product));

      if (!response.ok) {
        throw new Error("Editar producto");
      }

      this.eventBus.publish(product.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("Editar producto");
    }
  }
}

export default HttpProductRepository;
