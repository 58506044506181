import React, { useEffect, useMemo } from "react";
import { Outlet, RouteObject, useLocation, useNavigate, useRoutes } from "react-router-dom";
import ROUTES from "./routes";
import AuthenticationMiddleware from "./AuthenticationMiddleware";
import useIsUserLoggedIn from "../views/_behaviors/useIsUserLoggedIn";
import { StaticResourcesProvider } from "../componentLibrary/_behaviors/useQueryStaticResources";
import Hub from "../componentLibrary/layouts/hub/model/Hub";
import RoleSelector from "../componentLibrary/molecules/roleSelector/RoleSelector";
import LogoutButton from "../componentLibrary/atoms/logoutButton/LogoutButton";
import Header from "../views/app/header/Header";
import { UsePurchaseProductCacheProvider } from "../views/purchases/_behaviors/usePurchaseProductCache";
import useRole, { Role } from "../componentLibrary/_behaviors/useRole";

const BaOrders = React.lazy(() => import("../views/orders/baOrdersOverview/BaOrdersOverview"));
const BaOrder = React.lazy(() => import("../views/orders/baOrder/BaOrder"));
const BaOrderLine = React.lazy(() => import("../views/orders/baOrderLine/BaOrderLine"));
const Orders = React.lazy(() => import("../views/orders/orders/Orders"));
const OrdersOverview = React.lazy(() => import("../views/orders/ordersOverview/OrdersOverview"));
const OrdersLinesOverview = React.lazy(() => import("../views/orders/orderLinesOverview/OrderLinesOverview"));
const Order = React.lazy(() => import("../views/orders/order/Order"));
const OrderLine = React.lazy(() => import("../views/orders/orderLine/OrderLine"));
const PurchasesOverview = React.lazy(() => import("../views/purchases/purchasesOverview/PurchasesOverview"));
const Purchase = React.lazy(() => import("../views/purchases/purchase/Purchase"));
const Product = React.lazy(() => import("../views/purchases/product/Product"));
const BundlesOverview = React.lazy(() => import("../views/bundles/bundlesOverview/BundlesOverview"));
const Login = React.lazy(() => import("../views/login/Login"));

const Routing: React.FC = () => {
  const currentPath = useLocation().pathname;
  const navigate = useNavigate();
  const { setRole } = useRole();

  const isUserLoggedIn = useIsUserLoggedIn();
  const isLogginView = useMemo(() => currentPath === ROUTES.LOGIN, [currentPath]);

  useEffect(() => {
    if (currentPath === ROUTES.HOME) navigate(ROUTES.BA_ORDERS, { replace: true });
    if (isUserLoggedIn && isLogginView) navigate(ROUTES.HOME, { replace: true });
  }, [navigate, isUserLoggedIn, isLogginView, currentPath]);

  useEffect(() => {
    if (currentPath === `/${ROUTES.BA_ORDERS}`) setRole(Role.BUYING_ASSSISTANT);
    if (currentPath === `/${ROUTES.ORDERS}/${ROUTES.ORDERS_OVERVIEW}`) setRole(Role.RECEPTION);
  }, [currentPath, setRole]);

  const routes: RouteObject[] = useMemo(
    () => [
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
      {
        path: "",
        element: (
          <AuthenticationMiddleware locale="es">
            <StaticResourcesProvider>
              <Hub>
                <div className="root__hub">
                  <RoleSelector />
                  <LogoutButton />
                </div>
              </Hub>
              <Header />
              <div className="root__views">
                <UsePurchaseProductCacheProvider>
                  <Outlet />
                </UsePurchaseProductCacheProvider>
              </div>
            </StaticResourcesProvider>
          </AuthenticationMiddleware>
        ),
        children: [
          { path: ROUTES.BA_ORDERS, element: <BaOrders /> },
          {
            path: ROUTES.BA_ORDER,
            element: <BaOrder />,
            children: [{ path: ROUTES.BA_ORDER_LINE, element: <BaOrderLine /> }],
          },
          {
            path: ROUTES.ORDERS,
            element: <Orders />,
            children: [
              { path: ROUTES.ORDERS_OVERVIEW, element: <OrdersOverview /> },
              { path: ROUTES.ORDER_LINES_OVERVIEW, element: <OrdersLinesOverview /> },
            ],
          },
          {
            path: ROUTES.ORDER,
            element: <Order />,
            children: [
              {
                path: ROUTES.ORDER_LINE,
                element: <OrderLine />,
              },
            ],
          },
          {
            path: ROUTES.ORDER_NEW,
            element: <Order />,
          },
          {
            path: ROUTES.PURCHASES_OVERVIEW,
            element: <PurchasesOverview />,
          },
          {
            path: ROUTES.PURCHASE,
            element: <Purchase />,
            children: [
              {
                path: ROUTES.PRODUCT,
                element: <Product />,
              },
            ],
          },
          {
            path: ROUTES.BUNDLES_OVERVIEW,
            element: <BundlesOverview />,
          },
        ],
      },
    ],
    [],
  );
  return useRoutes(routes);
};

export default Routing;
