import { AggregateRoot } from "@lookiero/messaging.js";
import UserLoggedIn from "./UserLoggedIn";
import UserLoggedOut from "./UserLoggedOut";

class User extends AggregateRoot {
  public authToken?: string;
  public username?: string;

  public constructor(authToken?: string, username?: string) {
    super();
    this.authToken = authToken;
    this.username = username;
  }

  public static login(authToken: string, username: string): User {
    const instance = new User(authToken, username);
    instance.record(new UserLoggedIn());
    return instance;
  }

  public static logout(): User {
    const instance = new User();
    instance.record(new UserLoggedOut());
    return instance;
  }
}

export default User;
