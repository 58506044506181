import { Command } from "@lookiero/messaging.js";

class AccomplishStepTagging extends Command {
  readonly bundleId: string;

  public constructor(bundleId: string) {
    super();
    this.bundleId = bundleId;
  }

  public messageName(): string {
    return "AccomplishStepTagging";
  }
}

export default AccomplishStepTagging;
