import React from "react";
import HeaderComponent from "../../../componentLibrary/organisms/header/Header";
import { INJECT_COMPONENT_REGISTRY } from "../../_config/injectComponentRegistry";
import useInjectComponent from "../../../../shared/ui/behaviors/useInjectComponent/useInjectComponent";

const Header: React.FC = () => {
  // Query the registry's rendered Actions by a sub-view (OrdersOverview, PurchasesOverview & InvoicesOverview)
  const OrdersOverviewActions = useInjectComponent(INJECT_COMPONENT_REGISTRY.ORDERS_OVERVIEW_ACTIONS);
  const OrderActions = useInjectComponent(INJECT_COMPONENT_REGISTRY.ORDERS_ORDER_ACTIONS);
  const OrderLinesOverviewActions = useInjectComponent(INJECT_COMPONENT_REGISTRY.ORDER_LINES_OVERVIEW_ACTIONS);
  const PurchasesOverviewActions = useInjectComponent(INJECT_COMPONENT_REGISTRY.PURCHASES_OVERVIEW_ACTIONS);

  return (
    <HeaderComponent>
      {OrdersOverviewActions}
      {OrderActions}
      {OrderLinesOverviewActions}
      {PurchasesOverviewActions}
    </HeaderComponent>
  );
};

export default Header;
