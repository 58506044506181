import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { NavItem } from "@lookiero/react-ui-kit";
import useRole, { Role } from "../../_behaviors/useRole";
import useInProductionEnv from "@src/shared/ui/behaviors/useInProductionEnv/useInProductionEnv";
import ROUTES from "@src/ui/_routing/routes";
import useIsRoute from "@src/shared/ui/behaviors/useIsRoute/useIsRoute";
import "./header.css";

interface HeaderProps {
  readonly children: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const isOrdersActive = useIsRoute(`/${ROUTES.ORDERS}/${ROUTES.ORDERS_OVERVIEW}`);
  const isOrderActive = useIsRoute(`/${ROUTES.ORDER}`);
  const isBaOrdersActive = useIsRoute(`/${ROUTES.BA_ORDERS}`);
  const isBaOrderActive = useIsRoute(`/${ROUTES.BA_ORDER}`);
  const isPurchasesActive = useIsRoute(`/${ROUTES.PURCHASES_OVERVIEW}`);
  const isBundlesActive = useIsRoute(`/${ROUTES.BUNDLES_OVERVIEW}`);
  const isProd = useInProductionEnv();

  const { role } = useRole();
  const ordersRoute =
    role === Role.BUYING_ASSSISTANT ? `/${ROUTES.BA_ORDERS}` : `/${ROUTES.ORDERS}/${ROUTES.ORDERS_OVERVIEW}`;

  return (
    <header className="header">
      <nav className="header__nav">
        <NavLink to={ordersRoute}>
          <NavItem active={isOrdersActive || isOrderActive || isBaOrdersActive || isBaOrderActive}>Pedidos</NavItem>
        </NavLink>
        <NavLink to={ROUTES.PURCHASES_OVERVIEW}>
          <NavItem active={isPurchasesActive}>Recepción</NavItem>
        </NavLink>
        {!isProd ? (
          <NavLink to={ROUTES.BUNDLES_OVERVIEW}>
            <NavItem active={isBundlesActive}>Lotes</NavItem>
          </NavLink>
        ) : null}
      </nav>
      <div className="header__actions">{children}</div>
    </header>
  );
};

export default Header;
