import { DomainEvent } from "@lookiero/messaging.js";

class PurchaseInvoiced extends DomainEvent {
  public readonly purchaseId: string;

  public constructor(purchaseId: string) {
    super();
    this.purchaseId = purchaseId;
  }

  public messageName(): string {
    return "PurchaseInvoiced";
  }
}

export default PurchaseInvoiced;
