import { Command } from "@lookiero/messaging.js";

interface RegisterOrderParameters {
  readonly orderId: string;
}

class RegisterOrder extends Command {
  public readonly orderId: string;

  public constructor({ orderId }: RegisterOrderParameters) {
    super();
    this.orderId = orderId;
  }

  public messageName(): string {
    return "RegisteOrder";
  }
}

export default RegisterOrder;
