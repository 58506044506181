import { QueryHandler } from "@lookiero/messaging.js";
import MediaView from "../../model/MediaView";
import Media from "../../model/Media";
import ViewMediaById from "./ViewMediaById";

class ViewMediaByIdHandler implements QueryHandler<ViewMediaById> {
  private readonly mediaView: MediaView;

  public constructor(mediaView: MediaView) {
    this.mediaView = mediaView;
  }

  public async handle({ mediaId }: ViewMediaById): Promise<Media | undefined> {
    return await this.mediaView.ofId(mediaId);
  }
}

export default ViewMediaByIdHandler;
