import { PagedBundleCriteria } from "@src/core/projection/bundle/model/BundleView";
import toArray from "@src/shared/dataTransformer/toArray";

type BundleCountCriteriaDto = {
  readonly tags: string[] | undefined;
  readonly step_fashion_labs: string | undefined;
  readonly step_tagging: string | undefined;
  readonly created_on: string | undefined;
  readonly brand: string | undefined;
  readonly season: string | undefined;
};

type BundleSearchCriteriaDto = BundleCountCriteriaDto & {
  readonly page: number;
  readonly per_page: number;
};

const bundleSearchCriteriaDto = ({
  tags,
  stepFashionLabs,
  stepTagging,
  createdOn,
  brand,
  season,
  page,
  perPage,
}: PagedBundleCriteria): BundleSearchCriteriaDto => ({
  tags: toArray(tags),
  step_fashion_labs: stepFashionLabs,
  step_tagging: stepTagging,
  created_on: createdOn,
  season,
  brand,
  page,
  per_page: perPage,
});

export { bundleSearchCriteriaDto };
