import { Command } from "@lookiero/messaging.js";

class EditPurchase extends Command {
  public readonly purchaseId: string;
  public readonly invoiceNumber: string | null;
  public readonly invoiceDate: Date | null;
  public readonly providerDeliveryNoteNumber: string | null;
  public readonly providerDeliveryNoteDate: Date | null;
  public readonly entryDate: Date;
  public readonly warehouseLocation: string;
  public readonly segment: string | null;

  public constructor(
    purchaseId: string,
    invoiceNumber: string | null,
    invoiceDate: Date | null,
    providerDeliveryNoteNumber: string | null,
    providerDeliveryNoteDate: Date | null,
    entryDate: Date,
    warehouseLocation: string,
    segment: string | null,
  ) {
    super();
    this.purchaseId = purchaseId;
    this.invoiceNumber = invoiceNumber;
    this.invoiceDate = invoiceDate;
    this.providerDeliveryNoteNumber = providerDeliveryNoteNumber;
    this.providerDeliveryNoteDate = providerDeliveryNoteDate;
    this.entryDate = entryDate;
    this.warehouseLocation = warehouseLocation;
    this.segment = segment;
  }

  public messageName(): string {
    return "EditPurchase";
  }
}

export default EditPurchase;
