import { Query } from "@lookiero/messaging.js";

class ListSizesForFamily extends Query {
  public readonly familyId: string;

  public constructor(familyId: string) {
    super();
    this.familyId = familyId;
  }

  public messageName(): string {
    return "ListSizesForFamily";
  }
}

export default ListSizesForFamily;
