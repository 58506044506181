import { QueryHandler } from "@lookiero/messaging.js";
import NecklineView from "../../model/NecklineView";
import SearchNecklinesByFamily from "./SearchNecklinesByFamily";

class SearchNecklinesByFamilyHandler implements QueryHandler<SearchNecklinesByFamily> {
  private necklineView: NecklineView;

  public constructor(necklineView: NecklineView) {
    this.necklineView = necklineView;
  }

  public async handle({ familyId }: SearchNecklinesByFamily): Promise<string[]> {
    return await this.necklineView.searchByFamily(familyId);
  }
}

export default SearchNecklinesByFamilyHandler;
