import { EventBus } from "@lookiero/messaging.js";
import OrderLineRepository from "../../../../domain/orderLine/model/OrderLineRepository";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import OrderLine from "../../../../domain/orderLine/model/OrderLine";
import {
  orderLineRequestFromReceptionDtoFromOrderLine,
  orderLineRequestFromBuyingAssistantDtoFromOrderLine,
} from "./requestBuilder";
import { orderLineResponseDtoToOrderLine } from "./responseMapper";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpOrderLineRepository implements OrderLineRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async get(id: string): Promise<OrderLine | undefined> {
    try {
      const response = await this.httpClient.post("/view-order-line-by-id", { order_line_id: id });

      if (!response.ok) {
        throw new Error("obtener la línea de pedido del servicio");
      }

      const result = await response.json();

      return result.result ? orderLineResponseDtoToOrderLine(result.result) : undefined;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener la línea de pedido del servicio");
    }
  }

  public async addFromReception(orderLine: OrderLine): Promise<void> {
    try {
      const response = await this.httpClient.post(
        "/add-order-line-from-reception",
        orderLineRequestFromReceptionDtoFromOrderLine(orderLine),
      );

      if (!response.ok) {
        throw new Error("crear la línea de pedido en el servicio");
      }

      this.eventBus.publish(orderLine.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("crear la línea de pedido en el servicio");
    }
  }

  public async editFromReception(orderLine: OrderLine): Promise<void> {
    try {
      const response = await this.httpClient.post(
        "/edit-order-line-from-reception",
        orderLineRequestFromReceptionDtoFromOrderLine(orderLine),
      );

      if (!response.ok) {
        throw new Error("guardar la línea de pedido en el servicio");
      }

      this.eventBus.publish(orderLine.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("guardar la línea de pedido en el servicio");
    }
  }

  public async addFromBuyingAssistant(orderLine: OrderLine): Promise<void> {
    try {
      const response = await this.httpClient.post(
        "/add-order-line-from-buying-assistant",
        orderLineRequestFromBuyingAssistantDtoFromOrderLine(orderLine),
      );

      if (!response.ok) {
        throw new Error("crear la línea de pedido en el servicio");
      }

      this.eventBus.publish(orderLine.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("crear la línea de pedido en el servicio");
    }
  }

  public async editFromBuyingAssistant(orderLine: OrderLine): Promise<void> {
    try {
      const response = await this.httpClient.post(
        "/edit-order-line-from-buying-assistant",
        orderLineRequestFromBuyingAssistantDtoFromOrderLine(orderLine),
      );

      if (!response.ok) {
        throw new Error("guardar la línea de pedido en el servicio");
      }

      this.eventBus.publish(orderLine.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("guardar la línea de pedido en el servicio");
    }
  }

  public async markAsRestock(orderLine: OrderLine): Promise<void> {
    try {
      const response = await this.httpClient.post("/mark-order-line-as-restock", {
        order_line_id: orderLine.id,
        group: orderLine.group,
      });

      if (!response.ok) {
        throw new Error("marcar como restock la línea de pedido en el servicio");
      }

      this.eventBus.publish(orderLine.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError
        ? error
        : new Error("marcar como restock la línea de pedido en el servicio");
    }
  }

  public async unmarkAsRestock(orderLine: OrderLine): Promise<void> {
    try {
      const response = await this.httpClient.post("/unmark-order-line-as-restock", {
        order_line_id: orderLine.id,
      });

      if (!response.ok) {
        throw new Error("desmarcar como restock la línea de pedido en el servicio");
      }

      this.eventBus.publish(orderLine.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError
        ? error
        : new Error("desmarcar como restock la línea de pedido en el servicio");
    }
  }

  public async remove(orderLine: OrderLine): Promise<void> {
    try {
      const response = await this.httpClient.post("/remove-order-line", { order_line_id: orderLine.id });

      if (!response.ok) {
        throw new Error("eliminar la línea de pedido en el servicio");
      }

      this.eventBus.publish(orderLine.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("eliminar la línea de pedido en el servicio");
    }
  }

  public async cancel(orderLine: OrderLine): Promise<void> {
    try {
      const response = await this.httpClient.post("/cancel-order-line", { order_line_id: orderLine.id });

      if (!response.ok) {
        throw new Error("cancelar la línea de pedido en el servicio");
      }

      this.eventBus.publish(orderLine.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("cancelar la línea de pedido en el servicio");
    }
  }

  public async close(orderLine: OrderLine): Promise<void> {
    try {
      const response = await this.httpClient.post("/close-order-line", { order_line_id: orderLine.id });

      if (!response.ok) {
        throw new Error("cerrar la línea de pedido en el servicio");
      }

      this.eventBus.publish(orderLine.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("cerrar la línea de pedido en el servicio");
    }
  }
}

export default HttpOrderLineRepository;
