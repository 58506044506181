import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import ErrorThrown from "@src/core/domain/error/model/ErrorThrown";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";
import Logout from "@src/core/domain/user/command/logout/Logout";

class LogoutWhenUnAuthorizedUserError implements ProcessManager<ErrorThrown> {
  private commandBus!: CommandBus;

  public async process({ error }: ErrorThrown): Promise<void> {
    if (error instanceof UnAuthorizedUserError) {
      this.commandBus.dispatch(new Logout());
    }
  }
}

export default LogoutWhenUnAuthorizedUserError;
