import { QueryHandler } from "@lookiero/messaging.js";
import NotificationView from "../../model/NotificationView";
import Notification from "../../model/Notification";
import ListNotifications from "./ListNotifications";

class ListNotificationsHandler implements QueryHandler<ListNotifications> {
  private readonly view: NotificationView;

  public constructor(view: NotificationView) {
    this.view = view;
  }

  public async handle(): Promise<Notification[]> {
    return this.view.list();
  }
}

export default ListNotificationsHandler;
