import { CommandHandler } from "@lookiero/messaging.js";
import CancelOrderLine from "./CancelOrderLine";
import OrderLineRepository from "../../model/OrderLineRepository";

class CancelOrderLineHandler implements CommandHandler<CancelOrderLine> {
  private orderLineRepository: OrderLineRepository;

  public constructor(orderLineRepository: OrderLineRepository) {
    this.orderLineRepository = orderLineRepository;
  }

  public async execute({ orderLineId }: CancelOrderLine): Promise<void> {
    const orderLine = await this.orderLineRepository.get(orderLineId);

    if (!orderLine) {
      throw new Error(`There is no OrderLine for the provided id: ${orderLineId}`);
    }

    orderLine.cancel();

    await this.orderLineRepository.cancel(orderLine);
  }
}

export default CancelOrderLineHandler;
