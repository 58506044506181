import Provider from "@src/core/domain/provider/model/Provider";

type CreateProviderRequestDTO = {
  readonly id: string;
  readonly name: string;
  readonly address: string;
  readonly tin: string;
  readonly country: string;
  readonly currency: string;
  readonly operation: string;
  readonly payment_method: string;
};

const createProviderRequestDtoFromProvider = (provider: Provider): CreateProviderRequestDTO => ({
  id: provider.id,
  name: provider.name.trim(),
  address: provider.address.trim(),
  tin: provider.tin.trim(),
  country: provider.country,
  currency: provider.currency,
  operation: provider.operationType,
  payment_method: provider.paymentMethod,
});

export { createProviderRequestDtoFromProvider };
