import { DomainEvent } from "@lookiero/messaging.js";

class StepTaggingReverted extends DomainEvent {
  public readonly bundleId: string;

  public constructor(bundleId: string) {
    super();
    this.bundleId = bundleId;
  }

  public messageName(): string {
    return "StepTaggingReverted";
  }
}

export default StepTaggingReverted;
