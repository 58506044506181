import { EventBus } from "@lookiero/messaging.js";
import ProviderRepository from "@src/core/domain/provider/model/ProviderRepository";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import Provider from "@src/core/domain/provider/model/Provider";
import { createProviderRequestDtoFromProvider } from "./requestBuilder";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpProviderRepository implements ProviderRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async create(provider: Provider): Promise<void> {
    try {
      const response = await this.httpClient.post("/add-provider", createProviderRequestDtoFromProvider(provider));
      if (!response.ok) {
        throw new Error("crear proveedor en el servicio");
      }
      this.eventBus.publish(provider.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("crear proveedor en el servicio");
    }
  }
}

export default HttpProviderRepository;
