import { QueryHandler } from "@lookiero/messaging.js";
import ListSizesForFamily from "./ListSizesForFamily";
import Size from "../../model/Size";
import SizeView from "../../model/SizeView";

class ListSizesForFamilyHandler implements QueryHandler<ListSizesForFamily> {
  private sizeView: SizeView;

  public constructor(sizeView: SizeView) {
    this.sizeView = sizeView;
  }

  public async handle(query: ListSizesForFamily): Promise<Size[]> {
    return await this.sizeView.listForFamily(query.familyId);
  }
}

export default ListSizesForFamilyHandler;
