import Authenticator from "@src/core/domain/user/model/Autheticator";
import Credentials from "@src/core/domain/user/model/Credentials";
import { JwtToken } from "@src/core/domain/user/model/JwtToken";
import InvalidUserError from "@src/core/domain/user/model/InvalidUserError";

import { JwtPayload, jwtDecode } from "jwt-decode";
import HttpClient from "@src/shared/delivery/HttpClient";
import InvalidBuyingUserError from "@src/core/domain/user/model/InvalidBuyingUserError";

export interface UserJwtPayload extends JwtPayload {
  roles: string;
}

const BUYING_ROLE = "OPS_LOGISTICS_BUYING";

class HttpAuthenticator implements Authenticator {
  private httpClient: HttpClient;
  private authApi: string;

  constructor(httpClient: HttpClient, authApi: string) {
    this.httpClient = httpClient;
    this.authApi = authApi;
  }

  private readonly getUserRolesFromAuthenticationToken = (authToken: UserJwtPayload): string[] => {
    return authToken.roles.split(",");
  };

  public async authenticate(credentials: Credentials): Promise<JwtToken> {
    const token = await this.doAuthenticate(credentials);
    const isBuyingUser = this.checkTokenIsValid(token);

    if (!isBuyingUser) {
      throw new InvalidBuyingUserError();
    }

    return token;
  }

  private async doAuthenticate(credentials: Credentials): Promise<JwtToken> {
    const response = await this.httpClient.post(this.authApi, { ...credentials });
    if (!response.ok) {
      throw new InvalidUserError();
    }
    return await response.text();
  }

  private checkTokenIsValid(token: JwtToken): boolean {
    const decodedToken = jwtDecode<UserJwtPayload>(token);
    const roles = this.getUserRolesFromAuthenticationToken(decodedToken);
    return roles.includes(BUYING_ROLE);
  }
}

export default HttpAuthenticator;
