import { useDispatch } from "@lookiero/messaging.js";
import RemoveNotification from "@src/core/domain/notification/command/removeNotification/RemoveNotification";
import { useCallback } from "react";

interface UseRemoveNotificationFunctionResult {
  readonly removeNotification: (notificationId: string) => void;
}
interface UseRemoveNotificationFunction {
  (): UseRemoveNotificationFunctionResult;
}

const useRemoveNotification: UseRemoveNotificationFunction = () => {
  const dispatch = useDispatch();
  const removeNotification = useCallback(
    (notificationId: string) => dispatch(new RemoveNotification(notificationId)),
    [dispatch],
  );

  return { removeNotification };
};

export default useRemoveNotification;
