import React, { useState, useContext, useMemo, useCallback, ReactNode } from "react";
import invariant from "ts-invariant";

type ItemId = string;
type WithId = {
  readonly id: ItemId;
} & unknown;

export type ItemSelectionContextApi = {
  readonly selectedItems: WithId[];
  readonly select: (item: WithId) => void;
  readonly unselect: (item: WithId) => void;
  readonly isSelected: (item: WithId) => boolean;
  readonly clearSelection: () => void;
};

interface ItemSelectionProps {
  readonly children: ReactNode;
}

const UseItemSelectionContext = React.createContext<ItemSelectionContextApi>(
  undefined as unknown as ItemSelectionContextApi,
);

export const UseItemSelectionProvider: React.FC<ItemSelectionProps> = ({ children }) => {
  const [itemMap, setItemMap] = useState<Record<ItemId, WithId>>({});

  const selectedItems: WithId[] = useMemo(() => Object.values(itemMap), [itemMap]);

  const select = useCallback((item: WithId): void => {
    setItemMap(previousItems => ({
      ...previousItems,
      [item.id]: item,
    }));
  }, []);

  const unselect = useCallback((item: WithId) => {
    setItemMap(prevItemMap => {
      // TODO: Review this
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [item.id]: itemToBeRemoved, ...restItems } = prevItemMap;

      return restItems;
    });
  }, []);

  const isSelected = useCallback(
    (item: WithId): boolean => {
      return Boolean(itemMap[item.id]);
    },
    [itemMap],
  );

  const clearSelection = useCallback(() => setItemMap({}), []);

  const useOrderLineSelectionContextApi = {
    selectedItems,
    select,
    unselect,
    isSelected,
    clearSelection,
  };
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <UseItemSelectionContext.Provider value={useOrderLineSelectionContextApi as any}>
      {children}
    </UseItemSelectionContext.Provider>
  );
};

const useItemSelection = (): ItemSelectionContextApi => {
  const useItemSelectionApi = useContext(UseItemSelectionContext);

  invariant(useItemSelectionApi, "You are calling useItemSelection hook outside <UseItemSelectionProvider />");

  return useItemSelectionApi;
};

export default useItemSelection;
