import { convertSellingPricesToDTO } from "@src/shared/dataTransformer/prices";
import { toISO } from "../../../../../ui/componentLibrary/_intl/date";
import Purchase, { PurchasedProduct } from "../../../../domain/purchase/model/Purchase";
import { SellingPrice } from "../../../../projection/prices/model/Prices";

type ReceivePurchaseProductDTO = {
  readonly id: string;
  readonly order_line_id: string;
  readonly title: string;
  readonly provider_product_reference: string;
  readonly brand_id: string;
  readonly family_id: string;
  readonly manufacturing_country: string;
  readonly season: number;

  readonly printed: boolean;
  readonly restock: boolean;
  readonly medias: string[];
  readonly features: {
    readonly id: string;
    readonly values: {
      readonly id: string; // feature_value_id
      readonly qualifier?: {
        readonly id: string; // feature_id
        readonly values: string[]; // feature_value_id
      };
    }[];
  }[];
  readonly product_variants: {
    readonly id: string;
    readonly quantity: number;
    readonly unit_id: string;
    readonly color_id: string;
    readonly size_id: string;
    readonly cost_price_currency: string;
    readonly cost_price_amount: number;
  }[];
  readonly selling_prices: SellingPrice[];
};

export type ReceivePurchaseDto = {
  readonly id: string;
  readonly invoice_number: string | null;
  readonly invoice_date: string | null;
  readonly provider_delivery_note_number: string | null;
  readonly provider_delivery_note_date: string | null;
  readonly entry_date: string;
  readonly warehouse_location: string;
  readonly products: ReceivePurchaseProductDTO[];
  readonly segment: string | null;
};

export const receivePurchaseDtoFromPurchase = (purchase: Purchase): ReceivePurchaseDto => ({
  id: purchase.id,
  entry_date: toISO(purchase.entryDate),
  warehouse_location: purchase.warehouseLocation,
  invoice_number: purchase.invoiceNumber !== "" ? purchase.invoiceNumber : null,
  invoice_date: purchase.invoiceDate && purchase.invoiceNumber !== "" ? toISO(purchase.invoiceDate as Date) : null,
  provider_delivery_note_number:
    purchase.providerDeliveryNoteNumber !== "" ? purchase.providerDeliveryNoteNumber : null,
  provider_delivery_note_date:
    purchase.providerDeliveryNoteDate && purchase.providerDeliveryNoteNumber !== ""
      ? toISO(purchase.providerDeliveryNoteDate as Date)
      : null,
  products: (purchase.products as PurchasedProduct[]).map(product => {
    // TODO: change!
    const sellingPrices = convertSellingPricesToDTO(product.sellingPrices);

    return {
      id: product.id,
      order_line_id: product.orderLineId,
      title: product.title,
      provider_product_reference: product.providerProductReference,
      brand_id: product.brandId,
      family_id: product.familyId,
      manufacturing_country: product.manufacturingCountry,
      season: product.season,
      selling_prices: sellingPrices,
      restock: product.restock,
      printed: Boolean(product.printed),
      medias: product.medias,
      features: product.features.map(feature => ({
        ...feature,
        values: feature.values.map(value => ({
          ...value,
          qualifier: value.qualifier
            ? {
                ...value.qualifier,
                values: value.qualifier.values.map(({ id }) => id),
              }
            : undefined,
        })),
      })),
      product_variants: product.productVariants.map(variant => ({
        id: variant.id,
        unit_id: variant.unitId,
        quantity: variant.quantity,
        color_id: variant.colorId,
        size_id: variant.sizeId,
        cost_price_currency: variant.costPriceCurrency,
        cost_price_amount: variant.costPriceAmount,
      })),
    };
  }),
  segment: purchase.segment,
});

export type EditPurchaseDto = {
  id: string;
  entry_date: string;
  warehouse_location: string;
  invoice_number: string | null;
  invoice_date: string | null;
  provider_delivery_note_number: string | null;
  provider_delivery_note_date: string | null;
  segment: string | null;
};

export const editPurchaseDtoFromPurchase = (purchase: Purchase): EditPurchaseDto => ({
  id: purchase.id,
  entry_date: toISO(purchase.entryDate),
  warehouse_location: purchase.warehouseLocation,
  invoice_number: purchase.invoiceNumber,
  invoice_date: purchase.invoiceDate ? toISO(purchase.invoiceDate) : null,
  provider_delivery_note_number: purchase.providerDeliveryNoteNumber,
  provider_delivery_note_date: purchase.providerDeliveryNoteDate ? toISO(purchase.providerDeliveryNoteDate) : null,
  segment: purchase.segment,
});

export type InvoicePurchaseDto = {
  readonly id: string;
};
export const invoicePurchaseDtoFromPurchase = (purchase: Purchase): InvoicePurchaseDto => ({
  id: purchase.id,
});
