import { Command } from "@lookiero/messaging.js";

interface CreateBrandParameters {
  readonly brandId: string;
  readonly brandName: string;
  readonly brandOrigin: string;
}

class CreateBrand extends Command {
  readonly brandId: string;
  readonly brandName: string;
  readonly brandOrigin: string;

  public constructor({ brandId, brandName, brandOrigin }: CreateBrandParameters) {
    super();
    this.brandId = brandId;
    this.brandName = brandName;
    this.brandOrigin = brandOrigin;
  }

  public messageName(): string {
    return "CreateBrand";
  }
}

export default CreateBrand;
