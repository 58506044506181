import { QueryHandler } from "@lookiero/messaging.js";
import OrderLineView from "../../model/OrderLineView";
import SuggestDeliveryEstimation from "./SuggestDeliveryEstimation";

class SuggestDeliveryEstimationHandler implements QueryHandler<SuggestDeliveryEstimation> {
  private readonly orderLineView: OrderLineView;

  public constructor(orderLineView: OrderLineView) {
    this.orderLineView = orderLineView;
  }

  public async handle({ orderId }: SuggestDeliveryEstimation): Promise<Date | null> {
    return await this.orderLineView.suggestDeliveryEstimation(orderId);
  }
}

export default SuggestDeliveryEstimationHandler;
