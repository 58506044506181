import Brand from "../../../../domain/brand/model/Brand";

export type CreateBrandRequestDTO = {
  id: string;
  name: string;
  origin: string;
};

const createBrandRequestDtoFromBrand = (brand: Brand): CreateBrandRequestDTO => ({
  id: brand.id,
  name: brand.name.trim(),
  origin: brand.origin,
});

export { createBrandRequestDtoFromBrand };
