import { CommandHandler } from "@lookiero/messaging.js";
import CloseOrderLine from "./CloseOrderLine";
import OrderLineRepository from "./../../model/OrderLineRepository";

class CloseOrderLineHandler implements CommandHandler<CloseOrderLine> {
  private orderLineRepository: OrderLineRepository;

  public constructor(orderLineRepository: OrderLineRepository) {
    this.orderLineRepository = orderLineRepository;
  }

  public async execute({ orderLineId }: CloseOrderLine): Promise<void> {
    const orderLine = await this.orderLineRepository.get(orderLineId);

    if (!orderLine) {
      throw new Error(`There is no OrderLine for the provided id: ${orderLineId}`);
    }

    orderLine.close();

    await this.orderLineRepository.close(orderLine);
  }
}

export default CloseOrderLineHandler;
