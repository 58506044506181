import { Query } from "@lookiero/messaging.js";

class IsUserLoggedIn extends Query {
  /* istanbul ignore next */
  public messageName(): string {
    return "IsUserLoggedIn";
  }
}

export default IsUserLoggedIn;
