import HttpClient from "../../../../../shared/delivery/HttpClient";
import BundleTagView from "@src/core/projection/bundleTag/model/BundleTagView";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpBundleTagView implements BundleTagView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<string[]> {
    try {
      const response = await this.httpClient.post("/list-bundle-tags", {});
      if (!response.ok) {
        throw new Error("obtener las etiquetas del servicio");
      }
      const result = await response.json();
      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener las etiquetas del servicio");
    }
  }
}

export default HttpBundleTagView;
