import { CommandHandler } from "@lookiero/messaging.js";
import UnmarkOrderLineAsRestock from "./UnmarkOrderLineAsRestock";
import OrderLineRepository from "../../model/OrderLineRepository";

class UnmarkOrderLineAsRestockHandler implements CommandHandler<UnmarkOrderLineAsRestock> {
  private orderLineRepository: OrderLineRepository;

  public constructor(orderLineRepository: OrderLineRepository) {
    this.orderLineRepository = orderLineRepository;
  }

  public async execute({ orderLineId }: UnmarkOrderLineAsRestock): Promise<void> {
    const orderLine = await this.orderLineRepository.get(orderLineId);

    if (!orderLine) {
      throw new Error(`There is no OrderLine for the provided id: ${orderLineId}`);
    }

    orderLine.unmarkAsRestock();

    await this.orderLineRepository.unmarkAsRestock(orderLine);
  }
}

export default UnmarkOrderLineAsRestockHandler;
