import { DomainEvent, useAsk, useDispatch } from "@lookiero/messaging.js";
import ThrowError from "@src/core/domain/error/command/throwError/ThrowError";
import NotificationCreated from "@src/core/domain/notification/model/NotificationCreated";
import NotificationRemoved from "@src/core/domain/notification/model/NotificationRemoved";
import ListNotifications from "@src/core/projection/notification/query/listNotifications/ListNotifications";
import instanceOfClass from "@src/shared/_types/instanceOfClass";
import Notification from "@src/core/projection/notification/model/Notification";

interface UseListNotificationsFunctionResult {
  readonly notifications: Notification[];
}

interface UseListNotificationsFunction {
  (): UseListNotificationsFunctionResult;
}

const useListNotifications: UseListNotificationsFunction = () => {
  const dispatch = useDispatch();
  const { data: notifications = [] } = useAsk<Notification[], Error>({
    query: new ListNotifications(),
    invalidation: (event: DomainEvent) => instanceOfClass(event, [NotificationCreated, NotificationRemoved]),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });

  return { notifications };
};

export default useListNotifications;
