import { DomainEvent } from "@lookiero/messaging.js";

/* istanbul ignore next */
class UserLoggedIn extends DomainEvent {
  public messageName(): string {
    return "UserLoggedIn";
  }
}

export default UserLoggedIn;
