import { DomainEvent } from "@lookiero/messaging.js";

type ProviderCreatedParameters = {
  readonly providerId: string;
};

class ProviderCreated extends DomainEvent {
  public readonly providerId: string;

  public constructor({ providerId }: ProviderCreatedParameters) {
    super();
    this.providerId = providerId;
  }

  public messageName(): string {
    return "ProviderCreated";
  }
}

export default ProviderCreated;
