import { CommandHandler } from "@lookiero/messaging.js";
import StartOrder from "./StartOrder";
import Order from "../../model/Order";
import OrderRepository from "../../model/OrderRepository";

class StartOrderHandler implements CommandHandler<StartOrder> {
  private orderRepository: OrderRepository;

  public constructor(orderRepository: OrderRepository) {
    this.orderRepository = orderRepository;
  }

  public async execute({ orderId, seasonId, brandId, recommendationId, b2b, segment }: StartOrder): Promise<void> {
    const order = Order.start(orderId, seasonId, brandId, recommendationId, b2b, segment);

    await this.orderRepository.start(order);
  }
}

export default StartOrderHandler;
