import React, { createContext, ReactNode, useContext } from "react";
import { DomainEvent, useAsk } from "@lookiero/messaging.js";
import UserLoggedIn from "@src/core/domain/user/model/UserLoggedIn";
import GetCurrentUser from "@src/core/projection/user/query/getCurrentUser/GetCurrentUser";
import User from "@src/core/projection/user/model/User";
import UserLoggedOut from "@src/core/domain/user/model/UserLoggedOut";

const UserViewContext = createContext<User | undefined>(undefined);

interface UserViewContextProviderProps {
  readonly children: ReactNode;
}

const UserProvider: React.FC<UserViewContextProviderProps> = ({ children }: UserViewContextProviderProps) => {
  const { data } = useAsk<User, Error>({
    query: new GetCurrentUser(),
    invalidation: (event: DomainEvent) => event instanceof UserLoggedIn || event instanceof UserLoggedOut,
    options: { staleTime: Infinity },
  });

  return <UserViewContext.Provider value={data}>{children}</UserViewContext.Provider>;
};

export const useGetCurrentUser = (): User | undefined => {
  return useContext<User | undefined>(UserViewContext);
};

export default UserProvider;
