import { QueryHandler } from "@lookiero/messaging.js";
import ProviderOptions from "../../model/ProviderOptions";
import ProviderView from "../../model/ProviderView";
import SearchProviderOptions from "./SearchProviderOptions";

class SearchProviderOptionsHandler implements QueryHandler<SearchProviderOptions> {
  private readonly view: ProviderView;

  public constructor(view: ProviderView) {
    this.view = view;
  }

  public async handle(): Promise<ProviderOptions> {
    return await this.view.searchOptions();
  }
}

export default SearchProviderOptionsHandler;
