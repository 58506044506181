import { Query } from "@lookiero/messaging.js";

type RestockCriteriaFeature = {
  readonly id: string;
  readonly values: {
    readonly id: string; // feature_value_id
    readonly qualifier?: {
      readonly id: string; // feature_id
      readonly values: { id: string }[]; // feature_value_id
    };
  }[];
};

type SearchProductsByRestockCriteriaParameters = {
  readonly providerProductReference: string;
  readonly brandId: string;
  readonly familyId: string;
  readonly features: RestockCriteriaFeature[];
};

class SearchProductsByRestockCriteria extends Query {
  public readonly providerProductReference: string;
  public readonly brandId: string;
  public readonly familyId: string;
  public readonly features: RestockCriteriaFeature[];

  public constructor({
    providerProductReference,
    brandId,
    familyId,
    features,
  }: SearchProductsByRestockCriteriaParameters) {
    super();

    this.providerProductReference = providerProductReference;
    this.brandId = brandId;
    this.familyId = familyId;
    this.features = features;
  }

  public messageName(): string {
    return "SearchProductsByRestockCriteria";
  }
}

export default SearchProductsByRestockCriteria;
