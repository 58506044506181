import { QueryHandler } from "@lookiero/messaging.js";
import ManufacturingCountriesView from "@src/core/projection/manufacturingCountry/model/ManufacturingCountryView";
import ListManufacturingCountries from "./ListManufacturingCountries";

class ListManufacturingCountriesHandler implements QueryHandler<ListManufacturingCountries> {
  private manufacturingCountriesView: ManufacturingCountriesView;

  public constructor(manufacturingCountriesView: ManufacturingCountriesView) {
    this.manufacturingCountriesView = manufacturingCountriesView;
  }

  public async handle(): Promise<string[]> {
    return await this.manufacturingCountriesView.list();
  }
}

export default ListManufacturingCountriesHandler;
