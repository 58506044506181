import { CommandHandler } from "@lookiero/messaging.js";
import EditOrderLineFromReception from "./EditOrderLineFromReception";
import OrderLineRepository from "../../model/OrderLineRepository";

class EditOrderLineFromReceptionHandler implements CommandHandler<EditOrderLineFromReception> {
  private orderLineRepository: OrderLineRepository;

  public constructor(orderLineRepository: OrderLineRepository) {
    this.orderLineRepository = orderLineRepository;
  }

  public async execute({
    orderLineId,
    providerProductReference,
    title,
    deliveryEstimation,
    familyId,
    printed,
    costPriceCurrency,
    costPriceAmount,
    sellingPrices,
    features,
    units,
    manufacturingCountry,
    targetChannels,
    riskLevel,
  }: EditOrderLineFromReception): Promise<void> {
    const orderLine = await this.orderLineRepository.get(orderLineId);

    if (!orderLine) {
      return;
    }

    orderLine.editFromReception(
      providerProductReference,
      title,
      deliveryEstimation,
      familyId,
      printed,
      costPriceCurrency,
      costPriceAmount,
      sellingPrices,
      features,
      units,
      manufacturingCountry,
      targetChannels,
      riskLevel,
    );

    await this.orderLineRepository.editFromReception(orderLine);
  }
}

export default EditOrderLineFromReceptionHandler;
