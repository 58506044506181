import React, { useCallback } from "react";
import { OptionSelector } from "@lookiero/react-ui-kit";
import useRole, { Role } from "../../_behaviors/useRole";
import { useNavigate } from "react-router-dom";
import ROUTES from "@src/ui/_routing/routes";

const RoleSelector: React.FC = () => {
  const navigate = useNavigate();
  const { role, roles, setRole } = useRole();

  const handleOnRoleChange = useCallback(
    (role: Role) => {
      setRole(role);

      const routeForRole =
        role === Role.BUYING_ASSSISTANT ? `/${ROUTES.BA_ORDERS}` : `/${ROUTES.ORDERS}/${ROUTES.ORDERS_OVERVIEW}`;
      navigate(routeForRole, { replace: true });
    },
    [setRole, navigate],
  );

  return <OptionSelector options={roles} value={role} onChange={handleOnRoleChange as (role: string) => void} />;
};

export default RoleSelector;
