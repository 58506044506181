import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import Season from "../../../../projection/season/model/Season";
import SeasonView from "../../../../projection/season/model/SeasonView";

class HttpSeasonView implements SeasonView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Season[]> {
    try {
      const response = await this.httpClient.post("/search-seasons", {});

      if (!response.ok) {
        throw new Error("obtener las temporadas del servicio");
      }

      const result = await response.json();

      return result.result.map((season: { season: string }) => ({ id: season.season, name: season.season }));
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener las temporadas del servicio");
    }
  }
}

export default HttpSeasonView;
