import { Command } from "@lookiero/messaging.js";
import { Feature } from "../../model/OrderLine";
import OrderLineUnit from "../../model/OrderLineUnit";
import { SellingPrices } from "@src/core/projection/prices/model/Prices";

export type AddOrderLineFromReceptionParameters = {
  readonly orderId: string;
  readonly orderLineId: string;
  readonly providerProductReference: string;
  readonly title: string;
  readonly deliveryEstimation: Date;
  readonly familyId: string;
  readonly printed: boolean;
  readonly costPriceCurrency: string;
  readonly costPriceAmount: number;
  readonly sellingPrices: SellingPrices;
  readonly units: OrderLineUnit[];
  readonly features: Feature[];
  readonly manufacturingCountry: string;
  readonly targetChannels: string[];
  readonly riskLevel: number | null;
};

class AddOrderLineFromReception extends Command {
  public readonly orderId: string;
  public readonly orderLineId: string;
  public readonly providerProductReference: string;
  public readonly title: string;
  public readonly deliveryEstimation: Date;
  public readonly familyId: string;
  public readonly printed: boolean;
  public readonly costPriceCurrency: string;
  public readonly costPriceAmount: number;
  public readonly sellingPrices: SellingPrices;
  public readonly units: OrderLineUnit[];
  public readonly features: Feature[];
  public readonly manufacturingCountry: string;
  public readonly targetChannels: string[];
  public readonly riskLevel: number | null;

  public constructor({
    orderId,
    orderLineId,
    providerProductReference,
    title,
    deliveryEstimation,
    familyId,
    printed,
    costPriceCurrency,
    costPriceAmount,
    sellingPrices,
    units,
    features,
    manufacturingCountry,
    targetChannels,
    riskLevel,
  }: AddOrderLineFromReceptionParameters) {
    super();
    this.orderId = orderId;
    this.orderLineId = orderLineId;
    this.providerProductReference = providerProductReference;
    this.title = title;
    this.deliveryEstimation = deliveryEstimation;
    this.familyId = familyId;
    this.printed = printed;
    this.costPriceCurrency = costPriceCurrency;
    this.costPriceAmount = costPriceAmount;
    this.sellingPrices = sellingPrices;
    this.units = units;
    this.features = features;
    this.manufacturingCountry = manufacturingCountry;
    this.targetChannels = targetChannels;
    this.riskLevel = riskLevel;
  }

  public messageName(): string {
    return "AddOrderLineFromReception";
  }
}

export default AddOrderLineFromReception;
