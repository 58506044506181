import { CommandHandler } from "@lookiero/messaging.js";
import PlaceOrder from "./PlaceOrder";
import Order from "../../model/Order";
import OrderRepository from "../../model/OrderRepository";

class PlaceOrderHandler implements CommandHandler<PlaceOrder> {
  private orderRepository: OrderRepository;

  public constructor(orderRepository: OrderRepository) {
    this.orderRepository = orderRepository;
  }

  public async execute({
    orderId,
    providerId,
    seasonId,
    brandId,
    recommendationId,
    b2b,
    segment,
  }: PlaceOrder): Promise<void> {
    const order = Order.place(orderId, providerId, seasonId, brandId, recommendationId, b2b, segment);

    await this.orderRepository.place(order);
  }
}

export default PlaceOrderHandler;
