import { QueryHandler } from "@lookiero/messaging.js";
import OrderView from "../../model/OrderView";
import CountOrdersByCriteria from "./CountOrdersByCriteria";

class CountOrdersByCriteriaHandler implements QueryHandler<CountOrdersByCriteria> {
  private readonly orderView: OrderView;

  public constructor(orderView: OrderView) {
    this.orderView = orderView;
  }

  public async handle({ criteria }: CountOrdersByCriteria): Promise<number> {
    return await this.orderView.countByCriteria(criteria);
  }
}

export default CountOrdersByCriteriaHandler;
