import { Command } from "@lookiero/messaging.js";
import { Feature, ProductVariant } from "../../../product/model/Product";
import { SellingPrices } from "@src/core/projection/prices/model/Prices";

type ReceivePurchaseProduct = {
  readonly id: string;
  readonly orderLineId: string;
  readonly title: string;
  readonly providerProductReference: string;
  readonly brandId: string;
  readonly familyId: string;
  readonly manufacturingCountry: string;
  readonly season: number;
  readonly sellingPrices: SellingPrices;
  readonly printed: boolean;
  readonly medias: string[];
  readonly restock: boolean;
  readonly features: Feature[];
  readonly productVariants: ProductVariant[];
};

class ReceivePurchase extends Command {
  public readonly purchaseId: string;
  public readonly invoiceNumber: string | null;
  public readonly invoiceDate: Date | null;
  public readonly providerDeliveryNoteNumber: string | null;
  public readonly providerDeliveryNoteDate: Date | null;
  public readonly entryDate: Date;
  public readonly warehouseLocation: string;
  public readonly products: ReceivePurchaseProduct[];
  public readonly segment: string | null;

  public constructor(
    purchaseId: string,
    invoiceNumber: string | null,
    invoiceDate: Date | null,
    providerDeliveryNoteNumber: string | null,
    providerDeliveryNoteDate: Date | null,
    entryDate: Date,
    warehouseLocation: string,
    products: ReceivePurchaseProduct[],
    segment: string | null,
  ) {
    super();
    this.purchaseId = purchaseId;
    this.invoiceNumber = invoiceNumber;
    this.invoiceDate = invoiceDate;
    this.providerDeliveryNoteNumber = providerDeliveryNoteNumber;
    this.providerDeliveryNoteDate = providerDeliveryNoteDate;
    this.entryDate = entryDate;
    this.warehouseLocation = warehouseLocation;
    this.products = products;
    this.segment = segment;
  }

  public messageName(): string {
    return "ReceivePurchase";
  }
}

export default ReceivePurchase;
