import { CommandHandler } from "@lookiero/messaging.js";
import EditOrderLineFromBuyingAssistant from "./EditOrderLineFromBuyingAssistant";
import OrderLineRepository from "../../model/OrderLineRepository";

class EditOrderLineFromBuyingAssistantHandler implements CommandHandler<EditOrderLineFromBuyingAssistant> {
  private orderLineRepository: OrderLineRepository;

  public constructor(orderLineRepository: OrderLineRepository) {
    this.orderLineRepository = orderLineRepository;
  }

  public async execute({
    orderLineId,
    familyId,
    providerProductReference,
    medias,
    features,
    neckline,
    colors,
    targetChannels,
    riskLevel,
  }: EditOrderLineFromBuyingAssistant): Promise<void> {
    const orderLine = await this.orderLineRepository.get(orderLineId);

    if (!orderLine) {
      return;
    }

    orderLine.editFromBuyingAssistant(
      familyId,
      providerProductReference,
      medias,
      features,
      colors,
      neckline,
      targetChannels,
      riskLevel,
    );

    await this.orderLineRepository.editFromBuyingAssistant(orderLine);
  }
}

export default EditOrderLineFromBuyingAssistantHandler;
