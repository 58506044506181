import { QueryHandler } from "@lookiero/messaging.js";
import ListSeasons from "./ListSeasons";
import Season from "../../model/Season";
import SeasonView from "../../model/SeasonView";

class ListSeasonsHandler implements QueryHandler<ListSeasons> {
  private seasonView: SeasonView;

  public constructor(seasonView: SeasonView) {
    this.seasonView = seasonView;
  }

  public async handle(/*query: ListSeasons*/): Promise<Season[]> {
    return await this.seasonView.list();
  }
}

export default ListSeasonsHandler;
