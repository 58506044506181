import { CommandHandler } from "@lookiero/messaging.js";
import Purchase from "../../model/Purchase";
import ReceivePurchase from "./ReceivePurchase";
import PurchaseRepository from "../../model/PurchaseRepository";

class ReceivePurchaseHandler implements CommandHandler<ReceivePurchase> {
  private purchaseRepository: PurchaseRepository;

  public constructor(purchaseRepository: PurchaseRepository) {
    this.purchaseRepository = purchaseRepository;
  }

  public async execute({
    purchaseId: id,
    invoiceNumber,
    invoiceDate,
    providerDeliveryNoteNumber,
    providerDeliveryNoteDate,
    entryDate,
    warehouseLocation,
    products,
    segment,
  }: ReceivePurchase): Promise<void> {
    const purchase = Purchase.receive(
      id,
      invoiceNumber,
      invoiceDate,
      providerDeliveryNoteNumber,
      providerDeliveryNoteDate,
      entryDate,
      warehouseLocation,
      products,
      segment,
    );

    await this.purchaseRepository.receive(purchase);
  }
}

export default ReceivePurchaseHandler;
