import HttpClient from "../../../../../shared/delivery/HttpClient";
import BrandView from "../../../../projection/brand/model/BrandView";
import Brand from "../../../../projection/brand/model/Brand";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpBrandView implements BrandView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Brand[]> {
    try {
      const response = await this.httpClient.post("/search-brands-by-criteria", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener las marcas del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener las marcas del servicio");
    }
  }
}

export default HttpBrandView;
