import { DomainEvent } from "@lookiero/messaging.js";

class OrderStarted extends DomainEvent {
  public readonly orderId: string;

  public constructor(orderId: string) {
    super();
    this.orderId = orderId;
  }

  public messageName(): string {
    return "OrderStarted";
  }
}

export default OrderStarted;
