import { Query } from "@lookiero/messaging.js";

interface ViewOrderLineByIdParameters {
  readonly orderLineId: string;
}

class ViewOrderLineById extends Query {
  public readonly orderLineId: string;

  public constructor({ orderLineId }: ViewOrderLineByIdParameters) {
    super();
    this.orderLineId = orderLineId;
  }

  public messageName(): string {
    return "ViewOrderLineById";
  }
}

export default ViewOrderLineById;
