import Product from "@src/core/domain/product/model/Product";
import { convertSellingPricesToDTO } from "../../../../../shared/dataTransformer/prices";
import { SellingPrice } from "@src/core/projection/prices/model/Prices";

export type EditProductDto = {
  readonly id: string;
  readonly provider_product_reference: string;
  readonly family_id: string;
  readonly manufacturing_country: string;
  readonly title: string;
  readonly group: string;
  readonly printed: boolean;
  readonly medias: string[];
  readonly target_channels: string[];
  readonly features: {
    readonly id: string;
    readonly values: {
      readonly id: string; // feature_value_id
      readonly qualifier?: {
        readonly id: string; // feature_id
        readonly values: string[]; // feature_value_id
      };
    }[];
  }[];
  readonly product_variants: {
    readonly id: string;
    readonly quantity: number;
    readonly unit_id: string;
    readonly color_id: string;
    readonly size_id: string;
    readonly cost_price_currency: string;
    readonly cost_price_amount: number;
  }[];
  readonly selling_prices: SellingPrice[];
};

export const editProductDtoFromProduct = (product: Product): EditProductDto => {
  const sellingPrices = convertSellingPricesToDTO(product.sellingPrices);

  return {
    id: product.id,
    provider_product_reference: product.providerProductReference,
    family_id: product.familyId,
    manufacturing_country: product.manufacturingCountry,
    title: product.title,
    group: product.group,
    printed: product.printed,
    medias: product.medias,
    selling_prices: sellingPrices,
    target_channels: product.targetChannels,
    features: product.features.map(feature => ({
      ...feature,
      values: feature.values.map(value => ({
        ...value,
        qualifier: value.qualifier
          ? {
              ...value.qualifier,
              values: value.qualifier.values.map(({ id }) => id),
            }
          : undefined,
      })),
    })),
    product_variants: product.productVariants.map(variant => ({
      id: variant.id,
      unit_id: variant.unitId,
      quantity: variant.quantity,
      color_id: variant.colorId,
      size_id: variant.sizeId,
      cost_price_currency: variant.costPriceCurrency,
      cost_price_amount: variant.costPriceAmount,
    })),
  };
};
