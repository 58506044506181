import { QueryHandler } from "@lookiero/messaging.js";
import Product from "../../model/Product";
import ProductView from "../../model/ProductView";
import ViewProductById from "./ViewProductById";

class ViewProductByIdHandler implements QueryHandler<ViewProductById> {
  private readonly productView: ProductView;

  public constructor(productView: ProductView) {
    this.productView = productView;
  }

  public async handle({ productId }: ViewProductById): Promise<Product | undefined> {
    return await this.productView.byId(productId);
  }
}

export default ViewProductByIdHandler;
