import { QueryHandler } from "@lookiero/messaging.js";
import OrderLineRiskLevelsView from "../../model/OrderLineRiskLevelsView";
import ListOrderLineRiskLevels from "./ListOrderLineRiskLevels";

class ListOrderLineRiskLevelsHandler implements QueryHandler<ListOrderLineRiskLevels> {
  private orderLineRiskLevelsView: OrderLineRiskLevelsView;

  public constructor(orderLineRiskLevelsView: OrderLineRiskLevelsView) {
    this.orderLineRiskLevelsView = orderLineRiskLevelsView;
  }

  public async handle(): Promise<string[]> {
    return await this.orderLineRiskLevelsView.listOrderLineRiskLevels();
  }
}

export default ListOrderLineRiskLevelsHandler;
