import Currency from "@src/core/projection/currency/model/Currency";
import ProviderOptions from "@src/core/projection/provider/model/ProviderOptions";
import Provider from "../../../../projection/provider/model/Provider";

export type ProviderResponseDto = {
  readonly id: string;
  readonly name: string;
  readonly address: string;
  readonly country: string;
  readonly currency: string;
  readonly operation: string;
  readonly payment_method: string;
  readonly tin: string;
};

export type ProviderOptionsDto = {
  readonly provider_operations: string[];
  readonly provider_payment_methods: string[];
  readonly provider_countries: string[];
  readonly provider_currencies: string[];
};

const providerResponseDtoToProviderProjection = (provider: ProviderResponseDto): Provider => ({
  id: provider.id,
  name: provider.name,
  address: provider.address,
  country: provider.country,
  currency: provider.currency,
  operation: provider.operation,
  paymentMethod: provider.payment_method,
  tin: provider.tin,
});

const providerOptionsResponseDtoToProviderOptionsProjections = (
  providerOptions: ProviderOptionsDto,
): ProviderOptions => ({
  operations: providerOptions.provider_operations,
  paymentMethods: providerOptions.provider_payment_methods,
  countries: providerOptions.provider_countries,
  currencies: providerOptions.provider_currencies as Currency[],
});

export { providerResponseDtoToProviderProjection, providerOptionsResponseDtoToProviderOptionsProjections };
