import Currency from "@src/core/projection/currency/model/Currency";
import Purchase from "@src/core/projection/purchase/model/Purchase";
import PurchasePreview from "../../../../projection/purchase/model/PurchasePreview";

type SearchPurchasesByCriteriaPurchaseDto = {
  readonly id: string;
  readonly number: number;
  readonly status: string;
  readonly received_on: string;
  readonly product_quantity: number;
  readonly invoice_number: string;
  readonly invoice_date: string;
  readonly gross_amount: number;
  readonly provider: {
    readonly id: string;
    readonly name: string;
    readonly currency: Currency;
    readonly operation: string;
  };
  readonly warehouse_location: string;
};

type PurchaseDto = {
  readonly id: string;
  readonly number: number;
  readonly status: string;
  readonly priority_position: number;
  readonly received_on: string;
  readonly entry_date: string;
  readonly invoice_date: string | null;
  readonly invoice_number: string | null;
  readonly provider_delivery_note_date: string | null;
  readonly provider_delivery_note_number: string | null;
  readonly warehouse_location: string;
  readonly products: [
    {
      readonly id: string;
      readonly provider_product_reference: string;
      readonly title: string;
      readonly restock: boolean;
      readonly group: string;
      readonly product_variants: {
        readonly id: string;
        readonly quantity: number;
        readonly cost_price_currency: string;
        readonly cost_price_amount: number;
      }[];
      readonly medias: {
        readonly id: string;
        readonly url: string;
      }[];
    },
  ];
  readonly segment: string;
};

const searchPurchasesByCriteriaPurchaseDtoToPurchasePreviewProjection = (
  purchase: SearchPurchasesByCriteriaPurchaseDto,
): PurchasePreview => ({
  id: purchase.id,
  number: purchase.number,
  status: purchase.status,
  receptionDate: new Date(purchase.received_on),
  productQuantity: purchase.product_quantity,
  invoiceNumber: purchase.invoice_number,
  invoiceDate: purchase.invoice_date ? new Date(purchase.invoice_date) : null,
  grossAmount: purchase.gross_amount,
  provider: purchase.provider,
  warehouseLocation: purchase.warehouse_location,
});

const purchaseResponseDtoToPurchaseProjection = (purchase: PurchaseDto): Purchase => ({
  id: purchase.id,
  number: purchase.number,
  status: purchase.status,
  priorityPosition: purchase.priority_position,
  receivedOn: new Date(purchase.received_on),
  entryDate: new Date(purchase.entry_date),
  invoiceDate: purchase.invoice_date ? new Date(purchase.invoice_date) : undefined,
  invoiceNumber: purchase.invoice_number || undefined,
  providerDeliveryNoteDate: purchase.provider_delivery_note_date
    ? new Date(purchase.provider_delivery_note_date)
    : undefined,
  providerDeliveryNoteNumber: purchase.provider_delivery_note_number || undefined,
  warehouseLocation: purchase.warehouse_location,
  products: purchase.products.map(product => ({
    id: product.id,
    providerProductReference: product.provider_product_reference,
    title: product.title,
    restock: product.restock,
    group: product.group,
    productVariants: product.product_variants.map(variant => ({
      id: variant.id,
      quantity: variant.quantity,
      costPriceCurrency: variant.cost_price_currency,
      costPriceAmount: variant.cost_price_amount,
    })),
    medias: product.medias.map(({ id }) => id),
  })),
  segment: purchase.segment,
});

export { purchaseResponseDtoToPurchaseProjection, searchPurchasesByCriteriaPurchaseDtoToPurchasePreviewProjection };
