import React, { ReactNode, useContext, useState, useCallback, useEffect } from "react";
import { invariant } from "ts-invariant";

interface InjectComponentProps {
  readonly children: ReactNode;
}

interface UseInjectComponentApi {
  inject(id: string, component: ReactNode): void;
  render(id: string): ReactNode;
}

const UseInjectComponentContext = React.createContext<UseInjectComponentApi>({} as UseInjectComponentApi);

const UseInjectComponentProvider: React.FC<InjectComponentProps> = ({ children }) => {
  const [store, setStore] = useState<Record<string, ReactNode>>({});

  const inject = useCallback(
    (id: string, component: ReactNode) => setStore(store => ({ ...store, [id]: component })),
    [setStore],
  );

  const render = useCallback((id: string) => store[id], [store]);

  const useInjectComponentApi = {
    inject,
    render,
  };

  return (
    <UseInjectComponentContext.Provider value={useInjectComponentApi}>{children}</UseInjectComponentContext.Provider>
  );
};

const useInjectComponent = (id: string, component?: ReactNode): ReactNode => {
  const { inject, render } = useContext(UseInjectComponentContext);

  useEffect(() => {
    if (id && component) {
      inject(id, component);
    }

    return (): void => inject(id, null);
  }, [inject, id, component]);

  invariant(
    inject,
    "You are trying to use the useInjectComponent hook without wrapping your app with the <UseInjectComponentProvider>.",
  );

  return id && component ? null : render(id);
};

export { UseInjectComponentProvider };

export default useInjectComponent;
