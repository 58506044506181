import Bundle from "@src/core/projection/bundle/model/Bundle";

type BundleDto = {
  readonly id: string;
  readonly number: number;
  readonly purchases: {
    readonly id: string;
    readonly number: number;
    readonly products: {
      readonly id: string;
      readonly group: string;
      readonly brand: {
        readonly id: string;
        readonly name: string;
        readonly origin: string;
        readonly number: number;
      };
      readonly family: {
        readonly id: string;
        readonly category: string;
        readonly name: string;
        readonly number: number;
        readonly letter: string;
      };
      readonly media: string;
      readonly provider_product_reference: string;
      readonly season: number;
      readonly quantity: number;
      readonly restock: boolean;
    }[];
  }[];
  readonly location: string;
  readonly number_of_pallets: number;
  readonly restocks: number;
  readonly colors: number;
  readonly tags: string[];
  readonly note: string;
  readonly quantity: number;
  readonly step_fashion_labs: string;
  readonly step_tagging: string;
};

const bundleDtoToBundleProjection = (dto: BundleDto): Bundle => ({
  id: dto.id,
  number: dto.number,
  purchases: dto.purchases.map(purchase => ({
    id: purchase.id,
    number: purchase.number,
    products: purchase.products.map(product => ({
      id: product.id,
      group: product.group,
      brand: {
        id: product.brand.id,
        name: product.brand.name,
        origin: product.brand.origin,
        number: product.brand.number,
      },
      family: {
        id: product.family.id,
        category: product.family.category,
        name: product.family.name,
        number: product.family.number,
        letter: product.family.letter,
      },
      season: product.season,
      media: product.media,
      providerProductReference: product.provider_product_reference,
      restock: product.restock,
    })),
  })),
  totalQuantity: dto.quantity,
  location: dto.location,
  numberOfPallets: dto.number_of_pallets,
  restocks: dto.restocks,
  colors: dto.colors,
  tags: dto.tags,
  note: dto.note,
  stepFashionLabs: dto.step_fashion_labs,
  stepTagging: dto.step_tagging,
});

export { bundleDtoToBundleProjection };
