import { CommandHandler } from "@lookiero/messaging.js";
import RemoveOrderLine from "./RemoveOrderLine";
import OrderLineRepository from "./../../model/OrderLineRepository";

class RemoveOrderLineHandler implements CommandHandler<RemoveOrderLine> {
  private orderLineRepository: OrderLineRepository;

  public constructor(orderLineRepository: OrderLineRepository) {
    this.orderLineRepository = orderLineRepository;
  }

  public async execute({ orderLineId }: RemoveOrderLine): Promise<void> {
    const orderLine = await this.orderLineRepository.get(orderLineId);

    if (!orderLine) {
      throw new Error(`There is no OrderLine for the provided id: ${orderLineId}`);
    }

    orderLine.remove();

    await this.orderLineRepository.remove(orderLine);
  }
}

export default RemoveOrderLineHandler;
