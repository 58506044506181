import HttpClient from "../HttpClient";
import JwtAuthFetcher from "./JwtAuthFetcher";

type FetcherInstanceType = JwtAuthFetcher | typeof window;

class FetchHttpClient implements HttpClient {
  private apiUrl: string;
  private fetcherInstance: FetcherInstanceType;

  public constructor(apiUrl = "", fetcherInstance: FetcherInstanceType = window) {
    this.apiUrl = apiUrl;
    this.fetcherInstance = fetcherInstance;
  }

  public async get(endpoint: string): Promise<Response> {
    return await this.fetcherInstance.fetch(`${this.apiUrl}${endpoint}`, {
      method: "GET",
      referrer: "",
    });
  }

  public async post(endpoint: string, body: Record<string, unknown>): Promise<Response> {
    return await this.fetcherInstance.fetch(`${this.apiUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  }

  public async delete(endpoint: string): Promise<Response> {
    return await this.fetcherInstance.fetch(`${this.apiUrl}${endpoint}`, {
      method: "DELETE",
    });
  }
}

export default FetchHttpClient;
