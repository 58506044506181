import { AggregateRoot } from "@lookiero/messaging.js";
import PurchaseEdited from "./PurchaseEdited";
import PurchaseInvoiced from "./PurchaseInvoiced";
import PurchaseReceived from "./PurchaseReceived";
import PurchaseStatus from "./PurchaseStatus";
import { Feature, ProductVariant } from "../../product/model/Product";
import { SellingPrices } from "@src/core/projection/prices/model/Prices";

export type PurchasedProduct = {
  readonly id: string;
  readonly orderLineId: string;
  readonly title: string;
  readonly season: number;
  readonly providerProductReference: string;
  readonly brandId: string;
  readonly familyId: string;
  readonly manufacturingCountry: string;
  readonly sellingPrices: SellingPrices;
  readonly printed: boolean;
  readonly medias: string[];
  readonly restock: boolean;
  readonly features: Feature[];
  readonly productVariants: ProductVariant[];
};

class Purchase extends AggregateRoot {
  public id: string;
  public number: number | null;
  public status: PurchaseStatus;
  public entryDate: Date;
  public warehouseLocation: string;
  public invoiceNumber: string | null;
  public invoiceDate: Date | null;
  public providerDeliveryNoteNumber: string | null;
  public providerDeliveryNoteDate: Date | null;
  public products: PurchasedProduct[];
  public segment: string | null;

  public constructor(
    id: string,
    number: number | null,
    status: PurchaseStatus,
    invoiceNumber: string | null,
    invoiceDate: Date | null,
    providerDeliveryNoteNumber: string | null,
    providerDeliveryNoteDate: Date | null,
    entryDate: Date,
    warehouseLocation: string,
    products: PurchasedProduct[],
    segment: string | null,
  ) {
    super();

    this.id = id;
    this.number = number;
    this.status = status;
    this.invoiceNumber = invoiceNumber;
    this.invoiceDate = invoiceDate;
    this.providerDeliveryNoteNumber = providerDeliveryNoteNumber;
    this.providerDeliveryNoteDate = providerDeliveryNoteDate;
    this.entryDate = entryDate;
    this.warehouseLocation = warehouseLocation;
    this.products = products;
    this.segment = segment;
  }

  public static receive(
    id: string,
    invoiceNumber: string | null,
    invoiceDate: Date | null,
    providerDeliveryNoteNumber: string | null,
    providerDeliveryNoteDate: Date | null,
    entryDate: Date,
    warehouseLocation: string,
    products: PurchasedProduct[],
    segment: string | null,
  ): Purchase {
    const instance = new Purchase(
      id,
      null,
      PurchaseStatus.RECEIVED,
      invoiceNumber,
      invoiceDate,
      providerDeliveryNoteNumber,
      providerDeliveryNoteDate,
      entryDate,
      warehouseLocation,
      products,
      segment,
    );
    instance.record(new PurchaseReceived(instance.id));

    return instance;
  }

  public edit(
    invoiceNumber: string | null,
    invoiceDate: Date | null,
    providerDeliveryNoteNumber: string | null,
    providerDeliveryNoteDate: Date | null,
    entryDate: Date,
    warehouseLocation: string,
    segment: string | null,
  ): void {
    this.invoiceNumber = invoiceNumber;
    this.invoiceDate = invoiceDate;
    this.providerDeliveryNoteNumber = providerDeliveryNoteNumber;
    this.providerDeliveryNoteDate = providerDeliveryNoteDate;
    this.entryDate = entryDate;
    this.warehouseLocation = warehouseLocation;
    this.segment = segment;

    this.record(new PurchaseEdited(this.id));
  }

  public static isInvoiceable(status: PurchaseStatus, invoiceNumber: string | null, invoiceDate: Date | null): boolean {
    if (status !== PurchaseStatus.RECEIVED || !invoiceNumber || !invoiceDate) {
      return false;
    }

    const today = new Date();
    return invoiceDate < today;
  }

  public invoice(): void {
    if (!Purchase.isInvoiceable(this.status, this.invoiceNumber, this.invoiceDate)) {
      throw new Error("Purchase not invoiceable");
    }

    this.status = PurchaseStatus.INVOICED;

    this.record(new PurchaseInvoiced(this.id));
  }
}

export default Purchase;
