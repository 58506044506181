import { DomainEvent } from "@lookiero/messaging.js";

type OrderLineAddedParameters = {
  readonly orderLineId: string;
  readonly orderId: string;
};

class OrderLineAdded extends DomainEvent {
  public readonly orderId: string;
  public readonly orderLineId: string;

  public constructor({ orderId, orderLineId }: OrderLineAddedParameters) {
    super();
    this.orderId = orderId;
    this.orderLineId = orderLineId;
  }

  public messageName(): string {
    return "OrderLineAdded";
  }
}

export default OrderLineAdded;
