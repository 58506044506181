import React from "react";
import { NotificationVariant, Stack, StackElement } from "@lookiero/react-ui-kit";
import NotificationReadModel from "@src/core/projection/notification/model/Notification";
import { Notification } from "@lookiero/react-ui-kit";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";

import "./notifications.css";

interface NotificationsProps {
  readonly notifications: NotificationReadModel[];
  readonly className?: string;
  readonly onCancel?: (notificationId: string) => void;
}

const NoticationLevelVariant: Record<NotificationLevel, NotificationVariant> = {
  [NotificationLevel.INFO]: NotificationVariant.BASIC,
  [NotificationLevel.SUCCESS]: NotificationVariant.SUCCESS,
  [NotificationLevel.ERROR]: NotificationVariant.DESTRUCTIVE,
};

const Notifications: React.FC<NotificationsProps> = ({ notifications, onCancel }: NotificationsProps) => (
  <Stack className="notificationsContainer">
    {
      notifications.map(notification => (
        <Notification
          key={notification.id}
          onCancel={() => onCancel && onCancel(notification.id)}
          variant={NoticationLevelVariant[notification.level as NotificationLevel]}
        >
          <div dangerouslySetInnerHTML={{ __html: notification.content }}></div>
        </Notification>
      )) as unknown as StackElement[]
    }
  </Stack>
);

export default Notifications;
