enum Currency {
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP",
  CNY = "CNY",
  SEK = "SEK",
  CHF = "CHF",
}

const CurrencyVariants: Record<Currency, string> = {
  [Currency.EUR]: "€",
  [Currency.GBP]: "£",
  [Currency.USD]: "$",
  [Currency.CNY]: "¥",
  [Currency.SEK]: "kr",
  [Currency.CHF]: "SFr",
};

enum SaleCurrency {
  EUR = Currency.EUR,
  GBP = Currency.GBP,
  SEK = Currency.SEK,
  CHF = Currency.CHF,
}

// EUR is the base currency
enum SaleCurrencyWithoutBase {
  GBP = Currency.GBP,
  SEK = Currency.SEK,
  CHF = Currency.CHF,
}

const RoundedAmountPerCurrency: Record<SaleCurrency, number[] | undefined> = {
  [SaleCurrency.EUR]: undefined,
  [SaleCurrency.GBP]: undefined,
  [SaleCurrency.SEK]: [995],
  [SaleCurrency.CHF]: [495, 995],
};

export { CurrencyVariants, SaleCurrency, SaleCurrencyWithoutBase, RoundedAmountPerCurrency };

export default Currency;
