import { QueryHandler } from "@lookiero/messaging.js";
import OrderView from "../../model/OrderView";
import { Order } from "../../model/Order";
import ViewOrderById from "./ViewOrderById";

class ViewOrderByIdHandler implements QueryHandler<ViewOrderById> {
  private readonly orderView: OrderView;

  public constructor(orderView: OrderView) {
    this.orderView = orderView;
  }

  public async handle({ orderId }: ViewOrderById): Promise<Order | undefined> {
    return await this.orderView.ofId(orderId);
  }
}

export default ViewOrderByIdHandler;
