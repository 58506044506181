import { DomainEvent } from "@lookiero/messaging.js";

type OrderLineRemovedParameters = {
  readonly orderLineId: string;
  readonly orderId: string;
};

class OrderLineRemoved extends DomainEvent {
  public readonly orderLineId: string;
  public readonly orderId: string;

  public constructor({ orderId, orderLineId }: OrderLineRemovedParameters) {
    super();
    this.orderId = orderId;
    this.orderLineId = orderLineId;
  }

  public messageName(): string {
    return "OrderLineRemoved";
  }
}

export default OrderLineRemoved;
