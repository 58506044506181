import { QueryHandler } from "@lookiero/messaging.js";
import OrderLineStatusView from "../../model/OrderLineStatusView";
import ListOrderLineStatusesForPurchaseReception from "./ListOrderLineStatusesForPurchaseReception";

class ListOrderLineStatusesForPurchaseReceptionHandler
  implements QueryHandler<ListOrderLineStatusesForPurchaseReception>
{
  private orderLineStatusView: OrderLineStatusView;

  public constructor(orderLineStatusView: OrderLineStatusView) {
    this.orderLineStatusView = orderLineStatusView;
  }

  public async handle(): Promise<string[]> {
    return await this.orderLineStatusView.listForPurchaseReception();
  }
}

export default ListOrderLineStatusesForPurchaseReceptionHandler;
