import { CommandHandler } from "@lookiero/messaging.js";
import CreateBrand from "./CreateBrand";
import Brand from "../../model/Brand";
import BrandRepository from "../../model/BrandRepository";

class CreateBrandHandler implements CommandHandler<CreateBrand> {
  private brandRepository: BrandRepository;

  public constructor(brandRepository: BrandRepository) {
    this.brandRepository = brandRepository;
  }

  public async execute({ brandId, brandName, brandOrigin }: CreateBrand): Promise<void> {
    const brand = Brand.create(brandId, brandName, brandOrigin);

    await this.brandRepository.create(brand);
  }
}

export default CreateBrandHandler;
