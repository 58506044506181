import { Query } from "@lookiero/messaging.js";

type SearchFeaturesByFamilyForRestockCriteriaParameters = {
  readonly familyId: string;
};

class SearchFeaturesByFamilyForRestockCriteria extends Query {
  public readonly familyId: string;

  public constructor({ familyId }: SearchFeaturesByFamilyForRestockCriteriaParameters) {
    super();
    this.familyId = familyId;
  }

  public messageName(): string {
    return "SearchFeaturesByFamilyForRestockCriteria";
  }
}

export default SearchFeaturesByFamilyForRestockCriteria;
