class OrderLineStatus {
  public static DRAFT = "DRAFT";
  public static PLACED = "PLACED";
  public static REGISTERED = "REGISTERED";
  public static PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED";
  public static FULLY_RECEIVED = "FULLY_RECEIVED";
  public static CLOSED = "CLOSED";
  public static CANCELLED = "CANCELLED";

  public static isRemovable(status: string): boolean {
    return [OrderLineStatus.DRAFT, OrderLineStatus.PLACED, OrderLineStatus.REGISTERED].includes(status);
  }

  public static isEditable(status: string): boolean {
    return [
      OrderLineStatus.DRAFT,
      OrderLineStatus.PLACED,
      OrderLineStatus.REGISTERED,
      OrderLineStatus.PARTIALLY_RECEIVED,
    ].includes(status);
  }
}

export default OrderLineStatus;
