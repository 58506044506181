import { Query } from "@lookiero/messaging.js";

class SearchOrdersByProviderProductReference extends Query {
  public readonly providerProductReference: string;

  public constructor(providerProductReference: string) {
    super();
    this.providerProductReference = providerProductReference;
  }

  public messageName(): string {
    return "SearchOrdersByProviderProductReference";
  }
}

export default SearchOrdersByProviderProductReference;
