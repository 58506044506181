import { OrderLineCriteria, PagedOrderLineCriteria } from "../../../../projection/orderLine/model/OrderLineView";

const toArray = (value: undefined | string | string[]): undefined | string[] =>
  Array.isArray(value) ? value : value !== undefined ? [value] : undefined;

type OrderLineCountCriteriaDto = {
  readonly order_number: string | undefined;
  readonly provider_product_reference: string | undefined;
  readonly order_status: string | string[] | undefined;
  readonly order_line_status: string | string[] | undefined;
  readonly order_season: string | undefined;
  readonly provider_id: string | undefined;
  readonly delivery_estimation: string | undefined;
};

type OrderLineSearchCriteriaDto = OrderLineCountCriteriaDto & {
  readonly page: number;
  readonly per_page: number;
  readonly order_by: string;
  readonly order_type: "ASC" | "DESC";
};

const orderLineCountCriteriaDto = ({
  orderNumber,
  providerProductReference,
  orderLineStatus,
  orderSeason,
  providerId,
  estimatedDeliveryDate,
}: OrderLineCriteria): OrderLineCountCriteriaDto => ({
  order_number: orderNumber,
  provider_product_reference: providerProductReference,
  provider_id: providerId,
  order_line_status: toArray(orderLineStatus),
  order_status: undefined,
  order_season: orderSeason,
  delivery_estimation: estimatedDeliveryDate,
});

const orderLineSearchCriteriaDto = ({
  orderNumber,
  providerProductReference,
  orderLineStatus,
  orderSeason,
  providerId,
  estimatedDeliveryDate,
  page,
  perPage,
}: PagedOrderLineCriteria): OrderLineSearchCriteriaDto => ({
  order_number: orderNumber,
  provider_product_reference: providerProductReference,
  order_line_status: toArray(orderLineStatus),
  order_status: undefined,
  order_season: orderSeason,
  provider_id: providerId,
  delivery_estimation: estimatedDeliveryDate,
  order_by: "number",
  order_type: "DESC",
  page,
  per_page: perPage,
});

export { orderLineCountCriteriaDto, orderLineSearchCriteriaDto };
