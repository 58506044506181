import { CommandHandler } from "@lookiero/messaging.js";
import RegisterOrder from "./RegisterOrder";
import OrderRepository from "../../model/OrderRepository";

class RegisterOrderHandler implements CommandHandler<RegisterOrder> {
  private orderRepository: OrderRepository;

  public constructor(orderRepository: OrderRepository) {
    this.orderRepository = orderRepository;
  }

  public async execute({ orderId }: RegisterOrder): Promise<void> {
    const order = await this.orderRepository.get(orderId);
    if (!order) {
      throw new Error(`There is no Order for the provided id: ${orderId}`);
    }

    order.register();

    await this.orderRepository.register(order);
  }
}

export default RegisterOrderHandler;
