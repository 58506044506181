import Family from "../../../../projection/family/model/Family";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import FamilyView from "../../../../projection/family/model/FamilyView";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpFamilyView implements FamilyView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Family[]> {
    try {
      const response = await this.httpClient.post("/search-families", {});

      if (!response.ok) {
        throw new Error("obtener las familias del servicio");
      }
      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener las familias del servicio");
    }
  }
}

export default HttpFamilyView;
