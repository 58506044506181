import { QueryHandler } from "@lookiero/messaging.js";
import OrderSummary from "../../model/OrderSummary";
import OrderView from "../../model/OrderView";
import ViewOrderSummaryById from "./ViewOrderSummaryById";

class ViewOrderSummaryByIdHandler implements QueryHandler<ViewOrderSummaryById> {
  private readonly orderView: OrderView;

  public constructor(orderView: OrderView) {
    this.orderView = orderView;
  }

  public async handle({ orderId }: ViewOrderSummaryById): Promise<OrderSummary> {
    return await this.orderView.viewSummaryById(orderId);
  }
}

export default ViewOrderSummaryByIdHandler;
