import { AggregateRoot } from "@lookiero/messaging.js";
import BrandCreated from "./BrandCreated";

class Brand extends AggregateRoot {
  public id: string;
  public name: string;
  public origin: string;

  private constructor(id: string, name: string, origin: string) {
    super();
    this.id = id;
    this.name = name;
    this.origin = origin;
  }

  public static create(id: string, name: string, origin: string): Brand {
    const instance = new Brand(id, name, origin);

    instance.record(new BrandCreated({ brandId: id, brandName: instance.name, brandOrigin: instance.origin }));

    return instance;
  }
}

export default Brand;
