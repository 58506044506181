import { CommandHandler } from "@lookiero/messaging.js";
import ProductRepository from "../../model/ProductRepository";
import EditProduct from "./EditProduct";

class EditProductHandler implements CommandHandler<EditProduct> {
  private productRepository: ProductRepository;

  public constructor(productRepository: ProductRepository) {
    this.productRepository = productRepository;
  }

  public async execute({
    productId: id,
    providerProductReference,
    title,
    manufacturingCountry,
    sellingPrices,
    printed,
    features,
    productVariants,
    targetChannels,
  }: EditProduct): Promise<void> {
    const product = await this.productRepository.get(id);

    if (!product) {
      return;
    }

    product.edit(
      providerProductReference,
      title,
      manufacturingCountry,
      sellingPrices,
      printed,
      features,
      productVariants,
      targetChannels,
    );

    await this.productRepository.edit(product);
  }
}

export default EditProductHandler;
