import { CommandHandler } from "@lookiero/messaging.js";
import MarkOrderLineAsRestock from "./MarkOrderLineAsRestock";
import OrderLineRepository from "../../model/OrderLineRepository";

class MarkOrderLineAsRestockHandler implements CommandHandler<MarkOrderLineAsRestock> {
  private orderLineRepository: OrderLineRepository;

  public constructor(orderLineRepository: OrderLineRepository) {
    this.orderLineRepository = orderLineRepository;
  }

  public async execute({ orderLineId, group }: MarkOrderLineAsRestock): Promise<void> {
    const orderLine = await this.orderLineRepository.get(orderLineId);

    if (!orderLine) {
      throw new Error(`There is no OrderLine for the provided id: ${orderLineId}`);
    }

    orderLine.markAsRestock(group);

    await this.orderLineRepository.markAsRestock(orderLine);
  }
}

export default MarkOrderLineAsRestockHandler;
