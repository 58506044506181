import { CommandHandler } from "@lookiero/messaging.js";
import BundleRepository from "../../model/BundleRepository";
import AccomplishStepTagging from "./AccomplishStepTagging";

class AccomplishStepTaggingHandler implements CommandHandler<AccomplishStepTagging> {
  private bundleRepository: BundleRepository;

  public constructor(bundleRepository: BundleRepository) {
    this.bundleRepository = bundleRepository;
  }

  public async execute({ bundleId }: AccomplishStepTagging): Promise<void> {
    const bundle = await this.bundleRepository.get(bundleId);

    if (!bundle) {
      return;
    }

    bundle.accomplishStepTagging();

    await this.bundleRepository.accomplishStepTagging(bundle);
  }
}

export default AccomplishStepTaggingHandler;
