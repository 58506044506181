import { EventBus } from "@lookiero/messaging.js";
import PurchaseRepository from "../../../../domain/purchase/model/PurchaseRepository";
import Purchase from "../../../../domain/purchase/model/Purchase";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import {
  editPurchaseDtoFromPurchase,
  invoicePurchaseDtoFromPurchase,
  receivePurchaseDtoFromPurchase,
} from "./requestBuilder";
import purchaseDtoToPurchase from "./responseMapper";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpPurchaseRepository implements PurchaseRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async get(id: string): Promise<Purchase | undefined> {
    try {
      const response = await this.httpClient.post("/view-purchase-by-id", { purchase_id: id });

      if (!response.ok) {
        throw new Error("obtener compra del servicio");
      }

      const { result } = await response.json();

      return result ? purchaseDtoToPurchase(result) : undefined;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener compra del servicio");
    }
  }

  public async receive(purchase: Purchase): Promise<void> {
    try {
      const response = await this.httpClient.post("/receive-purchase", receivePurchaseDtoFromPurchase(purchase));

      if (!response.ok) {
        throw new Error(
          "recibir su compra. Por favor, verifique que el formulario esté completamente y correctamente rellenado",
        );
      }

      this.eventBus.publish(purchase.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError
        ? error
        : new Error(
            "recibir su compra. Por favor, verifique que el formulario esté completamente y correctamente rellenado",
          );
    }
  }

  public async edit(purchase: Purchase): Promise<void> {
    try {
      const response = await this.httpClient.post("/edit-purchase", editPurchaseDtoFromPurchase(purchase));

      if (!response.ok) {
        throw new Error("Editar compra");
      }

      this.eventBus.publish(purchase.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("Editar compra");
    }
  }

  public async invoice(purchase: Purchase): Promise<void> {
    try {
      const response = await this.httpClient.post("/invoice-purchase", invoicePurchaseDtoFromPurchase(purchase));

      if (!response.ok) {
        throw new Error("facturar compra");
      }

      this.eventBus.publish(purchase.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("facturar compra");
    }
  }
}

export default HttpPurchaseRepository;
