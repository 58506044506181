import React, { ReactNode, useContext } from "react";
import { FormInputOption } from "@lookiero/react-ui-kit";
import { useLocalStorage } from "react-use";
import { invariant } from "ts-invariant";

export enum Role {
  BUYING_ASSSISTANT = "BUYING_ASSSISTANT",
  RECEPTION = "RECEPTION",
}

const roles: FormInputOption[] = [
  { label: "BA", value: Role.BUYING_ASSSISTANT },
  { label: "RE", value: Role.RECEPTION },
];

type RoleContextType = {
  readonly role: Role;
  readonly roles: FormInputOption[];
  readonly setRole: (role: Role) => void;
};

const RoleContext = React.createContext<RoleContextType>(null as unknown as RoleContextType);

interface RoleContextProviderProps {
  readonly children: ReactNode;
}

const RoleProvider: React.FC<RoleContextProviderProps> = ({ children }: RoleContextProviderProps) => {
  const [role, setRole] = useLocalStorage("buying-role", Role.BUYING_ASSSISTANT);

  const value = {
    role: role || Role.BUYING_ASSSISTANT,
    roles,
    setRole,
  };

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

const useRole = (): RoleContextType => {
  const role = useContext<RoleContextType>(RoleContext);

  invariant(role, "Your are trying to use the useRole hook without wrapping your app with the <RoleProvider>.");

  return role;
};

export { RoleProvider };

export default useRole;
