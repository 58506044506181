import { Command } from "@lookiero/messaging.js";

type EditBundleParameters = {
  readonly bundleId: string;
  readonly location: string;
  readonly numberOfPallets: number;
  readonly tags: string[];
};

class EditBundle extends Command {
  readonly bundleId: string;
  readonly location: string;
  readonly numberOfPallets: number;
  readonly tags: string[];

  public constructor({ bundleId, location, numberOfPallets, tags }: EditBundleParameters) {
    super();
    this.bundleId = bundleId;
    this.location = location;
    this.numberOfPallets = numberOfPallets;
    this.tags = tags;
  }

  public messageName(): string {
    return "EditBundleBundle";
  }
}

export default EditBundle;
