import { CommandHandler } from "@lookiero/messaging.js";
import BundleRepository from "../../model/BundleRepository";
import RevertStepFashionLabs from "./RevertStepFashionLabs";

class RevertStepFashionLabsHandler implements CommandHandler<RevertStepFashionLabs> {
  private bundleRepository: BundleRepository;

  public constructor(bundleRepository: BundleRepository) {
    this.bundleRepository = bundleRepository;
  }

  public async execute({ bundleId }: RevertStepFashionLabs): Promise<void> {
    const bundle = await this.bundleRepository.get(bundleId);

    if (!bundle) {
      return;
    }

    bundle.revertStepFashionLabs();

    await this.bundleRepository.revertStepFashionLabs(bundle);
  }
}

export default RevertStepFashionLabsHandler;
