import HttpClient from "@src/shared/delivery/HttpClient";
import BundleStepView from "@src/core/projection/bundleSteps/model/BundleStepView";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpBundleStepsView implements BundleStepView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<string[]> {
    try {
      const response = await this.httpClient.post("/list-bundle-step-statuses", {});

      if (!response.ok) {
        throw new Error("obtener steps del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener steps del servicio");
    }
  }
}

export default HttpBundleStepsView;
