import { Query } from "@lookiero/messaging.js";

class SearchFeaturesForBuyingAssistant extends Query {
  public constructor() {
    super();
  }

  public messageName(): string {
    return "SearchFeaturesForBuyingAssistant";
  }
}

export default SearchFeaturesForBuyingAssistant;
