import { QueryHandler } from "@lookiero/messaging.js";
import SearchOrdersByProviderProductReference from "./SearchOrderByProviderProductReference";
import OrderView from "../../model/OrderView";
import OrderIdAndNumber from "../../model/OrderIdAndNumber";

class SearchOrdersByProviderProductReferenceHandler implements QueryHandler<SearchOrdersByProviderProductReference> {
  private readonly orderView: OrderView;

  public constructor(OrderView: OrderView) {
    this.orderView = OrderView;
  }

  public async handle({
    providerProductReference,
  }: SearchOrdersByProviderProductReference): Promise<OrderIdAndNumber[]> {
    return await this.orderView.searchOrdersByProviderProductReference(providerProductReference);
  }
}

export default SearchOrdersByProviderProductReferenceHandler;
