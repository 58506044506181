import { CommandHandler } from "@lookiero/messaging.js";
import BundleRepository from "../../model/BundleRepository";
import AccomplishStepFashionLabs from "./AccomplishStepFashionLabs";

class AccomplishStepFashionLabsHandler implements CommandHandler<AccomplishStepFashionLabs> {
  private bundleRepository: BundleRepository;

  public constructor(bundleRepository: BundleRepository) {
    this.bundleRepository = bundleRepository;
  }

  public async execute({ bundleId }: AccomplishStepFashionLabs): Promise<void> {
    const bundle = await this.bundleRepository.get(bundleId);

    if (!bundle) {
      return;
    }

    bundle.accomplishStepFashionLabs();

    await this.bundleRepository.accomplishStepFashionLabs(bundle);
  }
}

export default AccomplishStepFashionLabsHandler;
