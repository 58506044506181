import { EventBus } from "@lookiero/messaging.js";
import OrderRepository from "../../../../domain/order/model/OrderRepository";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import Order from "../../../../domain/order/model/Order";
import { toISO } from "../../../../../ui/componentLibrary/_intl/date";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpOrderRepository implements OrderRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async get(orderId: string): Promise<Order | undefined> {
    try {
      const response = await this.httpClient.post("/view-order-by-id", { order_id: orderId });

      if (!response.ok) {
        throw new Error("obtener el pedido del servicio");
      }

      const result = await response.json();

      if (result.result) {
        const order = result.result;

        return new Order(
          order.id,
          order.provider ? order.provider.id : undefined,
          `${order.season}`,
          order.brand ? order.brand.id : undefined,
          order.recommendationId,
          new Date(order.placed_on),
          order.delivered_on ? order.delivered_on.map((deliveredOn: string) => new Date(deliveredOn)) : [],
          order.subtotal,
          order.status,
          order.b2b,
          order.deliveryEstimation ? order.deliveryEstimation : null,
          order.segment,
        );
      }

      return undefined;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener el pedido del servicio");
    }
  }

  public async start(order: Order): Promise<void> {
    try {
      const requestParameters = {
        id: order.id,
        season: order.seasonId,
        brand_id: order.brandId,
        recommendation_id: order.recommendationId,
        b2b: Boolean(order.b2b),
        segment: order.segment,
      };

      const response = await this.httpClient.post("/start-order", requestParameters);

      if (!response.ok) {
        throw new Error("guardar los datos del pedido en el servicio");
      }

      this.eventBus.publish(order.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("guardar los datos del pedido en el servicio");
    }
  }

  public async place(order: Order): Promise<void> {
    try {
      const requestParameters = {
        id: order.id,
        provider_id: order.providerId,
        season: order.seasonId,
        brand_id: order.brandId,
        recommendation_id: order.recommendationId,
        b2b: order.b2b || false,
        delivery_estimation: toISO(new Date()), // should be removed
        segment: order.segment,
      };

      const response = await this.httpClient.post("/place-order", requestParameters);

      if (!response.ok) {
        throw new Error("guardar los datos del pedido en el servicio");
      }

      this.eventBus.publish(order.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("guardar los datos del pedido en el servicio");
    }
  }

  public async complete(order: Order): Promise<void> {
    try {
      const requestParameters = {
        id: order.id,
        provider_id: order.providerId,
        recommendation_id: order.recommendationId,
        b2b: order.b2b,
        delivery_estimation: toISO(new Date()), // should be removed
        segment: order.segment,
      };

      const response = await this.httpClient.post("/complete-order", requestParameters);

      if (!response.ok) {
        throw new Error("completar los datos del pedido en el servicio");
      }

      this.eventBus.publish(order.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("completar los datos del pedido en el servicio");
    }
  }

  public async register(order: Order): Promise<void> {
    try {
      const requestParameters = {
        order_id: order.id,
      };

      const response = await this.httpClient.post("/register-order", requestParameters);

      if (!response.ok) {
        throw new Error("registrar el pedido en el servicio");
      }

      this.eventBus.publish(order.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("registrar el pedido en el servicio");
    }
  }
  public async bulkChangeDeliveryEstimation(order: Order): Promise<void> {
    try {
      const requestParameters = {
        order_id: order.id,
        delivery_estimation: order.deliveryEstimation ? toISO(order.deliveryEstimation) : null,
      };

      const response = await this.httpClient.post("/bulk-change-delivery-estimation-by-order", requestParameters);
      if (!response.ok) {
        throw new Error("Actualizar fecha estimada de pedidos");
      }
      this.eventBus.publish(order.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("Actualizar fecha estimada de pedidos");
    }
  }
}

export default HttpOrderRepository;
