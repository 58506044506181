import Recommendation from "@src/core/projection/recommendation/model/Recommendation";
import RecommendationView from "@src/core/projection/recommendation/model/RecommendationView";
import HttpClient from "@src/shared/delivery/HttpClient";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpRecommendationView implements RecommendationView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Recommendation[]> {
    try {
      const response = await this.httpClient.post("/search-recommendations-by-criteria", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener las recomendaciones del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener las recomendaciones del servicio");
    }
  }
}

export default HttpRecommendationView;
