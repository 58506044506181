import { Command } from "@lookiero/messaging.js";

type BulkChangeDeliveryEstimationParameters = {
  readonly orderId: string;
  readonly deliveryEstimation: Date;
};

class BulkChangeDeliveryEstimation extends Command {
  readonly orderId: string;
  readonly deliveryEstimation: Date;

  public constructor({ orderId, deliveryEstimation }: BulkChangeDeliveryEstimationParameters) {
    super();
    this.orderId = orderId;
    this.deliveryEstimation = deliveryEstimation;
  }

  public messageName(): string {
    return "BulkChangeDeliveryEstimation";
  }
}

export default BulkChangeDeliveryEstimation;
