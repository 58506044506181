import { Query } from "@lookiero/messaging.js";

/* istanbul ignore next */
class GetCurrentUser extends Query {
  public messageName(): string {
    return "GetCurrentUser";
  }
}

export default GetCurrentUser;
