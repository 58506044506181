import { DomainEvent } from "@lookiero/messaging.js";

type ProductEditedParameters = {
  readonly productId: string;
  readonly purchaseId: string;
};

class ProductEdited extends DomainEvent {
  public readonly productId: string;
  public readonly purchaseId: string;

  public constructor({ productId, purchaseId }: ProductEditedParameters) {
    super();
    this.productId = productId;
    this.purchaseId = purchaseId;
  }

  public messageName(): string {
    return "ProductEdited";
  }
}

export default ProductEdited;
