import { QueryHandler } from "@lookiero/messaging.js";
import Purchase from "../../model/Purchase";
import PurchaseView from "../../model/PurchaseView";
import ViewPurchaseById from "./ViewPurchaseById";

class ViewPurchaseByIdHandler implements QueryHandler<ViewPurchaseById> {
  private readonly purchaseView: PurchaseView;

  public constructor(purchaseView: PurchaseView) {
    this.purchaseView = purchaseView;
  }

  public async handle({ purchaseId }: ViewPurchaseById): Promise<Purchase | undefined> {
    return await this.purchaseView.byId(purchaseId);
  }
}

export default ViewPurchaseByIdHandler;
