import { QueryHandler } from "@lookiero/messaging.js";
import OrderLinePreview from "../../model/OrderLinePreview";
import OrderLineView from "../../model/OrderLineView";
import SearchOrderLinesByCriteria from "./SearchOrderLinesbyCriteria";

class SearchOrderLinesByCriteriaHandler implements QueryHandler<SearchOrderLinesByCriteria> {
  private readonly orderLineView: OrderLineView;

  public constructor(orderLineView: OrderLineView) {
    this.orderLineView = orderLineView;
  }
  public async handle({ criteria }: SearchOrderLinesByCriteria): Promise<OrderLinePreview[]> {
    return await this.orderLineView.searchByCriteria(criteria);
  }
}

export default SearchOrderLinesByCriteriaHandler;
