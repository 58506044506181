import { Query } from "@lookiero/messaging.js";

class ViewBundlePriority extends Query {
  public constructor() {
    super();
  }
  public messageName(): string {
    return "GetBundlePriority";
  }
}

export default ViewBundlePriority;
