import { Command } from "@lookiero/messaging.js";

type UnmarkOrderLineAsRestockParameters = {
  readonly orderLineId: string;
};

class UnmarkOrderLineAsRestock extends Command {
  public readonly orderLineId: string;

  public constructor({ orderLineId }: UnmarkOrderLineAsRestockParameters) {
    super();
    this.orderLineId = orderLineId;
  }

  public messageName(): string {
    return "UnmarkOrderLineAsRestock";
  }
}

export default UnmarkOrderLineAsRestock;
