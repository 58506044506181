import { Query } from "@lookiero/messaging.js";
import { PagedSearchPurchasesCriteria } from "../../model/PurchaseView";

class SearchPurchasesByCriteria extends Query {
  public readonly criteria: PagedSearchPurchasesCriteria;

  public constructor(criteria: PagedSearchPurchasesCriteria) {
    super();
    this.criteria = criteria;
  }

  protected messageName(): string {
    return "SearchPurchasesByCriteria";
  }
}

export default SearchPurchasesByCriteria;
