import { Query } from "@lookiero/messaging.js";

class SearchNecklinesByFamily extends Query {
  public readonly familyId: string;

  public constructor(familyId: string) {
    super();
    this.familyId = familyId;
  }
  public messageName(): string {
    return "SearchNecklinesByFamily";
  }
}

export default SearchNecklinesByFamily;
