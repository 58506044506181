const TYPES = {
  MessagingRootComponent: Symbol.for("MessagingRootComponent"),
  CommandBus: Symbol.for("CommandBus"),
  DomainEventBus: Symbol.for("DomainEventBus"),
  UIEventBus: Symbol.for("UIEventBus"),

  Environment: Symbol.for("Environment"),
  Console: Symbol.for("Console"),
  Logger: Symbol.for("Logger"),
  Uppy: Symbol.for("Uppy"),

  HttpClient: Symbol.for("HttpClient"),
  HttpAuthenticatorClient: Symbol.for("HttpAuthenticatorClient"),

  BrandRepository: Symbol.for("BrandRepository"),
  CreateBrandHandler: Symbol.for("CreateBrandHandler"),

  BrandView: Symbol.for("BrandView"),
  BrandOriginView: Symbol.for("BrandOriginView"),

  ListBrandsHandler: Symbol.for("ListBrandsHandler"),
  ListBrandOriginsHandler: Symbol.for("ListBrandOriginsHandler"),

  FamilyView: Symbol.for("FamilyView"),
  ListFamiliesHandler: Symbol.for("ListFamiliesHandler"),

  SeasonView: Symbol.for("SeasonView"),
  ListSeasonsHandler: Symbol.for("ListSeasonsHandler"),

  ColorView: Symbol.for("ColorView"),
  ListColorsHandler: Symbol.for("ListColorsHandler"),

  SizeView: Symbol.for("SizeView"),
  ListSizesForFamilyHandler: Symbol.for("ListSizesForFamilyHandler"),

  ChannelView: Symbol.for("ChannelView"),
  ListChannelsHandler: Symbol.for("ListChannelsHandler"),

  OrderLineRiskLevelsView: Symbol.for("OrderLineRiskLevelsView"),
  ListOrderLineRiskLevelsHandler: Symbol.for("ListOrderLineRiskLevelsHandler"),

  BundleTagView: Symbol.for("BundleTagView"),
  ListBundleTagsHandler: Symbol.for("ListBundleTagsHandler"),

  BundleStepView: Symbol.for("BundleStepView"),
  ListBundleStepsHandler: Symbol.for("ListBundleStepsHandler"),

  RecommendationView: Symbol.for("RecommendationView"),
  ListRecommendationsHandler: Symbol.for("ListRecommendationsHandler"),

  ListManufacturingCountriesHandler: Symbol.for("ListManufacturingCountriesHandler"),
  ManufacturingCountryView: Symbol.for("ManufacturingCountryView"),

  FeatureView: Symbol.for("FeatureView"),
  SearchFeaturesForBuyingAssistantHandler: Symbol.for("SearchFeaturesForBuyingAssistantHandler"),
  SearchFeaturesByFamilyHandler: Symbol.for("SearchFeaturesByFamilyHandler"),
  SearchFeaturesByFamilyForRestockCriteriaHandler: Symbol.for("SearchFeaturesByFamilyForRestockCriteriaHandler"),

  NecklineView: Symbol.for("NecklineView"),
  SearchNecklinesByFamilyHandler: Symbol.for("SearchNecklinesByFamilyHandler"),

  ProviderView: Symbol.for("ProviderView"),
  ListProvidersHandler: Symbol.for("ListProvidersHandler"),
  SearchProviderOptionsHandler: Symbol.for("SearchProviderOptionsHandler"),

  MediaView: Symbol.for("MediaView"),
  ViewMediaByIdHandler: Symbol.for("ViewMediaByIdHandler"),

  OrderStatusView: Symbol.for("OrderStatusView"),
  ListOrderStatusesHandler: Symbol.for("ListOrderStatusesHandler"),

  OrderView: Symbol.for("OrderView"),
  CountOrdersByCriteriaHandler: Symbol.for("CountOrdersByCriteriaHandler"),
  SearchOrdersByCriteriaHandler: Symbol.for("SearchOrdersByCriteriaHandler"),
  SearchOrdersByProviderProductReferenceHandler: Symbol.for("SearchOrdersByProviderProductReferenceHandler"),
  ViewOrderByIdHandler: Symbol.for("ViewOrderByIdHandler"),
  ViewOrderSummaryByIdHandler: Symbol.for("ViewOrderSummaryByIdHandler"),

  OrderRepository: Symbol.for("OrderRepository"),
  PlaceOrderHandler: Symbol.for("PlaceOrderHandler"),
  StartOrderHandler: Symbol.for("StartOrderHandler"),
  CompleteOrderHandler: Symbol.for("CompleteOrderHandler"),
  BulkChangeDeliveryEstimationHandler: Symbol.for("BulkChangeDeliveryEstimationHandler"),
  RegisterOrderHandler: Symbol.for("RegisterOrderHandler"),
  RevertOrderToPlacedFromRegisteredHandler: Symbol.for("RevertOrderToPlacedFromRegisteredHandler"),

  OrderLineStatusView: Symbol.for("OrderLineStatusView"),
  ListOrderLineStatusesForPurchaseReceptionHandler: Symbol.for("ListOrderLineStatusesForPurchaseReceptionHandler"),

  OrderLineView: Symbol.for("OrderLineView"),
  ViewOrderLineByIdHandler: Symbol.for("ViewOrderLineByIdHandler"),
  CountOrderLinesByCriteriaHandler: Symbol.for("CountOrderLinesByCriteriaHandler"),
  SearchOrderLinesByCriteriaHandler: Symbol.for("SearchOrderLinesByCriteriaHandler"),
  SuggestDeliveryEstimationHandler: Symbol.for("SuggestDeliveryEstimationHandler"),

  RestockProductView: Symbol.for("RestockProductView"),
  SearchProductsByRestockCriteriaHandler: Symbol.for("SearchProductsByRestockCriteriaHandler"),
  ViewRestockProductOfGroupHandler: Symbol.for("ViewRestockProductOfGroupHandler"),

  OrderLineRepository: Symbol.for("OrderLineRepository"),
  AddOrderLineFromReceptionHandler: Symbol.for("AddOrderLineFromReceptionHandler"),
  EditOrderLineFromReceptionHandler: Symbol.for("EditOrderLineFromReceptionHandler"),
  AddOrderLineFromBuyingAssistantHandler: Symbol.for("AddOrderLineFromBuyingAssistantHandler"),
  EditOrderLineFromBuyingAssistantHandler: Symbol.for("EditOrderLineFromBuyingAssistantHandler"),
  RemoveOrderLineHandler: Symbol.for("RemoveOrderLineHandler"),
  CloseOrderLineHandler: Symbol.for("CloseOrderLineHandler"),
  CancelOrderLineHandler: Symbol.for("CancelOrderLineHandler"),
  MarkOrderLineAsRestockHandler: Symbol.for("MarkOrderLineAsRestockHandler"),
  UnmarkOrderLineAsRestockHandler: Symbol.for("UnmarkOrderLineAsRestockHandler"),

  BundleView: Symbol.for("BundleView"),
  SearchBundlesByCriteriaHandler: Symbol.for("SearchBundlesByCriteriaHandler"),
  GetBundlePriorityHandler: Symbol.for("GetBundlePriorityHandler"),

  PurchaseRepository: Symbol.for("PurchaseRepository"),
  ReceivePurchaseHandler: Symbol.for("ReceivePurchaseHandler"),
  EditPurchaseHandler: Symbol.for("EditPurchaseHandler"),
  InvoicePurchaseHandler: Symbol.for("InvoicePurchaseHandler"),

  ProviderRepository: Symbol.for("ProviderRepository"),
  CreateProviderHandler: Symbol.for("CreateProviderHandler"),

  PurchaseView: Symbol.for("PurchaseView"),
  CountPurchasesByCriteriaHandler: Symbol.for("CountPurchasesByCriteriaHandler"),
  SearchPurchasesByCriteriaHandler: Symbol.for("SearchPurchasesByCriteriaHandler"),
  ViewPurchaseByIdHandler: Symbol.for("ViewPurchaseByIdHandler"),

  ProductView: Symbol.for("ProductView"),
  ViewProductByIdHandler: Symbol.for("ViewProductByIdHandler"),

  ProductRepository: Symbol.for("ProductRepository"),
  EditProductHandler: Symbol.for("EditProductHandler"),

  BundleRepository: Symbol.for("BundleRepository"),
  CreateBundleHandler: Symbol.for("CreateBundleHandler"),
  EditBundleHandler: Symbol.for("EditBundleHandler"),
  EditBundleNoteHandler: Symbol.for("EditBundleNoteHandler"),
  AccomplishStepFashionLabsHandler: Symbol.for("AccomplishStepFashionLabsHandler"),
  RevertStepFashionLabsHandler: Symbol.for("RevertStepFashionLabsHandler"),
  AccomplishStepTaggingHandler: Symbol.for("AccomplishStepTaggingHandler"),
  RevertStepTaggingHandler: Symbol.for("RevertStepTaggingHandler"),
  ChangeBundlePriorityHandler: Symbol.for("ChangeBundlePriorityHandler"),
  DeleteBundleHandler: Symbol.for("DeleteBandleHandler"),

  LocalStorageClient: Symbol.for("LocalStorageClient"),
  HttpAutheticator: Symbol.for("HttpAutheticator"),
  UserRepository: Symbol.for("UserRepository"),
  LoginHandler: Symbol.for("LoginHandler"),
  JwtAuthFetch: Symbol.for("JwtAuthFetch"),
  LogoutHandler: Symbol.for("LogoutHandler"),
  UserView: Symbol.for("UserView"),
  GetCurrentUserHandler: Symbol.for("GetCurrentUserHandler"),
  IsUserLoggedInHandler: Symbol.for("IsUserLoggedInHandler"),

  LogoutWhenUnAuthorizedUserError: Symbol.for("LogoutWhenUnAuthorizedUserError"),
  ThrowCoreErrorHandler: Symbol.for("ThrowCoreErrorHandler"),
  ErrorRepository: Symbol.for("ErrorRepository"),

  SegmentView: Symbol.for("SegmentView"),
  ListSegmentsHandler: Symbol.for("ListSegmentsHandler"),

  NotificationStorageClient: Symbol.for("NotificationStorageClient"),
  NotificationView: Symbol.for("NotificationView"),
  ListNotificationsHandler: Symbol.for("ListNotificationsHandler"),
  NotificationRepository: Symbol.for("NotificationRepository"),
  CreateNotificationHandler: Symbol.for("CreateNotificationHandler"),
  RemoveNotificationHandler: Symbol.for("RemoveNotificationHandler"),
  CreateNotificationWhenError: Symbol.for("CreateNotificationWhenError"),
};

export { TYPES };
