import { QueryHandler } from "@lookiero/messaging.js";
import Recommendation from "../../model/Recommendation";
import RecommendationView from "../../model/RecommendationView";
import ListRecommendations from "./ListRecommendations";

class ListRecommendationsHandler implements QueryHandler<ListRecommendations> {
  private recommendationView: RecommendationView;

  public constructor(recommendationView: RecommendationView) {
    this.recommendationView = recommendationView;
  }

  public async handle(/*query: ListBrands*/): Promise<Recommendation[]> {
    return await this.recommendationView.list();
  }
}

export default ListRecommendationsHandler;
