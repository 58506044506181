import { DomainEvent } from "@lookiero/messaging.js";

/* istanbul ignore next */
class UserLoggedOut extends DomainEvent {
  public messageName(): string {
    return "UserLoggedOut";
  }
}

export default UserLoggedOut;
