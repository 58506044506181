import { Query } from "@lookiero/messaging.js";
import { OrderLineCriteria } from "../../model/OrderLineView";

class CountOrderLinesByCriteria extends Query {
  public readonly criteria: OrderLineCriteria;

  public constructor(criteria: OrderLineCriteria) {
    super();
    this.criteria = criteria;
  }

  protected messageName(): string {
    return "CountOrderLinesByCriteria";
  }
}

export default CountOrderLinesByCriteria;
