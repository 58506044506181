import { CommandHandler } from "@lookiero/messaging.js";
import CompleteOrder from "./CompleteOrder";
import OrderRepository from "../../model/OrderRepository";

class CompleteOrderHandler implements CommandHandler<CompleteOrder> {
  private orderRepository: OrderRepository;

  public constructor(orderRepository: OrderRepository) {
    this.orderRepository = orderRepository;
  }

  public async execute({ orderId, providerId, recommendationId, b2b, segment }: CompleteOrder): Promise<void> {
    const order = await this.orderRepository.get(orderId);

    if (!order) {
      throw new Error(`There is no Order for the provided id: ${orderId}`);
    }

    order.complete(providerId, recommendationId, b2b, segment);

    await this.orderRepository.complete(order);
  }
}

export default CompleteOrderHandler;
