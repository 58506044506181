import { DomainEvent } from "@lookiero/messaging.js";

interface BrandCreatedParameters {
  readonly brandId: string;
  readonly brandName: string;
  readonly brandOrigin: string;
}

class BrandCreated extends DomainEvent {
  public readonly brandId: string;
  public readonly brandName: string;
  public readonly brandOrigin: string;

  public constructor({ brandId, brandName, brandOrigin }: BrandCreatedParameters) {
    super();
    this.brandId = brandId;
    this.brandName = brandName;
    this.brandOrigin = brandOrigin;
  }

  public messageName(): string {
    return "BrandCreated";
  }
}

export default BrandCreated;
