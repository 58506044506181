import { CommandHandler } from "@lookiero/messaging.js";
import AddOrderLineFromReception from "./AddOrderLineFromReception";
import OrderLine from "../../model/OrderLine";
import OrderLineRepository from "../../model/OrderLineRepository";

class AddOrderLineFromReceptionHandler implements CommandHandler<AddOrderLineFromReception> {
  private orderLineRepository: OrderLineRepository;

  public constructor(orderLineRepository: OrderLineRepository) {
    this.orderLineRepository = orderLineRepository;
  }

  public async execute({
    orderId,
    orderLineId,
    providerProductReference,
    title,
    deliveryEstimation,
    familyId,
    printed,
    costPriceCurrency,
    costPriceAmount,
    sellingPrices,
    units,
    features,
    manufacturingCountry,
    targetChannels,
    riskLevel,
  }: AddOrderLineFromReception): Promise<void> {
    const orderLine = OrderLine.fromReception(
      orderId,
      orderLineId,
      providerProductReference,
      title,
      deliveryEstimation,
      familyId,
      printed,
      costPriceCurrency,
      costPriceAmount,
      sellingPrices,
      units,
      features,
      manufacturingCountry,
      targetChannels,
      riskLevel,
    );

    await this.orderLineRepository.addFromReception(orderLine);
  }
}

export default AddOrderLineFromReceptionHandler;
