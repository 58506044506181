import { Command } from "@lookiero/messaging.js";

class InvoicePurchase extends Command {
  public readonly purchaseId: string;

  public constructor(purchaseId: string) {
    super();
    this.purchaseId = purchaseId;
  }

  public messageName(): string {
    return "InvoicePurchase";
  }
}

export default InvoicePurchase;
