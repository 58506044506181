import { DomainEvent } from "@lookiero/messaging.js";

class BundleEdited extends DomainEvent {
  public readonly bundleId: string;

  public constructor(bundleId: string) {
    super();
    this.bundleId = bundleId;
  }

  public messageName(): string {
    return "BundleEdited";
  }
}

export default BundleEdited;
