import { Query } from "@lookiero/messaging.js";

interface ViewOrderOfByIdParameters {
  readonly orderId: string;
}

class ViewOrderById extends Query {
  public readonly orderId: string;

  public constructor({ orderId }: ViewOrderOfByIdParameters) {
    super();
    this.orderId = orderId;
  }

  public messageName(): string {
    return "ViewOrderById";
  }
}

export default ViewOrderById;
