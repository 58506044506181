import { QueryHandler } from "@lookiero/messaging.js";
import ViewRestockProductOfGroup from "./ViewRestockProductOfGroup";
import RestockProduct from "../../model/RestockProduct";
import RestockProductView from "../../model/RestockProductView";

class ViewRestockProductOfGroupHandler implements QueryHandler<ViewRestockProductOfGroup> {
  private restockProductView: RestockProductView;

  public constructor(restockProductView: RestockProductView) {
    this.restockProductView = restockProductView;
  }

  public async handle({ group }: ViewRestockProductOfGroup): Promise<RestockProduct | undefined> {
    return await this.restockProductView.ofGroup(group);
  }
}

export default ViewRestockProductOfGroupHandler;
