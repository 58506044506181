import Channel from "@src/core/projection/channel/model/Channel";
import ChannelView from "@src/core/projection/channel/model/ChannelView";
import HttpClient from "@src/shared/delivery/HttpClient";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpChannelView implements ChannelView {
  private httpClient: HttpClient;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Channel[]> {
    try {
      const response = await this.httpClient.post("/list-channels", {
        per_page: -1,
      });

      if (!response.ok) {
        throw new Error("obtener los canales del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener los canales del servicio");
    }
  }
}

export default HttpChannelView;
