import HttpClient from "@src/shared/delivery/HttpClient";
import ProductView from "@src/core/projection/product/model/ProductView";
import Product from "@src/core/projection/product/model/Product";
import { productDtoToProductProjection } from "./responseMapper";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpProductView implements ProductView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async byId(productId: string): Promise<Product | undefined> {
    try {
      const response = await this.httpClient.post("/view-product-by-id", { id: productId });

      if (!response.ok) {
        throw new Error("View Product by Id");
      }

      const { result } = await response.json();

      if (result) {
        return productDtoToProductProjection(result);
      }

      return undefined;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("View Product by Id");
    }
  }
}

export default HttpProductView;
