import BrandOrigin from "../../../../projection/brand/model/BrandOrigin";
import RestockProduct from "../../../../projection/restockProduct/model/RestockProduct";

type RestockProductResponseDto = {
  readonly id: string;
  readonly providerProductReference: string;
  readonly group: string;
  readonly title: string;
  readonly brand: {
    readonly id: string;
    readonly name: string;
    readonly type: BrandOrigin;
  };
  readonly family: {
    readonly id: string;
    readonly name: string;
    readonly number: number;
    readonly category: string;
    readonly segment: string;
  };
  readonly media: {
    readonly id: string;
    readonly url: string;
  };
};

const restockProductResponseDtoToRestockProduct = (restockProduct: RestockProductResponseDto): RestockProduct => ({
  id: restockProduct.id,
  providerProductReference: restockProduct.providerProductReference,
  group: restockProduct.group,
  name: restockProduct.title,
  brand: {
    id: restockProduct.brand.id,
    name: restockProduct.brand.name,
    origin: restockProduct.brand.type,
  },
  family: {
    id: restockProduct.family.id,
    name: restockProduct.family.name,
    category: restockProduct.family.category,
    segment: restockProduct.family.segment,
  },
  media: restockProduct.media,
});

export { restockProductResponseDtoToRestockProduct };
