import StorageClient from "../StorageClient";

class LocalStorageClient implements StorageClient {
  private readonly storageName: string;

  public constructor(storageName: string) {
    this.storageName = storageName;
  }

  private encode(value: unknown): string {
    if (!value) return "";

    return JSON.stringify(value);
  }

  private decode(value: string): string | number | Record<string, unknown> {
    if (!value) return "";

    return JSON.parse(value);
  }

  private getStorageKey(key: string): string {
    return `${this.storageName}.${key}`;
  }

  public async get(key: string): Promise<unknown> {
    const storageKey = this.getStorageKey(key);

    const json = localStorage.getItem(storageKey) || "";

    const result = this.decode(json);

    return Promise.resolve(result);
  }

  public async getAll(): Promise<unknown[]> {
    const regex = new RegExp(`^${this.storageName}`);
    return Object.entries(localStorage).reduce((acc, current) => {
      const [key, values] = current;
      if (key.match(regex)) {
        return [...acc, [key, this.decode(values)]];
      }
      return acc;
    }, [] as unknown[]);
  }

  public async has(key: string): Promise<boolean> {
    const storageKey = this.getStorageKey(key);
    const exists = localStorage.hasOwnProperty(storageKey);
    return Promise.resolve(exists);
  }

  public async store(key: string, item: unknown): Promise<void> {
    const storageKey = this.getStorageKey(key);
    const encodedValue = this.encode(item);

    localStorage.setItem(storageKey, encodedValue);

    return Promise.resolve();
  }

  public async delete(key: string): Promise<void> {
    const storageKey = this.getStorageKey(key);

    localStorage.removeItem(storageKey);

    return Promise.resolve();
  }
  public async clear(): Promise<void> {
    localStorage.clear();

    return Promise.resolve();
  }
}

export default LocalStorageClient;
