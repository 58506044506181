import HttpClient from "../../../../../shared/delivery/HttpClient";
import MediaView from "../../../../projection/media/model/MediaView";
import Media from "../../../../projection/media/model/Media";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpMediaView implements MediaView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async ofId(mediaId: string): Promise<Media | undefined> {
    try {
      const response = await this.httpClient.post("/view-media-by-id", { id: mediaId });

      if (!response.ok) {
        throw new Error("obtener el objeto media del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener el objeto media del servicio");
    }
  }
}

export default HttpMediaView;
