/* eslint-disable @typescript-eslint/no-unused-vars */
import { Transaction, TransactionContext } from "@sentry/types";
import { MatchRoutes, UseEffect, UseLocation, UseNavigationType } from "./types";
import { WINDOW } from "@sentry/react";

/**
 * "@sentry/react"'s reactRouterV6Instrumentation has a bug and we have to patch its implementation.
 * (regarding location and matchRoutes)
 */

type TransactionSource = "url" | "route";

interface NavigationTransactionContext extends TransactionContext {
  readonly tags: {
    readonly ["routing.instrumentation"]: string;
    readonly ["routing.route.name"]: string;
  };
  readonly metadata: {
    readonly source: TransactionSource;
  };
}

interface TransactionContextFunctionArgs {
  readonly instrumentationName: string;
  readonly op: string;
  readonly routeName: string;
  readonly source: TransactionSource;
}

interface TransactionContextFunction {
  (args: TransactionContextFunctionArgs): NavigationTransactionContext;
}

const transactionContext: TransactionContextFunction = ({
  instrumentationName,
  op,
  routeName,
  source,
}): NavigationTransactionContext => ({
  name: routeName,
  op,
  tags: {
    "routing.instrumentation": instrumentationName,
    "routing.route.name": routeName,
  },
  metadata: {
    source,
  },
});

const instrumentationName = "react-router-v6";

let activeTransaction: Transaction | undefined;

/* eslint-disable @typescript-eslint/naming-convention */
let _useEffect: UseEffect;
let _useLocation: UseLocation;
let _useNavigationType: UseNavigationType;
let _matchRoutes: MatchRoutes;
let _customStartTransaction: (context: TransactionContext) => Transaction | undefined;
let _startTransactionOnLocationChange: boolean;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let _transformLocation: (location: Location) => Location;
/* eslint-enable @typescript-eslint/naming-convention */

interface ReactRouterV6InstrumentationFunctionArgs {
  readonly useEffect: UseEffect;
  readonly useLocation: UseLocation;
  readonly useNavigationType: UseNavigationType;
  readonly matchRoutes: MatchRoutes;
}

interface ReactRouterV6InstrumentationFunction {
  (
    args: ReactRouterV6InstrumentationFunctionArgs,
  ): (
    customStartTransaction: (context: TransactionContext) => Transaction | undefined,
    startTransactionOnPageLoad?: boolean,
    startTransactionOnLocationChange?: boolean,
  ) => void;
}

const reactRouterV6Instrumentation: ReactRouterV6InstrumentationFunction = ({
  useEffect,
  useLocation,
  useNavigationType,
  matchRoutes,
}) => {
  return (customStartTransaction, startTransactionOnPageLoad = true, startTransactionOnLocationChange = true): void => {
    const initPathName = WINDOW && WINDOW.location && WINDOW.location.pathname;

    if (startTransactionOnPageLoad && initPathName) {
      activeTransaction = customStartTransaction(
        transactionContext({
          instrumentationName,
          op: "pageload",
          routeName: initPathName,
          source: "url",
        }),
      );
    }

    _useEffect = useEffect;
    _useLocation = useLocation;
    _useNavigationType = useNavigationType;
    _matchRoutes = matchRoutes;
    _customStartTransaction = customStartTransaction;
    _startTransactionOnLocationChange = startTransactionOnLocationChange;
  };
};

export { reactRouterV6Instrumentation };
