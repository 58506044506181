import { Command } from "@lookiero/messaging.js";

type CreateProviderParameters = {
  readonly providerId: string;
  readonly providerName: string;
  readonly providerAddress: string;
  readonly providerTin: string;
  readonly providerCountry: string;
  readonly providerCurrency: string;
  readonly providerOperationType: string;
  readonly providerPaymentMethod: string;
};

class CreateProvider extends Command {
  readonly providerId: string;
  readonly providerName: string;
  readonly providerAddress: string;
  readonly providerTin: string;
  readonly providerCountry: string;
  readonly providerCurrency: string;
  readonly providerOperationType: string;
  readonly providerPaymentMethod: string;

  public constructor({
    providerId,
    providerName,
    providerAddress,
    providerTin,
    providerCountry,
    providerCurrency,
    providerOperationType,
    providerPaymentMethod,
  }: CreateProviderParameters) {
    super();
    this.providerId = providerId;
    this.providerName = providerName;
    this.providerAddress = providerAddress;
    this.providerTin = providerTin;
    this.providerCountry = providerCountry;
    this.providerCurrency = providerCurrency;
    this.providerOperationType = providerOperationType;
    this.providerPaymentMethod = providerPaymentMethod;
  }

  public messageName(): string {
    return "CreateProvider";
  }
}

export default CreateProvider;
