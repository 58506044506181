import { Query } from "@lookiero/messaging.js";
import { OrderCriteria } from "../../model/OrderView";

class CountOrdersByCriteria extends Query {
  public readonly criteria: OrderCriteria;

  public constructor(criteria: OrderCriteria) {
    super();
    this.criteria = criteria;
  }

  protected messageName(): string {
    return "CountOrdersByCriteria";
  }
}

export default CountOrdersByCriteria;
