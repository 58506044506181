import { QueryHandler } from "@lookiero/messaging.js";
import BundleTagView from "../../model/BundleTagView";
import ListBundleTags from "./ListBundleTags";

class ListTagsHandler implements QueryHandler<ListBundleTags> {
  private bundleTagView: BundleTagView;

  public constructor(bundleTagView: BundleTagView) {
    this.bundleTagView = bundleTagView;
  }

  public async handle(): Promise<string[]> {
    return await this.bundleTagView.list();
  }
}

export default ListTagsHandler;
