import { Command } from "@lookiero/messaging.js";

type MarkOrderLineAsRestockParameters = {
  readonly orderLineId: string;
  readonly group: string;
};

class MarkOrderLineAsRestock extends Command {
  public readonly orderLineId: string;
  public readonly group: string;

  public constructor({ orderLineId, group }: MarkOrderLineAsRestockParameters) {
    super();
    this.orderLineId = orderLineId;
    this.group = group;
  }

  public messageName(): string {
    return "MarkOrderLineAsRestock";
  }
}

export default MarkOrderLineAsRestock;
