import { EventBus } from "@lookiero/messaging.js";
import HttpClient from "@src/shared/delivery/HttpClient";
import Bundle from "@src/core/domain/bundle/model/Bundle";
import BundleRepository from "@src/core/domain/bundle/model/BundleRepository";
import {
  createBundleRequestDtoFromBundle,
  editBundleRequestDtoFromBundle,
  editBundleNoteRequestDtoFromBundle,
} from "./requestBuilder";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpBundleRepository implements BundleRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async get(id: string): Promise<Bundle | undefined> {
    try {
      const response = await this.httpClient.post("/view-bundle-by-id", { id });

      if (!response.ok) {
        throw new Error("obtener lote");
      }

      const { result } = await response.json();

      return new Bundle(
        result.id,
        result.location,
        result.number_of_pallets,
        result.tags,
        result.note,
        result.purchases,
        result.step_fashion_labs,
        result.step_tagging,
      );
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener lote");
    }
  }

  public async create(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/create-bundle", createBundleRequestDtoFromBundle(bundle));

      if (!response.ok) {
        throw new Error("crear lote");
      }
      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("crear lote");
    }
  }

  public async edit(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/edit-bundle", editBundleRequestDtoFromBundle(bundle));

      if (!response.ok) {
        throw new Error("editar lote");
      }

      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("editar lote");
    }
  }

  public async editNote(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/edit-bundle-note", editBundleNoteRequestDtoFromBundle(bundle));

      if (!response.ok) {
        throw new Error("Editar nota del bundle");
      }

      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("Editar nota del bundle");
    }
  }

  public async accomplishStepFashionLabs(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/accomplish-step-fashion-labs", { bundle_id: bundle.id });

      if (!response.ok) {
        throw new Error("siguiente step de fashion labs");
      }

      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("siguiente step de fashion labs");
    }
  }

  public async revertStepFashionLabs(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/revert-step-fashion-labs", { bundle_id: bundle.id });

      if (!response.ok) {
        throw new Error("retroceder step de fashion labs");
      }

      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("retroceder step de fashion labs");
    }
  }

  public async accomplishStepTagging(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/accomplish-step-tagging", { bundle_id: bundle.id });

      if (!response.ok) {
        throw new Error("siguiente step de etiquetado");
      }

      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("siguiente step de etiquetado");
    }
  }

  public async revertStepTagging(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/revert-step-tagging", { bundle_id: bundle.id });

      if (!response.ok) {
        throw new Error("retroceder step de etiquetado");
      }

      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("retroceder step de etiquetado");
    }
  }

  public async changePriority(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/change-bundle-priority", {
        id: bundle.id,
        prev_id: bundle.prevId,
        next_id: bundle.nextId,
      });

      if (!response.ok) {
        throw new Error("cambiar prioridad del lote");
      }

      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("cambiar prioridad del lote");
    }
  }

  public async delete(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/delete-bundle", { id: bundle.id });

      if (!response.ok) {
        throw new Error("eliminar lote");
      }

      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("eliminar lote");
    }
  }

  public async returnPreviousStep(bundle: Bundle): Promise<void> {
    try {
      const response = await this.httpClient.post("/return-previous-step", { id: bundle.id });

      if (!response.ok) {
        throw new Error("volver al Step anterior");
      }

      this.eventBus.publish(bundle.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("volver al Step anterior");
    }
  }
}

export default HttpBundleRepository;
