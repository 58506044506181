import { useEffect } from "react";
import {
  defaultIntegrations,
  init,
  setTag,
  setExtras,
  captureException,
  captureEvent,
  showReportDialog,
} from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Logger from "@src/core/application/logger/model/Logger";
import LoggerError from "@src/core/application/logger/model/LoggerError";
import LoggerDomainEvent from "@src/core/application/logger/model/LoggerDomainEvent";
import { Integration, SeverityLevel } from "@sentry/types";
import { reactRouterV6Instrumentation } from "./reactRouterV6Instrumentation";
import { matchRoutes, useLocation, useNavigationType } from "react-router-dom";

const SENTRY_LOGGER_LAYER = "layer";

const LEVEL_INFO: SeverityLevel = "info";

interface Integrations {
  (): Integration[];
}

const browserTracing = new BrowserTracing({
  routingInstrumentation: reactRouterV6Instrumentation({
    useEffect,
    useLocation,
    useNavigationType,
    matchRoutes,
  }),
});

const integrations: Integrations = () => [...defaultIntegrations, browserTracing];

interface SentryLoggerParameters {
  readonly environment: string;
  readonly release: string;
  readonly publicKey: string;
  readonly projectId: string;
}

class SentryLogger implements Logger {
  private readonly release: string;

  public constructor({ environment, release, publicKey, projectId }: SentryLoggerParameters) {
    this.release = release;
    if (environment && release && publicKey && projectId) {
      init({
        environment,
        release,
        dsn: `https://${publicKey}@sentry.io/${projectId}`,
        defaultIntegrations: false,
        integrations: integrations(),
        tracesSampleRate: 0.5,
      });
    }
  }

  public error({ layer, error, info }: LoggerError): string {
    setTag(SENTRY_LOGGER_LAYER, layer);
    if (info) {
      setExtras(info);
    }
    const eventId = captureException(error);

    return eventId;
  }

  // TODO: why its necessary to pass the event as extra?
  public event({ layer, event }: LoggerDomainEvent): void {
    captureEvent({
      release: this.release,
      event_id: event.id(),
      timestamp: event.ocurredOn() / 1000,
      message: event.name(),
      level: LEVEL_INFO,
      tags: {
        [SENTRY_LOGGER_LAYER]: layer,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      extra: event as any,
    });
  }

  public report(id: string): void {
    showReportDialog({ eventId: id });
  }
}

export default SentryLogger;
