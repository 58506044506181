import Purchase, { PurchasedProduct } from "@src/core/domain/purchase/model/Purchase";
import PurchaseStatus from "@src/core/domain/purchase/model/PurchaseStatus";

type PurchaseDto = {
  readonly id: string;
  readonly number: number;
  readonly status: PurchaseStatus;
  readonly priority_position: number;
  readonly received_on: string;
  readonly entry_date: string;
  readonly invoice_date: string | null;
  readonly invoice_number: string;
  readonly provider_delivery_note_date: string | null;
  readonly provider_delivery_note_number: string;
  readonly warehouse_location: string;
  readonly products: [
    {
      readonly id: string;
      readonly provider_product_reference: string;
      readonly title: string;
      readonly restock: boolean;
      readonly product_variants: {
        readonly id: string;
        readonly quantity: number;
        readonly cost_price_currency: string;
        readonly cost_price_amount: number;
      }[];
      readonly medias: {
        readonly id: string;
        readonly url: string;
      }[];
    },
  ];
  readonly segment: string;
};

const purchaseDtoToPurchase = (purchase: PurchaseDto): Purchase =>
  new Purchase(
    purchase.id,
    purchase.number,
    purchase.status,
    purchase.invoice_number,
    purchase.invoice_date ? new Date(purchase.invoice_date) : null,
    purchase.provider_delivery_note_number,
    purchase.provider_delivery_note_date ? new Date(purchase.provider_delivery_note_date) : null,
    new Date(purchase.entry_date),
    purchase.warehouse_location,
    purchase.products.map(product => ({
      id: product.id,
      title: product.title,
      providerProductReference: product.provider_product_reference,
      restock: product.restock,
      medias: product.medias.map(({ id }) => id),
      productVariants: product.product_variants.map(variant => ({
        id: variant.id,
        quantity: variant.quantity,
        costPriceCurrency: variant.cost_price_currency,
        costPriceAmount: variant.cost_price_amount,
      })),
    })) as PurchasedProduct[],
    purchase.segment,
  );

export default purchaseDtoToPurchase;
