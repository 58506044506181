import { EventBus } from "@lookiero/messaging.js";
import Brand from "../../../../domain/brand/model/Brand";
import BrandRepository from "../../../../domain/brand/model/BrandRepository";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import { createBrandRequestDtoFromBrand } from "./requestBuilder";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpBrandRepository implements BrandRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async create(brand: Brand): Promise<void> {
    try {
      const response = await this.httpClient.post("/create-brand", createBrandRequestDtoFromBrand(brand));

      if (!response.ok) {
        throw new Error("crear marca en el servicio");
      }

      this.eventBus.publish(brand.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("crear marca en el servicio");
    }
  }
}

export default HttpBrandRepository;
