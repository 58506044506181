import { CommandHandler } from "@lookiero/messaging.js";
import AddOrderLineFromBuyingAssistant from "./AddOrderLineFromBuyingAssistant";
import OrderLine from "../../model/OrderLine";
import OrderLineRepository from "../../model/OrderLineRepository";

class AddOrderLineFromBuyingAssistantHandler implements CommandHandler<AddOrderLineFromBuyingAssistant> {
  private orderLineRepository: OrderLineRepository;

  public constructor(orderLineRepository: OrderLineRepository) {
    this.orderLineRepository = orderLineRepository;
  }

  public async execute({
    orderId,
    orderLineId,
    familyId,
    providerProductReference,
    medias,
    features,
    neckline,
    targetChannels,
    riskLevel,
    colors,
  }: AddOrderLineFromBuyingAssistant): Promise<void> {
    const orderLine = OrderLine.fromBuyingAssistant(
      orderId,
      orderLineId,
      familyId,
      providerProductReference,
      medias,
      features,
      neckline,
      targetChannels,
      riskLevel,
      colors,
    );

    await this.orderLineRepository.addFromBuyingAssistant(orderLine);
  }
}

export default AddOrderLineFromBuyingAssistantHandler;
