import HttpClient from "@src/shared/delivery/HttpClient";
import Bundle from "@src/core/projection/bundle/model/Bundle";
import BundleView, { PagedBundleCriteria } from "@src/core/projection/bundle/model/BundleView";
import { bundleDtoToBundleProjection } from "./responseMapper";
import { bundleSearchCriteriaDto } from "./requestBuilder";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpBundleView implements BundleView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async viewBundlesPriority(): Promise<string[]> {
    try {
      const response = await this.httpClient.post("/view-bundles-priority", {});

      if (!response.ok) {
        throw new Error("View bundles priorities");
      }

      const result = await response.json();

      return result.result.map((item: { id: string }) => item.id);
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("Search bundles by Criteria");
    }
  }

  public async searchByCriteria(criteria: PagedBundleCriteria): Promise<Bundle[]> {
    try {
      const searchCriteriaDto = bundleSearchCriteriaDto(criteria);
      const response = await this.httpClient.post("/search-bundles-by-criteria", searchCriteriaDto);

      if (!response.ok) {
        throw new Error("Search bundles by Criteria");
      }

      const result = await response.json();

      return result.result.map(bundleDtoToBundleProjection);
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("Search bundles by Criteria");
    }
  }
}

export default HttpBundleView;
