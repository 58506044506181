/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: active eslint rules
import "reflect-metadata";
import { Container, ContainerModule } from "inversify";
import getDecorators from "inversify-inject-decorators";
import { helpers, inversifyInterfaces } from "inversify-vanillajs-helpers";
import { v4 as uuid } from "uuid";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import Webcam from "@uppy/webcam";
import { TYPES } from "./ioc.types";
import { bootstrapWithReact } from "@lookiero/messaging.js";
import packageInfo from "../../../package.json";
import SentryLogger from "../infrastructure/application/logger/model/SentryLogger";

import Environment from "../projection/environment/model/Environment";
import FetchHttpClient from "../../shared/delivery/infrastructure/FetchHttpClient";
import ListBrands from "../projection/brand/query/listBrands/ListBrands";
import ListBrandsHandler from "../projection/brand/query/listBrands/ListBrandsHandler";
import HttpBrandView from "../infrastructure/projection/brand/model/HttpBrandView";
import ListBrandOrigins from "../projection/brand/query/listBrandOrigins/ListBrandOrigins";
import ListBrandOriginsHandler from "../projection/brand/query/listBrandOrigins/ListBrandOriginsHandler";
import InMemoryBrandOriginView from "../infrastructure/projection/brand/model/InMemoryBrandOriginView";
import HttpFamilyView from "../infrastructure/projection/family/model/HttpFamilyView";
import ListFamiliesHandler from "../projection/family/query/listFamilies/ListFamiliesHandler";
import ListFamilies from "../projection/family/query/listFamilies/ListFamilies";
import HttpOrderStatusView from "../infrastructure/projection/orderStatus/model/HttpOrderStatusView";
import ListOrderStatusesHandler from "../projection/orderStatus/query/listOrderStatuses/ListOrderStatusesHandler";
import HttpSeasonView from "../infrastructure/projection/season/model/HttpSeasonView";
import ListChannels from "../projection/channel/query/listChannels/ListChannels";
import ListChannelsHandler from "../projection/channel/query/listChannels/ListChannelsHandler";
import ListOrderLineRiskLevelsHandler from "../projection/orderLine/query/ListOrderLineRiskLevels/ListOrderLineRiskLevelsHandler";
import ListOrderLineRiskLevels from "../projection/orderLine/query/ListOrderLineRiskLevels/ListOrderLineRiskLevels";
import HttpChannelView from "../infrastructure/projection/channel/HttpChannelView";
import HttpBundleTagView from "../infrastructure/projection/bundleTag/model/HttpBundleTagView";
import ListBundleTags from "../projection/bundleTag/query/listTags/ListBundleTags";
import ListBundleTagsHandler from "../projection/bundleTag/query/listTags/ListBundleTagsHandler";
import HttpBundleStepsView from "../infrastructure/projection/bundleSteps/HttpBundleStepsView";
import ListBundleStepsHandler from "../projection/bundleSteps/query/ListBundleStepsHandler";
import ListBundleSteps from "../projection/bundleSteps/query/ListBundleSteps";
import ListOrderStatuses from "../projection/orderStatus/query/listOrderStatuses/ListOrderStatuses";
import ListSeasons from "../projection/season/query/listSeasons/ListSeasons";
import ListSeasonsHandler from "../projection/season/query/listSeasons/ListSeasonsHandler";
import HttpOrderView from "../infrastructure/projection/order/model/HttpOrderView";
import SearchOrdersByCriteriaHandler from "../projection/order/query/searchOrdersByCriteria/SearchOrdersByCriteriaHandler";
import SearchOrdersByCriteria from "../projection/order/query/searchOrdersByCriteria/SearchOrdersByCriteria";
import SearchOrdersByProviderProductReferenceHandler from "../projection/order/query/searchOrdersByProviderProductReference/SearchOrderByProviderProductReferenceHandler";
import SearchOrdersByProviderProductReference from "../projection/order/query/searchOrdersByProviderProductReference/SearchOrderByProviderProductReference";
import CountOrdersByCriteriaHandler from "../projection/order/query/countOrdersByCriteria/CountOrdersByCriteriaHandler";
import CountOrdersByCriteria from "../projection/order/query/countOrdersByCriteria/CountOrdersByCriteria";
import ViewOrderByIdHandler from "../projection/order/query/viewOrderById/ViewOrderByIdHandler";
import ViewOrderById from "../projection/order/query/viewOrderById/ViewOrderById";
import ViewOrderSummaryByIdHandler from "../projection/order/query/viewOrderSummaryById/ViewOrderSummaryByIdHandler";
import ViewOrderSummaryById from "../projection/order/query/viewOrderSummaryById/ViewOrderSummaryById";
import HttpColorView from "../infrastructure/projection/color/model/HttpColorView";
import ListColorsHandler from "../projection/color/query/listColors/ListColorsHandler";
import ListColors from "../projection/color/query/listColors/ListColors";
import HttpSizeView from "../infrastructure/projection/size/model/HttpSizeView";
import ListSizesForFamilyHandler from "../projection/size/query/listSizesForFamily/ListSizesForFamilyHandler";
import ListSizesForFamily from "../projection/size/query/listSizesForFamily/ListSizesForFamily";
import HttpManufacturingCountryView from "../infrastructure/projection/manufacturingCountry/HttpManufacturingCountryView";
import ListManufacturingCountries from "../projection/manufacturingCountry/query/listManufacturingCountries/ListManufacturingCountries";
import ListManufacturingCountriesHandler from "../projection/manufacturingCountry/query/listManufacturingCountries/ListManufacturingCountriesHandler";
import HttpRecommendationView from "../infrastructure/projection/recommendation/model/HttpRecommendationView";
import ListRecommendationsHandler from "../projection/recommendation/query/listRecommendations/ListRecommendationsHandler";
import ListRecommendations from "../projection/recommendation/query/listRecommendations/ListRecommendations";
import HttpFeatureView from "../infrastructure/projection/feature/model/HttpFeatureView";
import SearchFeaturesForBuyingAssistantHandler from "../projection/feature/query/searchFeaturesForBuyingAssistant/searchFeaturesForBuyingAssistantHandler";
import SearchFeaturesForBuyingAssistant from "../projection/feature/query/searchFeaturesForBuyingAssistant/searchFeaturesForBuyingAssistant";
import SearchFeaturesByFamilyHandler from "../projection/feature/query/searchFeaturesByFamily/SearchFeaturesByFamilyHandler";
import SearchFeaturesByFamily from "../projection/feature/query/searchFeaturesByFamily/SearchFeaturesByFamily";
import SearchFeaturesByFamilyForRestockCriteriaHandler from "../projection/feature/query/searchFeaturesByFamilyForRestockCriteria/SearchFeaturesByFamilyForRestockCriteriaHandler";
import SearchFeaturesByFamilyForRestockCriteria from "../projection/feature/query/searchFeaturesByFamilyForRestockCriteria/SearchFeaturesByFamilyForRestockCriteria";
import HttpProviderView from "../infrastructure/projection/provider/model/HttpProviderView";
import ListProvidersHandler from "../projection/provider/query/listProviders/ListProvidersHandler";
import ListProviders from "../projection/provider/query/listProviders/ListProviders";
import SearchProviderOptionsHandler from "../projection/provider/query/searchProviderOptions/SearchProviderOptionsHandler";
import SearchProviderOptions from "../projection/provider/query/searchProviderOptions/SearchProviderOptions";
import CreateProvider from "../domain/provider/command/createProvider/CreateProvider";
import CreateProviderHandler from "../domain/provider/command/createProvider/CreateProviderHandler";
import HttpProviderRepository from "../infrastructure/domain/provider/model/HttpProviderRepository";
import PlaceOrder from "../domain/order/command/placeOrder/PlaceOrder";
import HttpOrderRepository from "../infrastructure/domain/order/model/HttpOrderRepository";
import PlaceOrderHandler from "../domain/order/command/placeOrder/PlaceOrderHandler";
import HttpOrderLineRepository from "../infrastructure/domain/orderLine/model/HttpOrderLineRepository";
import AddOrderLineFromReception from "../domain/orderLine/command/addOrderLineFromReception/AddOrderLineFromReception";
import AddOrderLineFromReceptionHandler from "../domain/orderLine/command/addOrderLineFromReception/AddOrderLineFromReceptionHandler";
import AddOrderLineFromBuyingAssistant from "../domain/orderLine/command/addOrderLineFromBuyingAssistant/AddOrderLineFromBuyingAssistant";
import AddOrderLineFromBuyingAssistantHandler from "../domain/orderLine/command/addOrderLineFromBuyingAssistant/AddOrderLineFromBuyingAssistantHandler";
import EditOrderLineFromReception from "../domain/orderLine/command/editOrderLineFromReception/EditOrderLineFromReception";
import EditOrderLineFromReceptionHandler from "../domain/orderLine/command/editOrderLineFromReception/EditOrderLineFromReceptionHandler";
import EditOrderLineFromBuyingAssistant from "../domain/orderLine/command/editOrderLineFromBuyingAssistant/EditOrderLineFromBuyingAssistant";
import EditOrderLineFromBuyingAssistantHandler from "../domain/orderLine/command/editOrderLineFromBuyingAssistant/EditOrderLineFromBuyingAssistantHandler";
import RemoveOrderLineHandler from "../domain/orderLine/command/removeOrderLine/RemoveOrderLineHandler";
import RemoveOrderLine from "../domain/orderLine/command/removeOrderLine/RemoveOrderLine";
import CloseOrderLineHandler from "../domain/orderLine/command/closeOrderLine/CloseOrderLineHandler";
import CloseOrderLine from "../domain/orderLine/command/closeOrderLine/CloseOrderLine";
import CancelOrderLineHandler from "../domain/orderLine/command/cancelOrderLine/CancelOrderLineHandler";
import CancelOrderLine from "../domain/orderLine/command/cancelOrderLine/CancelOrderLine";
import HttpOrderLineView from "../infrastructure/projection/orderLine/model/HttpOrderLineView";
import ViewOrderLineByIdHandler from "../projection/orderLine/query/viewOrderLineById/ViewOrderLineByIdHandler";
import ViewOrderLineById from "../projection/orderLine/query/viewOrderLineById/ViewOrderLineById";
import CountOrderLinesByCriteriaHandler from "../projection/orderLine/query/countOrderLinesByCriteria/CountOrderLinesByCriteriaHandler";
import CountOrderLinesByCriteria from "../projection/orderLine/query/countOrderLinesByCriteria/CountOrderLinesByCriteria";
import SearchOrderLinesByCriteriaHandler from "../projection/orderLine/query/searchOrderLinesbyCriteria/SearchOrderLinesbyCriteriaHandler";
import SearchOrderLinesByCriteria from "../projection/orderLine/query/searchOrderLinesbyCriteria/SearchOrderLinesbyCriteria";
import SuggestDeliveryEstimationHandler from "../projection/orderLine/query/suggestDeliveryEstimation/SuggestDeliveryEstimationHandler";
import SuggestDeliveryEstimation from "../projection/orderLine/query/suggestDeliveryEstimation/SuggestDeliveryEstimation";
import HttpOrderLineStatusView from "../infrastructure/projection/orderLineStatus/model/HttpOrderLineStatusView";
import ListOrderLineStatusesForPurchaseReceptionHandler from "../projection/orderLineStatus/query/listOrderLineStatusesForPurchaseReception/ListOrderLineStatusesForPurchaseReceptionHandler";
import ListOrderLineStatusesForPurchaseReception from "../projection/orderLineStatus/query/listOrderLineStatusesForPurchaseReception/ListOrderLineStatusesForPurchaseReception";
import HttpMediaView from "../infrastructure/projection/media/model/HttpMediaView";
import ViewMediaByIdHandler from "../projection/media/query/viewMediaById/ViewMediaByIdHandler";
import ViewMediaById from "../projection/media/query/viewMediaById/ViewMediaById";
import StartOrderHandler from "../domain/order/command/startOrder/StartOrderHandler";
import StartOrder from "../domain/order/command/startOrder/StartOrder";
import CompleteOrderHandler from "../domain/order/command/completeOrder/CompleteOrderHandler";
import CompleteOrder from "../domain/order/command/completeOrder/CompleteOrder";
import RegisterOrderHandler from "../domain/order/command/registerOrder/RegisterOrderHandler";
import RegisterOrder from "../domain/order/command/registerOrder/RegisterOrder";
import HttpRestockProductView from "../infrastructure/projection/restockProduct/model/HttpRestockProductView";
import SearchProductsByRestockCriteriaHandler from "../projection/restockProduct/query/searchProductsByRestockCriteria/SearchProductsByRestockCriteriaHandler";
import SearchProductsByRestockCriteria from "../projection/restockProduct/query/searchProductsByRestockCriteria/SearchProductsByRestockCriteria";
import HttpNecklineView from "../infrastructure/projection/neckline/HttpNecklineView";
import SearchNecklinesByFamilyHandler from "../projection/neckline/query/searchNecklineByFamily/SearchNecklinesByFamilyHandler";
import SearchNecklinesByFamily from "../projection/neckline/query/searchNecklineByFamily/SearchNecklinesByFamily";
import MarkOrderLineAsRestockHandler from "../domain/orderLine/command/markOrderLineAsRestock/MarkOrderLineAsRestockHandler";
import MarkOrderLineAsRestock from "../domain/orderLine/command/markOrderLineAsRestock/MarkOrderLineAsRestock";
import UnmarkOrderLineAsRestockHandler from "../domain/orderLine/command/unmarkOrderLineAsRestock/UnmarkOrderLineAsRestockHandler";
import UnmarkOrderLineAsRestock from "../domain/orderLine/command/unmarkOrderLineAsRestock/UnmarkOrderLineAsRestock";
import ViewRestockProductOfGroup from "../projection/restockProduct/query/viewRestockProductOfGroup/ViewRestockProductOfGroup";
import ViewRestockProductOfGroupHandler from "../projection/restockProduct/query/viewRestockProductOfGroup/ViewRestockProductOfGroupHandler";
import HttpBrandRepository from "../infrastructure/domain/brand/model/HttpBrandRepository";
import CreateBrand from "../domain/brand/command/createBrand/CreateBrand";
import CreateBrandHandler from "../domain/brand/command/createBrand/CreateBrandHandler";
import HttpPurchaseRepository from "../infrastructure/domain/purchase/model/HttpPurchaseRepository";
import ReceivePurchaseHandler from "../domain/purchase/command/receivePurchase/ReceivePurchaseHandler";
import ReceivePurchase from "../domain/purchase/command/receivePurchase/ReceivePurchase";
import EditPurchaseHandler from "../domain/purchase/command/editPurchase/EditPurchaseHandler";
import EditPurchase from "../domain/purchase/command/editPurchase/EditPurchase";
import InvoicePurchaseHandler from "../domain/purchase/command/invoicePurchase/InvoicePurchaseHandler";
import InvoicePurchase from "../domain/purchase/command/invoicePurchase/InvoicePurchase";
import HttpPurchaseView from "../infrastructure/projection/purchase/model/HttpPurchaseView";
import ViewPurchaseByIdHandler from "../projection/purchase/query/viewPurchaseById/ViewPurchaseByIdHandler";
import ViewPurchaseById from "../projection/purchase/query/viewPurchaseById/ViewPurchaseById";
import SearchPurchasesByCriteriaHandler from "../projection/purchase/query/searchPurchasesByCriteria/SearchPurchasesByCriteriaHandler";
import SearchPurchasesByCriteria from "../projection/purchase/query/searchPurchasesByCriteria/SearchPurchasesByCriteria";
import CountPurchasesByCriteriaHandler from "../projection/purchase/query/countPurchasesByCriteria/CountPurchasesByCriteriaHandler";
import CountPurchasesByCriteria from "../projection/purchase/query/countPurchasesByCriteria/CountPurchasesByCriteria";
import HttpProductView from "../infrastructure/projection/product/model/HttpProductView";
import ViewProductByIdHandler from "../projection/product/query/viewProductById/ViewProductByIdHandler";
import ViewProductById from "../projection/product/query/viewProductById/ViewProductById";
import HttpProductRepository from "../infrastructure/domain/product/model/HttpProductRepository";
import EditProduct from "../domain/product/command/editProduct/EditProduct";
import EditProductHandler from "../domain/product/command/editProduct/EditProductHandler";
import BulkChangeDeliveryEstimationHandler from "../domain/order/command/bulkChangeDeliveryEstimation/BulkChangeDeliveryEstimationHandler";
import BulkChangeDeliveryEstimation from "../domain/order/command/bulkChangeDeliveryEstimation/BulkChangeDeliveryEstimation";
import HttpEnvironmentFetcher from "../infrastructure/projection/environment/model/HttpEnvironmentFetcher";
import SearchBundlesByCriteriaHandler from "../projection/bundle/query/searchBundlesByCriteria/SearchBundlesByCriteriaHandler";
import SearchBundlesByCriteria from "../projection/bundle/query/searchBundlesByCriteria/SearchBundlesByCriteria";
import ViewBundlePriority from "../projection/bundle/query/viewBundlePriority/ViewBundlePriority";
import ViewBundlePriorityHandler from "../projection/bundle/query/viewBundlePriority/ViewBundlePriorityHandler";
import HttpBundleRepository from "../infrastructure/domain/bundle/model/HttpBundleRepository";
import CreateBundleHandler from "../domain/bundle/command/createBundle/CreateBundleHandler";
import CreateBundle from "../domain/bundle/command/createBundle/CreateBundle";
import EditBundleNoteHandler from "../domain/bundle/command/editBundleNote/EditBundleNoteHandler";
import EditBundleNote from "../domain/bundle/command/editBundleNote/EditBundleNote";
import ChangeBundlePriorityHandler from "../domain/bundle/command/changeBundlePriority/ChangeBundlePriorityHandler";
import ChangeBundlePriority from "../domain/bundle/command/changeBundlePriority/ChangeBundlePriority";
import EditBundle from "../domain/bundle/command/editBundle/EditBundle";
import EditBundleHandler from "../domain/bundle/command/editBundle/EditBundleHandler";
import HttpBundleView from "../infrastructure/projection/bundle/model/HttpBundleView";
import DeleteBundleHandler from "../domain/bundle/command/deleteBundle/DeleteBunbleHandler";
import DeleteBundle from "../domain/bundle/command/deleteBundle/DeleteBundle";
import AccomplishStepFashionLabsHandler from "../domain/bundle/command/accomplishStepFashionLabs/AccomplishStepFashionLabsHandler";
import AccomplishStepTaggingHandler from "../domain/bundle/command/accomplishStepTagging/AccomplishStepTaggingHandler";
import AccomplishStepFashionLabs from "../domain/bundle/command/accomplishStepFashionLabs/AccomplishStepFashionLabs";
import AccomplishStepTagging from "../domain/bundle/command/accomplishStepTagging/AccomplishStepTagging";
import RevertStepFashionLabsHandler from "../domain/bundle/command/revertStepFashionLabs/RevertStepFashionLabsHandler";
import RevertStepTaggingHandler from "../domain/bundle/command/revertStepTagging/RevertStepTaggingHandler";
import RevertStepFashionLabs from "../domain/bundle/command/revertStepFashionLabs/RevertStepFashionLabs";
import RevertStepTagging from "../domain/bundle/command/revertStepTagging/RevertStepTagging";
import LocalStorageClient from "@src/shared/delivery/infrastructure/LocalStorageClient";
import LoginHandler from "../domain/user/command/login/LoginHandler";
import HttpAuthenticator from "../infrastructure/domain/user/model/HttpAuthenticator";
import Login from "../domain/user/command/login/Login";
import JwtAuthFetcher from "@src/shared/delivery/infrastructure/JwtAuthFetcher";
import HttpClient from "@src/shared/delivery/HttpClient";
import InMemoryUserRepository from "@src/shared/infrastructure/domain/user/model/InMemoryUserRepository";
import LogoutHandler from "../domain/user/command/logout/LogoutHandler";
import InMemoryUserView from "../infrastructure/projection/user/model/InMemoryUserView";
import GetCurrentUserHandler from "../projection/user/query/getCurrentUser/GetCurrentUserHandler";
import GetCurrentUser from "../projection/user/query/getCurrentUser/GetCurrentUser";
import IsUserLoggedInHandler from "../projection/user/query/isUserLoggedIn/IsUserLoggedInHandler";
import IsUserLoggedIn from "../projection/user/query/isUserLoggedIn/IsUserLoggedIn";
import Logout from "../domain/user/command/logout/Logout";
import ErrorThrown from "../domain/error/model/ErrorThrown";
import LogoutWhenUnAuthorizedUserError from "../infrastructure/application/LogoutWhenUnAuthorizedUserError";
import ThrowErrorHandler from "../domain/error/command/throwError/ThrowErrorHandler";
import InMemoryErrorRepository from "../infrastructure/domain/error/model/InMemoryErrorRepository";
import ThrowError from "../domain/error/command/throwError/ThrowError";
import ListSegments from "../projection/segment/query/listSegments/ListSegments";
import ListSegmentsHandler from "../projection/segment/query/listSegments/ListSegmentsHandler";
import HttpSegmentView from "../infrastructure/projection/segment/model/HttpSegmentView";
import InMemoryNotificationView from "../infrastructure/projection/notification/model/InMemoryNotificationView";
import CreateNotificationHandler from "../domain/notification/command/createNotification/CreateNotificationHandler";
import RemoveNotificationHandler from "../domain/notification/command/removeNotification/RemoveNotificationHandler";
import InMemoryNotificationRepository from "../infrastructure/domain/notification/model/InMemoryNotificationRepository";
import ListNotificationsHandler from "../projection/notification/query/listNotifications/ListNotificationsHandler";
import ListNotifications from "../projection/notification/query/listNotifications/ListNotifications";
import CreateNotification from "../domain/notification/command/createNotification/CreateNotification";
import RemoveNotification from "../domain/notification/command/removeNotification/RemoveNotification";
import InMemoryStorageClient from "@src/shared/delivery/infrastructure/InMemoryStorageClient";
import CreateNotificationWhenError from "../infrastructure/application/CreateNotificationWhenError";

/* eslint-disable @typescript-eslint/no-explicit-any */
const inSingletonScopeBinding = <T>(binding: inversifyInterfaces.BindingInSyntax<T>): void =>
  void binding.inSingletonScope();

let container: Container;

const bootstrap = async (): Promise<Container> => {
  if (container) {
    return container;
  }
  container = new Container();

  const { lazyInject } = getDecorators(container, true);

  const register = helpers.register(container as any);
  const registerConstantValue = helpers.registerConstantValue(container as any);
  const registerDynamicValue = helpers.registerDynamicValue(container as any);

  const environmentFetcher = new HttpEnvironmentFetcher(new FetchHttpClient());
  const environment = await environmentFetcher.fetch();

  registerDynamicValue(TYPES.Environment, () => ({
    ...environment,
  }));

  register(TYPES.UserRepository, [TYPES.LocalStorageClient], inSingletonScopeBinding)(InMemoryUserRepository);
  register(TYPES.JwtAuthFetch, [TYPES.UserRepository], inSingletonScopeBinding)(JwtAuthFetcher);

  registerDynamicValue(
    TYPES.HttpClient,
    context => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const jwtAuthFetch = context.container.get<JwtAuthFetcher>(TYPES.JwtAuthFetch);
      const fetcher = environment.featureToggle.jwtLoginEnabled ? jwtAuthFetch : undefined;

      return new FetchHttpClient(environment.buyingBackUrl, fetcher);
    },
    inSingletonScopeBinding,
  );

  register(TYPES.HttpAuthenticatorClient, [], inSingletonScopeBinding)(FetchHttpClient);

  registerDynamicValue(
    TYPES.Logger,
    context => {
      const environment = context.container.get<Environment>(TYPES.Environment);

      return new SentryLogger({
        environment: window.location.hostname,
        release: packageInfo.version,
        publicKey: environment.sentryPublicKey,
        projectId: environment.sentryProject,
      });
    },
    inSingletonScopeBinding,
  );

  registerDynamicValue(
    TYPES.Uppy,
    context => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const jwtAuthFetcher = context.container.get<JwtAuthFetcher>(TYPES.JwtAuthFetch);

      const uppy = new Uppy({
        autoProceed: true,
      });

      uppy.use(XHRUpload, {
        endpoint: `${environment.buyingBackUrl}/upload-media`,
        fieldName: "content",
      });

      uppy.use(Webcam, {
        mirror: false,
        modes: ["picture"],
        facingMode: "environment",
      });

      uppy.on("file-added", file => uppy.setFileMeta(file.id, { id: uuid() }));

      if (environment.featureToggle.jwtLoginEnabled) {
        uppy.on("upload", async data => {
          uppy.setState({
            xhrUpload: {
              headers: { ...(await jwtAuthFetcher.getJwtAuthorization()) },
            },
          });
        });
      }
      return uppy;
    },
    inSingletonScopeBinding,
  );

  register(TYPES.BrandView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpBrandView);
  register(TYPES.ListBrandsHandler, [TYPES.BrandView], inSingletonScopeBinding)(ListBrandsHandler);

  register(TYPES.BrandOriginView, [], inSingletonScopeBinding)(InMemoryBrandOriginView);
  register(TYPES.ListBrandOriginsHandler, [TYPES.BrandOriginView], inSingletonScopeBinding)(ListBrandOriginsHandler);

  register(TYPES.FamilyView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpFamilyView);
  register(TYPES.ListFamiliesHandler, [TYPES.FamilyView], inSingletonScopeBinding)(ListFamiliesHandler);

  register(TYPES.OrderStatusView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpOrderStatusView);
  register(TYPES.ListOrderStatusesHandler, [TYPES.OrderStatusView], inSingletonScopeBinding)(ListOrderStatusesHandler);

  register(TYPES.SeasonView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpSeasonView);
  register(TYPES.ListSeasonsHandler, [TYPES.SeasonView], inSingletonScopeBinding)(ListSeasonsHandler);

  register(TYPES.ColorView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpColorView);
  register(TYPES.ListColorsHandler, [TYPES.ColorView], inSingletonScopeBinding)(ListColorsHandler);

  register(TYPES.SizeView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpSizeView);
  register(TYPES.ListSizesForFamilyHandler, [TYPES.SizeView], inSingletonScopeBinding)(ListSizesForFamilyHandler);

  register(TYPES.ChannelView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpChannelView);
  register(TYPES.ListChannelsHandler, [TYPES.ChannelView], inSingletonScopeBinding)(ListChannelsHandler);

  register(TYPES.BundleTagView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpBundleTagView);
  register(TYPES.ListBundleTagsHandler, [TYPES.BundleTagView], inSingletonScopeBinding)(ListBundleTagsHandler);

  register(TYPES.BundleStepView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpBundleStepsView);
  register(TYPES.ListBundleStepsHandler, [TYPES.BundleStepView], inSingletonScopeBinding)(ListBundleStepsHandler);

  register(TYPES.RecommendationView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpRecommendationView);
  register(
    TYPES.ListRecommendationsHandler,
    [TYPES.RecommendationView],
    inSingletonScopeBinding,
  )(ListRecommendationsHandler);

  register(TYPES.MediaView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpMediaView);
  register(TYPES.ViewMediaByIdHandler, [TYPES.MediaView], inSingletonScopeBinding)(ViewMediaByIdHandler);

  register(TYPES.ManufacturingCountryView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpManufacturingCountryView);
  register(
    TYPES.ListManufacturingCountriesHandler,
    [TYPES.ManufacturingCountryView],
    inSingletonScopeBinding,
  )(ListManufacturingCountriesHandler);

  register(TYPES.FeatureView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpFeatureView);
  register(TYPES.NecklineView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpNecklineView);
  register(
    TYPES.SearchNecklinesByFamilyHandler,
    [TYPES.NecklineView],
    inSingletonScopeBinding,
  )(SearchNecklinesByFamilyHandler);
  register(
    TYPES.SearchFeaturesForBuyingAssistantHandler,
    [TYPES.FeatureView],
    inSingletonScopeBinding,
  )(SearchFeaturesForBuyingAssistantHandler);
  register(
    TYPES.SearchFeaturesByFamilyHandler,
    [TYPES.FeatureView],
    inSingletonScopeBinding,
  )(SearchFeaturesByFamilyHandler);
  register(
    TYPES.SearchFeaturesByFamilyForRestockCriteriaHandler,
    [TYPES.FeatureView],
    inSingletonScopeBinding,
  )(SearchFeaturesByFamilyForRestockCriteriaHandler);

  register(TYPES.ProviderView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpProviderView);
  register(TYPES.ListProvidersHandler, [TYPES.ProviderView], inSingletonScopeBinding)(ListProvidersHandler);

  register(
    TYPES.SearchProviderOptionsHandler,
    [TYPES.ProviderView],
    inSingletonScopeBinding,
  )(SearchProviderOptionsHandler);

  register(TYPES.OrderView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpOrderView);
  register(
    TYPES.CountOrdersByCriteriaHandler,
    [TYPES.OrderView],
    inSingletonScopeBinding,
  )(CountOrdersByCriteriaHandler);
  register(
    TYPES.SearchOrdersByCriteriaHandler,
    [TYPES.OrderView],
    inSingletonScopeBinding,
  )(SearchOrdersByCriteriaHandler);
  register(TYPES.ViewOrderSummaryByIdHandler, [TYPES.OrderView], inSingletonScopeBinding)(ViewOrderSummaryByIdHandler);
  register(
    TYPES.SearchOrdersByProviderProductReferenceHandler,
    [TYPES.OrderView],
    inSingletonScopeBinding,
  )(SearchOrdersByProviderProductReferenceHandler);

  register(TYPES.ViewOrderByIdHandler, [TYPES.OrderView], inSingletonScopeBinding)(ViewOrderByIdHandler);

  register(TYPES.OrderLineView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpOrderLineView);

  register(
    TYPES.ListOrderLineRiskLevelsHandler,
    [TYPES.OrderLineView],
    inSingletonScopeBinding,
  )(ListOrderLineRiskLevelsHandler);
  register(TYPES.ViewOrderLineByIdHandler, [TYPES.OrderLineView], inSingletonScopeBinding)(ViewOrderLineByIdHandler);
  register(
    TYPES.CountOrderLinesByCriteriaHandler,
    [TYPES.OrderLineView],
    inSingletonScopeBinding,
  )(CountOrderLinesByCriteriaHandler);
  register(
    TYPES.SearchOrderLinesByCriteriaHandler,
    [TYPES.OrderLineView],
    inSingletonScopeBinding,
  )(SearchOrderLinesByCriteriaHandler);
  register(
    TYPES.SuggestDeliveryEstimationHandler,
    [TYPES.OrderLineView],
    inSingletonScopeBinding,
  )(SuggestDeliveryEstimationHandler);

  register(TYPES.OrderLineStatusView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpOrderLineStatusView);
  register(
    TYPES.ListOrderLineStatusesForPurchaseReceptionHandler,
    [TYPES.OrderLineStatusView],
    inSingletonScopeBinding,
  )(ListOrderLineStatusesForPurchaseReceptionHandler);

  register(TYPES.RestockProductView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpRestockProductView);
  register(
    TYPES.SearchProductsByRestockCriteriaHandler,
    [TYPES.RestockProductView],
    inSingletonScopeBinding,
  )(SearchProductsByRestockCriteriaHandler);
  register(
    TYPES.ViewRestockProductOfGroupHandler,
    [TYPES.RestockProductView],
    inSingletonScopeBinding,
  )(ViewRestockProductOfGroupHandler);

  register(TYPES.PurchaseView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpPurchaseView);
  register(
    TYPES.SearchPurchasesByCriteriaHandler,
    [TYPES.PurchaseView],
    inSingletonScopeBinding,
  )(SearchPurchasesByCriteriaHandler);
  register(
    TYPES.CountPurchasesByCriteriaHandler,
    [TYPES.PurchaseView],
    inSingletonScopeBinding,
  )(CountPurchasesByCriteriaHandler);
  register(TYPES.ViewPurchaseByIdHandler, [TYPES.PurchaseView], inSingletonScopeBinding)(ViewPurchaseByIdHandler);

  register(TYPES.ProductView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpProductView);
  register(TYPES.ViewProductByIdHandler, [TYPES.ProductView], inSingletonScopeBinding)(ViewProductByIdHandler);

  register(TYPES.BundleView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpBundleView);
  register(
    TYPES.SearchBundlesByCriteriaHandler,
    [TYPES.BundleView],
    inSingletonScopeBinding,
  )(SearchBundlesByCriteriaHandler);
  register(TYPES.GetBundlePriorityHandler, [TYPES.BundleView], inSingletonScopeBinding)(ViewBundlePriorityHandler);

  register(TYPES.OrderRepository, [TYPES.HttpClient], inSingletonScopeBinding)(HttpOrderRepository);
  register(TYPES.PlaceOrderHandler, [TYPES.OrderRepository], inSingletonScopeBinding)(PlaceOrderHandler);
  register(TYPES.StartOrderHandler, [TYPES.OrderRepository], inSingletonScopeBinding)(StartOrderHandler);
  register(TYPES.CompleteOrderHandler, [TYPES.OrderRepository], inSingletonScopeBinding)(CompleteOrderHandler);
  register(
    TYPES.BulkChangeDeliveryEstimationHandler,
    [TYPES.OrderRepository],
    inSingletonScopeBinding,
  )(BulkChangeDeliveryEstimationHandler);
  register(TYPES.RegisterOrderHandler, [TYPES.OrderRepository], inSingletonScopeBinding)(RegisterOrderHandler);

  register(TYPES.OrderLineRepository, [TYPES.HttpClient], inSingletonScopeBinding)(HttpOrderLineRepository);
  register(
    TYPES.AddOrderLineFromReceptionHandler,
    [TYPES.OrderLineRepository],
    inSingletonScopeBinding,
  )(AddOrderLineFromReceptionHandler);
  register(
    TYPES.EditOrderLineFromReceptionHandler,
    [TYPES.OrderLineRepository],
    inSingletonScopeBinding,
  )(EditOrderLineFromReceptionHandler);
  register(
    TYPES.AddOrderLineFromBuyingAssistantHandler,
    [TYPES.OrderLineRepository],
    inSingletonScopeBinding,
  )(AddOrderLineFromBuyingAssistantHandler);
  register(
    TYPES.EditOrderLineFromBuyingAssistantHandler,
    [TYPES.OrderLineRepository],
    inSingletonScopeBinding,
  )(EditOrderLineFromBuyingAssistantHandler);
  register(TYPES.RemoveOrderLineHandler, [TYPES.OrderLineRepository], inSingletonScopeBinding)(RemoveOrderLineHandler);
  register(TYPES.CancelOrderLineHandler, [TYPES.OrderLineRepository], inSingletonScopeBinding)(CancelOrderLineHandler);
  register(TYPES.CloseOrderLineHandler, [TYPES.OrderLineRepository], inSingletonScopeBinding)(CloseOrderLineHandler);
  register(
    TYPES.MarkOrderLineAsRestockHandler,
    [TYPES.OrderLineRepository],
    inSingletonScopeBinding,
  )(MarkOrderLineAsRestockHandler);
  register(
    TYPES.UnmarkOrderLineAsRestockHandler,
    [TYPES.OrderLineRepository],
    inSingletonScopeBinding,
  )(UnmarkOrderLineAsRestockHandler);

  register(TYPES.BrandRepository, [TYPES.HttpClient], inSingletonScopeBinding)(HttpBrandRepository);
  register(TYPES.CreateBrandHandler, [TYPES.BrandRepository], inSingletonScopeBinding)(CreateBrandHandler);

  register(TYPES.BundleRepository, [TYPES.HttpClient], inSingletonScopeBinding)(HttpBundleRepository);
  register(TYPES.CreateBundleHandler, [TYPES.BundleRepository], inSingletonScopeBinding)(CreateBundleHandler);
  register(TYPES.EditBundleHandler, [TYPES.BundleRepository], inSingletonScopeBinding)(EditBundleHandler);
  register(TYPES.EditBundleNoteHandler, [TYPES.BundleRepository], inSingletonScopeBinding)(EditBundleNoteHandler);
  register(TYPES.DeleteBundleHandler, [TYPES.BundleRepository], inSingletonScopeBinding)(DeleteBundleHandler);
  register(
    TYPES.AccomplishStepFashionLabsHandler,
    [TYPES.BundleRepository],
    inSingletonScopeBinding,
  )(AccomplishStepFashionLabsHandler);
  register(
    TYPES.RevertStepFashionLabsHandler,
    [TYPES.BundleRepository],
    inSingletonScopeBinding,
  )(RevertStepFashionLabsHandler);
  register(
    TYPES.AccomplishStepTaggingHandler,
    [TYPES.BundleRepository],
    inSingletonScopeBinding,
  )(AccomplishStepTaggingHandler);
  register(TYPES.RevertStepTaggingHandler, [TYPES.BundleRepository], inSingletonScopeBinding)(RevertStepTaggingHandler);
  register(
    TYPES.ChangeBundlePriorityHandler,
    [TYPES.BundleRepository],
    inSingletonScopeBinding,
  )(ChangeBundlePriorityHandler);

  register(TYPES.ProviderRepository, [TYPES.HttpClient], inSingletonScopeBinding)(HttpProviderRepository);
  register(TYPES.CreateProviderHandler, [TYPES.ProviderRepository], inSingletonScopeBinding)(CreateProviderHandler);

  register(TYPES.PurchaseRepository, [TYPES.HttpClient], inSingletonScopeBinding)(HttpPurchaseRepository);
  register(TYPES.ReceivePurchaseHandler, [TYPES.PurchaseRepository], inSingletonScopeBinding)(ReceivePurchaseHandler);
  register(TYPES.EditPurchaseHandler, [TYPES.PurchaseRepository], inSingletonScopeBinding)(EditPurchaseHandler);
  register(TYPES.InvoicePurchaseHandler, [TYPES.PurchaseRepository], inSingletonScopeBinding)(InvoicePurchaseHandler);

  register(TYPES.ProductRepository, [TYPES.HttpClient], inSingletonScopeBinding)(HttpProductRepository);
  register(TYPES.EditProductHandler, [TYPES.ProductRepository], inSingletonScopeBinding)(EditProductHandler);

  registerDynamicValue(
    TYPES.LocalStorageClient,
    () => {
      return new LocalStorageClient("buying_storage");
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.HttpAutheticator,
    context => {
      const httpAuthenticatorClient = context.container.get<HttpClient>(TYPES.HttpAuthenticatorClient);
      const environment = context.container.get<Environment>(TYPES.Environment);

      return new HttpAuthenticator(httpAuthenticatorClient, environment.authApi);
    },
    inSingletonScopeBinding,
  );
  register(TYPES.LoginHandler, [TYPES.UserRepository, TYPES.HttpAutheticator], inSingletonScopeBinding)(LoginHandler);
  register(TYPES.LogoutHandler, [TYPES.UserRepository], inSingletonScopeBinding)(LogoutHandler);
  register(TYPES.UserView, [TYPES.LocalStorageClient], inSingletonScopeBinding)(InMemoryUserView);
  register(
    TYPES.GetCurrentUserHandler,
    [TYPES.UserView, TYPES.LocalStorageClient],
    inSingletonScopeBinding,
  )(GetCurrentUserHandler);
  register(
    TYPES.IsUserLoggedInHandler,
    [TYPES.UserView, TYPES.LocalStorageClient],
    inSingletonScopeBinding,
  )(IsUserLoggedInHandler);

  register(TYPES.LogoutWhenUnAuthorizedUserError, [], inSingletonScopeBinding)(LogoutWhenUnAuthorizedUserError);
  register(TYPES.ErrorRepository, [], inSingletonScopeBinding)(InMemoryErrorRepository);
  register(TYPES.ThrowCoreErrorHandler, [TYPES.ErrorRepository], inSingletonScopeBinding)(ThrowErrorHandler);

  register(TYPES.SegmentView, [TYPES.HttpClient], inSingletonScopeBinding)(HttpSegmentView);
  register(TYPES.ListSegmentsHandler, [TYPES.SegmentView], inSingletonScopeBinding)(ListSegmentsHandler);

  register(
    TYPES.NotificationView,
    [TYPES.NotificationStorageClient],
    inSingletonScopeBinding,
  )(InMemoryNotificationView);
  register(TYPES.ListNotificationsHandler, [TYPES.NotificationView], inSingletonScopeBinding)(ListNotificationsHandler);

  register(TYPES.NotificationStorageClient, [], inSingletonScopeBinding)(InMemoryStorageClient);
  register(
    TYPES.NotificationRepository,
    [TYPES.NotificationStorageClient],
    inSingletonScopeBinding,
  )(InMemoryNotificationRepository);
  register(
    TYPES.CreateNotificationHandler,
    [TYPES.NotificationRepository],
    inSingletonScopeBinding,
  )(CreateNotificationHandler);
  register(
    TYPES.RemoveNotificationHandler,
    [TYPES.NotificationRepository],
    inSingletonScopeBinding,
  )(RemoveNotificationHandler);
  register(TYPES.CreateNotificationWhenError, [], inSingletonScopeBinding)(CreateNotificationWhenError);

  const { commandBus, eventBus, uiEventBus, component } = bootstrapWithReact({
    queries: [
      [ListBrands, container.get(TYPES.ListBrandsHandler)],
      [ListBrandOrigins, container.get(TYPES.ListBrandOriginsHandler)],
      [ListFamilies, container.get(TYPES.ListFamiliesHandler)],
      [ListSeasons, container.get(TYPES.ListSeasonsHandler)],
      [ListColors, container.get(TYPES.ListColorsHandler)],
      [ListChannels, container.get(TYPES.ListChannelsHandler)],
      [ListOrderLineRiskLevels, container.get(TYPES.ListOrderLineRiskLevelsHandler)],
      [ListBundleTags, container.get(TYPES.ListBundleTagsHandler)],
      [ListBundleSteps, container.get(TYPES.ListBundleStepsHandler)],
      [ListSizesForFamily, container.get(TYPES.ListSizesForFamilyHandler)],
      [ListManufacturingCountries, container.get(TYPES.ListManufacturingCountriesHandler)],
      [ListRecommendations, container.get(TYPES.ListRecommendationsHandler)],
      [ViewMediaById, container.get(TYPES.ViewMediaByIdHandler)],
      [SearchNecklinesByFamily, container.get(TYPES.SearchNecklinesByFamilyHandler)],
      [SearchFeaturesForBuyingAssistant, container.get(TYPES.SearchFeaturesForBuyingAssistantHandler)],
      [SearchFeaturesByFamily, container.get(TYPES.SearchFeaturesByFamilyHandler)],
      [SearchFeaturesByFamilyForRestockCriteria, container.get(TYPES.SearchFeaturesByFamilyForRestockCriteriaHandler)],
      [ListProviders, container.get(TYPES.ListProvidersHandler)],
      [SearchProviderOptions, container.get(TYPES.SearchProviderOptionsHandler)],
      [ListOrderStatuses, container.get(TYPES.ListOrderStatusesHandler)],
      [SearchOrdersByCriteria, container.get(TYPES.SearchOrdersByCriteriaHandler)],
      [SearchOrdersByProviderProductReference, container.get(TYPES.SearchOrdersByProviderProductReferenceHandler)],
      [CountOrdersByCriteria, container.get(TYPES.CountOrdersByCriteriaHandler)],
      [ViewOrderById, container.get(TYPES.ViewOrderByIdHandler)],
      [ViewOrderLineById, container.get(TYPES.ViewOrderLineByIdHandler)],
      [CountOrderLinesByCriteria, container.get(TYPES.CountOrderLinesByCriteriaHandler)],
      [SearchOrderLinesByCriteria, container.get(TYPES.SearchOrderLinesByCriteriaHandler)],
      [SuggestDeliveryEstimation, container.get(TYPES.SuggestDeliveryEstimationHandler)],
      [
        ListOrderLineStatusesForPurchaseReception,
        container.get(TYPES.ListOrderLineStatusesForPurchaseReceptionHandler),
      ],
      [SearchProductsByRestockCriteria, container.get(TYPES.SearchProductsByRestockCriteriaHandler)],
      [ViewRestockProductOfGroup, container.get(TYPES.ViewRestockProductOfGroupHandler)],
      [SearchPurchasesByCriteria, container.get(TYPES.SearchPurchasesByCriteriaHandler)],
      [CountPurchasesByCriteria, container.get(TYPES.CountPurchasesByCriteriaHandler)],
      [ViewPurchaseById, container.get(TYPES.ViewPurchaseByIdHandler)],
      [ViewProductById, container.get(TYPES.ViewProductByIdHandler)],
      [ViewOrderSummaryById, container.get(TYPES.ViewOrderSummaryByIdHandler)],
      [SearchBundlesByCriteria, container.get(TYPES.SearchBundlesByCriteriaHandler)],
      [ViewBundlePriority, container.get(TYPES.GetBundlePriorityHandler)],
      [GetCurrentUser, container.get(TYPES.GetCurrentUserHandler)],
      [IsUserLoggedIn, container.get(TYPES.IsUserLoggedInHandler)],
      [ListSegments, container.get(TYPES.ListSegmentsHandler)],
      [ListNotifications, container.get(TYPES.ListNotificationsHandler)],
    ],
    commands: [
      [PlaceOrder, container.get(TYPES.PlaceOrderHandler)],
      [StartOrder, container.get(TYPES.StartOrderHandler)],
      [CompleteOrder, container.get(TYPES.CompleteOrderHandler)],
      [BulkChangeDeliveryEstimation, container.get(TYPES.BulkChangeDeliveryEstimationHandler)],
      [RegisterOrder, container.get(TYPES.RegisterOrderHandler)],
      [AddOrderLineFromReception, container.get(TYPES.AddOrderLineFromReceptionHandler)],
      [EditOrderLineFromReception, container.get(TYPES.EditOrderLineFromReceptionHandler)],
      [AddOrderLineFromBuyingAssistant, container.get(TYPES.AddOrderLineFromBuyingAssistantHandler)],
      [EditOrderLineFromBuyingAssistant, container.get(TYPES.EditOrderLineFromBuyingAssistantHandler)],
      [RemoveOrderLine, container.get(TYPES.RemoveOrderLineHandler)],
      [CancelOrderLine, container.get(TYPES.CancelOrderLineHandler)],
      [CloseOrderLine, container.get(TYPES.CloseOrderLineHandler)],
      [MarkOrderLineAsRestock, container.get(TYPES.MarkOrderLineAsRestockHandler)],
      [UnmarkOrderLineAsRestock, container.get(TYPES.UnmarkOrderLineAsRestockHandler)],
      [CreateBundle, container.get(TYPES.CreateBundleHandler)],
      [EditBundle, container.get(TYPES.EditBundleHandler)],
      [EditBundleNote, container.get(TYPES.EditBundleNoteHandler)],
      [DeleteBundle, container.get(TYPES.DeleteBundleHandler)],
      [AccomplishStepFashionLabs, container.get(TYPES.AccomplishStepFashionLabsHandler)],
      [RevertStepFashionLabs, container.get(TYPES.RevertStepFashionLabsHandler)],
      [AccomplishStepTagging, container.get(TYPES.AccomplishStepTaggingHandler)],
      [RevertStepTagging, container.get(TYPES.RevertStepTaggingHandler)],
      [ChangeBundlePriority, container.get(TYPES.ChangeBundlePriorityHandler)],
      [CreateBrand, container.get(TYPES.CreateBrandHandler)],
      [CreateProvider, container.get(TYPES.CreateProviderHandler)],
      [ReceivePurchase, container.get(TYPES.ReceivePurchaseHandler)],
      [EditPurchase, container.get(TYPES.EditPurchaseHandler)],
      [InvoicePurchase, container.get(TYPES.InvoicePurchaseHandler)],
      [EditProduct, container.get(TYPES.EditProductHandler)],
      [Login, container.get(TYPES.LoginHandler)],
      [Logout, container.get(TYPES.LogoutHandler)],
      [ThrowError, container.get(TYPES.ThrowCoreErrorHandler)],
      [CreateNotification, container.get(TYPES.CreateNotificationHandler)],
      [RemoveNotification, container.get(TYPES.RemoveNotificationHandler)],
    ],
    processManagers: [
      [ErrorThrown, container.get(TYPES.LogoutWhenUnAuthorizedUserError)],
      [ErrorThrown, container.get(TYPES.Logger)],
      [ErrorThrown, container.get(TYPES.CreateNotificationWhenError)],
    ],
  });

  registerConstantValue(TYPES.DomainEventBus, eventBus);
  registerConstantValue(TYPES.UIEventBus, uiEventBus);
  registerConstantValue(TYPES.CommandBus, commandBus);
  registerConstantValue(TYPES.MessagingRootComponent, component);

  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.NotificationRepository), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.OrderRepository), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.OrderLineRepository), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.BrandRepository), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.BundleRepository), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.ProviderRepository), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.PurchaseRepository), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.ProductRepository), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.UserRepository), "eventBus");
  lazyInject(TYPES.DomainEventBus)(container.get(TYPES.ErrorRepository), "eventBus");

  lazyInject(TYPES.CommandBus)(container.get(TYPES.LogoutWhenUnAuthorizedUserError), "commandBus");
  lazyInject(TYPES.CommandBus)(container.get(TYPES.CreateNotificationWhenError), "commandBus");

  return container;
};

export { container };

export default bootstrap;
