import { QueryHandler } from "@lookiero/messaging.js";
import OrderLineView from "../../model/OrderLineView";
import OrderLine from "../../model/OrderLine";
import ViewOrderLineById from "./ViewOrderLineById";

class ViewOrderLineByIdHandler implements QueryHandler<ViewOrderLineById> {
  private readonly orderLineView: OrderLineView;

  public constructor(orderLineView: OrderLineView) {
    this.orderLineView = orderLineView;
  }

  public async handle({ orderLineId }: ViewOrderLineById): Promise<OrderLine | undefined> {
    return await this.orderLineView.ofId(orderLineId);
  }
}

export default ViewOrderLineByIdHandler;
