enum ROUTES {
  HOME = "/",
  LOGIN = "/login",
  BA_ORDERS = "ba/orders",
  BA_ORDER = "ba/order/:id",
  BA_ORDER_LINE = "line/:orderLineId",
  BA_ORDER_LINE_NEW = "line/new",
  ORDERS = "order",
  ORDERS_OVERVIEW = "orders-overview",
  ORDER_LINES_OVERVIEW = "orderlines-overview",
  ORDER = "order/:id",
  ORDER_NEW = "order/new",
  ORDER_LINE = "line/:orderLineId",
  ORDER_LINE_NEW = "order/:id/line/new",
  PURCHASES_OVERVIEW = "purchases/overview",
  PURCHASE = "purchases/:id",
  PRODUCT = "product/:productId",
  PURCHASE_NEW = "purchases/new",
  BUNDLES_OVERVIEW = "bundles/overview",
}

export default ROUTES;
