import HttpClient from "../../../../../shared/delivery/HttpClient";
import OrderView, { OrderCriteria, PagedOrderCriteria } from "../../../../projection/order/model/OrderView";
import OrderPreview from "@src/core/projection/order/model/OrderPreview";
import { Order } from "@src/core/projection/order/model/Order";
import OrderSummary from "@src/core/projection/order/model/OrderSummary";
import OrderIdAndNumber from "@src/core/projection/order/model/OrderIdAndNumber";
import {
  orderResponseDtoToOrderProjection,
  orderPreviewResponseDtoToOrderPreviewProjection,
  orderSummaryResponseDtoToOrderSummary,
  searchOrdersByProviderProductReferenceDtoToOrderIdAndNumber,
} from "./responseMapper";
import { orderCountCriteriaDto, orderSearchCriteriaDto } from "./requestBuilder";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpOrderView implements OrderView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async ofId(orderId: string): Promise<Order | undefined> {
    try {
      const response = await this.httpClient.post("/view-order-by-id", { order_id: orderId });

      if (!response.ok) {
        throw new Error("View Order of Id");
      }

      const { result } = await response.json();

      return result ? orderResponseDtoToOrderProjection(result) : undefined;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("View Order of Id");
    }
  }

  public async countByCriteria(criteria: OrderCriteria): Promise<number> {
    try {
      const response = await this.httpClient.post("/count-orders-by-criteria", orderCountCriteriaDto(criteria));

      if (!response.ok) {
        throw new Error("Count Orders by Criteria");
      }

      const result = await response.json();

      return result.result;
    } catch (err) {
      console.log(err);
      if (err instanceof UnAuthorizedUserError) {
        throw err;
      }
      return 0;
    }
  }

  public async searchByCriteria(criteria: PagedOrderCriteria): Promise<OrderPreview[]> {
    try {
      const response = await this.httpClient.post("/search-orders-by-criteria", orderSearchCriteriaDto(criteria));

      if (!response.ok) {
        throw new Error("Search Orders by Criteria");
      }

      const result = await response.json();

      return result.result.map(orderPreviewResponseDtoToOrderPreviewProjection);
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("Search Orders by Criteria");
    }
  }

  public async viewSummaryById(orderId: string): Promise<OrderSummary> {
    try {
      const response = await this.httpClient.post("/view-order-summary-by-id", { order_id: orderId });

      if (!response.ok) {
        throw new Error("View Order Summary By Id");
      }

      const result = await response.json();
      return orderSummaryResponseDtoToOrderSummary(result.result);
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("View Order Summary By Id");
    }
  }

  public async searchOrdersByProviderProductReference(providerProductReference: string): Promise<OrderIdAndNumber[]> {
    try {
      const response = await this.httpClient.post("/search-orders-by-provider-product-reference", {
        provider_product_reference: providerProductReference,
      });
      if (!response.ok) {
        throw new Error("Search Orders by Provider Product Reference");
      }

      const result = await response.json();

      return searchOrdersByProviderProductReferenceDtoToOrderIdAndNumber(result.result);
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("Search Orders by Provider Product Reference");
    }
  }
}

export default HttpOrderView;
