const QUERYPARAMS = {
  BRAND: "brand",
  ESTIMATED_DELIVERY_DATE: "estimated-delivery-date",
  FAMILY: "family",
  NUMBER: "number",
  PAGE: "page",
  PERPAGE: "perPage",
  PLACED_ON: "placed-on",
  PROVIDER: "provider",
  PROVIDER_PRODUCT_REFERENCE: "provider-product-reference",
  SEASON: "season",
  STATUS: "status",
  ORDER_STATUS: "order-status",
  ORDER_LINE_STATUS: "order-line-status",
  PROVIDER_TYPE: "provider-type",
  INVOICE_NUMBER: "invoice-number",
  RECEPTION_DATE: "reception-date",
  ORDER_TYPE: "order-type",
  GROUP: "group",
  TAGS: "tags",
  STEPFASHIONLABS: "step-fashion-labs",
  STEPTAGGING: "step-tagging",
  BUNDLESEARCHTERM: "bundle-search-term",
  CREATEDON: "bundle-created-on",
};

export type QueryParameter = undefined | null | string | string[];

const read = (locationSearch: string): Record<string, string | string[]> => {
  const searchParams = new URLSearchParams(locationSearch);

  return Array.from(searchParams.keys()).reduce((acc, key) => {
    const value = searchParams.get(key);

    return {
      ...acc,
      ...(value && { [key]: value.includes(",") ? value.split(",") : value }),
    };
  }, {});
};

const produce = (
  params: Record<string, string | string[]>,
  paramsToUpdate: Record<string, QueryParameter>,
): Record<string, string> => {
  const currentParamKeys = Object.keys(paramsToUpdate);

  if (currentParamKeys.length === 0) {
    throw new Error("You cannot provide an empty object as the setParam parameter");
  }

  const updatedParams = {
    ...params,
    ...paramsToUpdate,
  };

  return Object.keys(updatedParams).reduce(
    (acc: Record<string, string>, name: string) => {
      const value = updatedParams[name];

      return {
        ...acc,
        ...(!(value === null || value === undefined || (Array.isArray(value) && value.length === 0)) && {
          [name]: Array.isArray(value) ? value.join(",") : value,
        }),
      };
    },
    {} as Record<string, string>,
  );
};

export { produce, read };

export default QUERYPARAMS;
