enum OrderStatus {
  DRAFT = "DRAFT",
  PLACED = "PLACED",
  REGISTERED = "REGISTERED",
  PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
  FULLY_RECEIVED = "FULLY_RECEIVED",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
}

export default OrderStatus;
