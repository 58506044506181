import React, { MouseEvent, useCallback, useRef, useState } from "react";
import "./LogoutButton.css";
import { Icon, IconVariant, Text, TextVariant } from "@lookiero/react-ui-kit";
import { animated } from "react-spring";
import classNames from "classnames";
import { useClickAway } from "react-use";
import useLogout from "./_behavior/useLogout";
import User from "@src/core/projection/user/model/User";
import { useGetCurrentUser } from "@src/ui/views/_behaviors/userProvider";
import useEnvironment from "@src/ui/views/_behaviors/useEnvironment";

const LogoutButton: React.FC = () => {
  const menuRef = useRef(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const onHandleMenuClickAwayToggled = useCallback((event: Event) => {
    event.stopPropagation();
    setOpenMenu(openedParam => !openedParam);
  }, []);

  const onHandleMenuToggled = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenMenu(openedParam => !openedParam);
  }, []);

  const getAvatar = (user: User) => {
    return user.username.charAt(0).toLocaleUpperCase();
  };

  const { logout } = useLogout();

  const getCurrentUser = useGetCurrentUser();

  const isJwtLoginEnabled = useEnvironment().featureToggle.jwtLoginEnabled;

  useClickAway(menuRef, event => {
    if (openMenu) {
      onHandleMenuClickAwayToggled(event);
    }
  });

  return (
    <>
      {isJwtLoginEnabled && getCurrentUser ? (
        <div className="logout">
          <div
            className={classNames("logout__avatar-container", { "logout__avatar-container--opened": openMenu })}
            onClick={onHandleMenuToggled}
          >
            <div className="logout__avatar-icon">
              <Text variant={TextVariant.BODY}>{getAvatar(getCurrentUser)}</Text>
            </div>
            <Icon variant={IconVariant.MINOR_DROPDOWN} />
          </div>
          <animated.div className={classNames("logout__menu", { "logout__menu--opened": openMenu })} ref={menuRef}>
            <div className="logout__user">
              <div className="logout__avatar-icon">
                <Text variant={TextVariant.BODY}>{getAvatar(getCurrentUser)}</Text>
              </div>
              <Text variant={TextVariant.BODY_SMALL} className="logout__user-name">
                {getCurrentUser.username}
              </Text>
            </div>
            <div className="logout__button-container">
              <div className="logout__button" onClick={logout} role="button">
                <Icon variant={IconVariant.LOGOUT} />
                <Text variant={TextVariant.BODY_SMALL}>Cerrar Sesión</Text>
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
    </>
  );
};

export default LogoutButton;
