import HttpClient from "../../../../../shared/delivery/HttpClient";
import PurchaseView, {
  SearchPurchasesCriteria,
  PagedSearchPurchasesCriteria,
} from "../../../../projection/purchase/model/PurchaseView";
import PurchasePreview from "../../../../projection/purchase/model/PurchasePreview";
import {
  purchaseResponseDtoToPurchaseProjection,
  searchPurchasesByCriteriaPurchaseDtoToPurchasePreviewProjection,
} from "./responseMapper";
import { purchaseCountCriteriaDto, purchaseSearchCriteriaDto } from "./requestBuilder";
import Purchase from "@src/core/projection/purchase/model/Purchase";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpPurchaseView implements PurchaseView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async byId(purchaseId: string): Promise<Purchase | undefined> {
    try {
      const response = await this.httpClient.post("/view-purchase-by-id", { purchase_id: purchaseId });

      if (!response.ok) {
        throw new Error("View Purchase by Id");
      }

      const result = await response.json();

      if (result.result) {
        return purchaseResponseDtoToPurchaseProjection(result.result);
      }

      return undefined;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedUserError ? error : new Error("View Purchase by Id");
    }
  }

  public async countByCriteria(criteria: SearchPurchasesCriteria): Promise<number> {
    try {
      const response = await this.httpClient.post("/count-purchases-by-criteria", purchaseCountCriteriaDto(criteria));

      if (!response.ok) {
        throw new Error("Count Purchases by Criteria");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      console.log(error);
      if (error instanceof UnAuthorizedUserError) {
        throw error;
      }
      return 0;
    }
  }

  public async searchByCriteria(criteria: PagedSearchPurchasesCriteria): Promise<PurchasePreview[]> {
    try {
      const response = await this.httpClient.post("/search-purchases-by-criteria", purchaseSearchCriteriaDto(criteria));

      if (!response.ok) {
        throw new Error("Search Purchases by Criteria");
      }

      const result = await response.json();

      return result.result.map(searchPurchasesByCriteriaPurchaseDtoToPurchasePreviewProjection);
    } catch (error) {
      console.log(error);
      if (error instanceof UnAuthorizedUserError) {
        throw error;
      }
      return [];
    }
  }
}

export default HttpPurchaseView;
