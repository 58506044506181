import { QueryHandler } from "@lookiero/messaging.js";
import ListProviders from "./ListProviders";
import Provider from "../../model/Provider";
import ProviderView from "../../model/ProviderView";

class ListProvidersHandler implements QueryHandler<ListProviders> {
  private providerView: ProviderView;

  public constructor(providerView: ProviderView) {
    this.providerView = providerView;
  }

  public async handle(/*query: ListProviders*/): Promise<Provider[]> {
    return await this.providerView.list();
  }
}

export default ListProvidersHandler;
