import UserRepository from "@src/core/domain/user/model/UserRepository";
import { EventBus } from "@lookiero/messaging.js";
import User from "@src/core/domain/user/model/User";
import StorageClient from "@src/shared/delivery/StorageClient";

type JsonUser = {
  authToken: string;
  username: string;
};
class InMemoryUserRepository implements UserRepository {
  private eventBus!: EventBus;

  private static readonly USER_KEY = "user";

  private readonly userStorageClient: StorageClient;

  constructor(storage: StorageClient) {
    this.userStorageClient = storage;
  }

  public async save(user: User): Promise<void> {
    const userToSave = InMemoryUserRepository.userToJson(user);
    await this.userStorageClient.store(InMemoryUserRepository.USER_KEY, userToSave);

    this.eventBus.publish(user.recordedEvents());

    return Promise.resolve();
  }

  public async remove(user: User): Promise<void> {
    await this.userStorageClient.delete(InMemoryUserRepository.USER_KEY);

    this.eventBus.publish(user.recordedEvents());

    return Promise.resolve();
  }

  public async getCurrentUser(): Promise<User | undefined> {
    return this.userStorageClient.get(InMemoryUserRepository.USER_KEY).then(InMemoryUserRepository.jsonToUser);
  }

  private static userToJson(user: User): JsonUser {
    return {
      authToken: user.authToken || "",
      username: user.username || "",
    };
  }

  private static jsonToUser(jsonUser?: JsonUser): User | undefined {
    return jsonUser ? new User(jsonUser.authToken, jsonUser.username) : undefined;
  }
}

export default InMemoryUserRepository;
