import { CommandHandler } from "@lookiero/messaging.js";
import Bundle from "../../model/Bundle";
import BundleRepository from "../../model/BundleRepository";
import CreateBundle from "./CreateBundle";

class CreateBundleHandler implements CommandHandler<CreateBundle> {
  private bundleRepository: BundleRepository;

  public constructor(bundleRepository: BundleRepository) {
    this.bundleRepository = bundleRepository;
  }

  public async execute({ bundleId, location, numberOfPallets, tags, note, purchases }: CreateBundle): Promise<void> {
    const bundle = Bundle.create(bundleId, location, numberOfPallets, tags, note, purchases);

    await this.bundleRepository.create(bundle);
  }
}

export default CreateBundleHandler;
