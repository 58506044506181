import { Command } from "@lookiero/messaging.js";
import { Feature, ProductVariant } from "../../model/Product";
import { SellingPrices } from "@src/core/projection/prices/model/Prices";

type EditProductParams = {
  readonly productId: string;
  readonly providerProductReference: string;
  readonly title: string;
  readonly manufacturingCountry: string;
  readonly sellingPrices: SellingPrices;
  readonly printed: boolean;
  readonly features: Feature[];
  readonly productVariants: ProductVariant[];
  readonly targetChannels: string[];
};

class EditProduct extends Command {
  public readonly productId: string;
  public readonly providerProductReference: string;
  public readonly title: string;
  public readonly manufacturingCountry: string;
  public readonly sellingPrices: SellingPrices;
  public readonly printed: boolean;
  public readonly features: Feature[];
  public readonly productVariants: ProductVariant[];
  public readonly targetChannels: string[];

  public constructor({
    productId,
    providerProductReference,
    title,
    manufacturingCountry,
    sellingPrices,
    printed,
    features,
    productVariants,
    targetChannels,
  }: EditProductParams) {
    super();

    this.productId = productId;
    this.providerProductReference = providerProductReference;
    this.title = title;
    this.manufacturingCountry = manufacturingCountry;
    this.sellingPrices = sellingPrices;
    this.printed = printed;
    this.features = features;
    this.productVariants = productVariants;
    this.targetChannels = targetChannels;
  }

  public messageName(): string {
    return "EditProduct";
  }
}

export default EditProduct;
