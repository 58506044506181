import { Query } from "@lookiero/messaging.js";

class SuggestDeliveryEstimation extends Query {
  public readonly orderId: string;

  public constructor(orderId: string) {
    super();
    this.orderId = orderId;
  }

  public messageName(): string {
    return "SuggestDeliveryEstimation";
  }
}

export default SuggestDeliveryEstimation;
