import { CommandHandler } from "@lookiero/messaging.js";
import InvoicePurchase from "./InvoicePurchase";
import PurchaseRepository from "../../model/PurchaseRepository";

class InvoicePurchaseHandler implements CommandHandler<InvoicePurchase> {
  private purchaseRepository: PurchaseRepository;

  public constructor(purchaseRepository: PurchaseRepository) {
    this.purchaseRepository = purchaseRepository;
  }

  public async execute({ purchaseId }: InvoicePurchase): Promise<void> {
    const purchase = await this.purchaseRepository.get(purchaseId);

    if (!purchase) {
      return;
    }

    purchase.invoice();

    await this.purchaseRepository.invoice(purchase);
  }
}

export default InvoicePurchaseHandler;
