import { Query } from "@lookiero/messaging.js";

class ViewOrderSummaryById extends Query {
  public readonly orderId: string;

  public constructor(orderId: string) {
    super();
    this.orderId = orderId;
  }

  public messageName(): string {
    return "ViewOrderSummaryById";
  }
}

export default ViewOrderSummaryById;
