import { QueryHandler } from "@lookiero/messaging.js";
import SearchFeaturesForBuyingAssistant from "./searchFeaturesForBuyingAssistant";
import Feature from "../../model/Feature";
import FeatureView from "../../model/FeatureView";

class SearchFeaturesForBuyingAssistantHandler implements QueryHandler<SearchFeaturesForBuyingAssistant> {
  private featureView: FeatureView;

  public constructor(featureView: FeatureView) {
    this.featureView = featureView;
  }

  public async handle(): Promise<Feature[]> {
    return await this.featureView.searchFeaturesForBuyingAssistant();
  }
}

export default SearchFeaturesForBuyingAssistantHandler;
