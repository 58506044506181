import { CommandHandler } from "@lookiero/messaging.js";
import BundleRepository from "../../model/BundleRepository";
import RevertStepTagging from "./RevertStepTagging";

class RevertStepTaggingHandler implements CommandHandler<RevertStepTagging> {
  private bundleRepository: BundleRepository;

  public constructor(bundleRepository: BundleRepository) {
    this.bundleRepository = bundleRepository;
  }

  public async execute({ bundleId }: RevertStepTagging): Promise<void> {
    const bundle = await this.bundleRepository.get(bundleId);

    if (!bundle) {
      return;
    }

    bundle.revertStepTagging();

    await this.bundleRepository.revertStepTagging(bundle);
  }
}

export default RevertStepTaggingHandler;
