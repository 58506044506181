import { DomainEvent } from "@lookiero/messaging.js";

type OrderLineCanceledParameters = {
  readonly orderLineId: string;
  readonly orderId: string;
};

class OrderLineCanceled extends DomainEvent {
  public readonly orderLineId: string;
  public readonly orderId: string;

  public constructor({ orderId, orderLineId }: OrderLineCanceledParameters) {
    super();
    this.orderId = orderId;
    this.orderLineId = orderLineId;
  }

  public messageName(): string {
    return "OrderLineCanceled";
  }
}

export default OrderLineCanceled;
