import { QueryHandler } from "@lookiero/messaging.js";
import PurchaseView from "../../model/PurchaseView";
import CountPurchasesByCriteria from "./CountPurchasesByCriteria";

class CountPurchasesByCriteriaHandler implements QueryHandler<CountPurchasesByCriteria> {
  private readonly purchaseView: PurchaseView;

  public constructor(purchaseView: PurchaseView) {
    this.purchaseView = purchaseView;
  }

  public async handle({ criteria }: CountPurchasesByCriteria): Promise<number> {
    return await this.purchaseView.countByCriteria(criteria);
  }
}

export default CountPurchasesByCriteriaHandler;
