import { CommandHandler } from "@lookiero/messaging.js";
import UserRepository from "@src/core/domain/user/model/UserRepository";
import User from "../../model/User";
import Logout from "./Logout";

class LogoutHandler implements CommandHandler<Logout> {
  private readonly repository: UserRepository;

  constructor(repository: UserRepository) {
    this.repository = repository;
  }

  public async execute(): Promise<void> {
    const user = User.logout();
    return this.repository.remove(user);
  }
}

export default LogoutHandler;
