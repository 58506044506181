import { Command } from "@lookiero/messaging.js";

type CreateBundleParameters = {
  readonly bundleId: string;
  readonly location: string;
  readonly numberOfPallets: number;
  readonly tags: string[];
  readonly note: string;
  readonly purchases: { id: string }[];
};

class CreateBundle extends Command {
  readonly bundleId: string;
  readonly location: string;
  readonly numberOfPallets: number;
  readonly tags: string[];
  readonly note: string;
  readonly purchases: { id: string }[];

  public constructor({ bundleId, location, numberOfPallets, tags, note, purchases }: CreateBundleParameters) {
    super();
    this.bundleId = bundleId;
    this.location = location;
    this.numberOfPallets = numberOfPallets;
    this.tags = tags;
    this.note = note;
    this.purchases = purchases;
  }

  public messageName(): string {
    return "CreateBundle";
  }
}

export default CreateBundle;
