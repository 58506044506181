import Family from "@src/core/projection/family/model/Family";
import OrderLine from "@src/core/projection/orderLine/model/OrderLine";
import OrderLinePreview from "@src/core/projection/orderLine/model/OrderLinePreview";
import { convertDTOToPrices, convertDTOToSellingPrices } from "@src/shared/dataTransformer/prices";
import { PricePropertiesInterface, SellingPrice } from "@src/core/projection/prices/model/Prices";

export type OrderLineResponseDto = {
  readonly id: string;
  readonly status: string;
  readonly provider_product_reference: string;
  readonly title: string;
  readonly delivery_estimation: string | null;
  readonly family: { id: string; name: string; category: string; segment: string };
  readonly group: string;
  readonly printed: boolean;
  readonly cost_price_currency: string;
  readonly cost_price_amount: number;
  readonly target_channels: { id: string; name: string }[];
  readonly manufacturing_country: string;
  readonly units: {
    readonly id: string;
    readonly quantity: number;
    readonly color: { id: string };
    readonly size: { id: string };
  }[];
  readonly medias: {
    readonly id: string;
    readonly url: string;
  }[];
  readonly colors: {
    readonly id: string;
    readonly code: string;
    readonly hexadecimal: string;
    readonly name: string;
    readonly parent: string | null;
  }[];
  readonly features: {
    readonly id: string; // feature_id
    readonly values: {
      readonly id: string; // feature_value_id
      readonly qualifier?: {
        readonly id: string; // feature_id
        readonly values: {
          readonly id: string;
          readonly value: string;
        }[]; // feature_value_id[]
      };
    }[];
  }[];
  readonly neckline: string;
  readonly risk_level: number;
  readonly selling_prices: SellingPrice[];
} & PricePropertiesInterface;

export type OrderLinePreviewResponseDto = {
  readonly order: {
    readonly id: string;
    readonly number: number;
    readonly status: string;
    readonly season: number;
    readonly provider: {
      readonly id: string;
      readonly name: string;
      readonly currency: string;
      readonly operation: string;
    };
    readonly brand_id: string;
  };
  readonly id: string;
  readonly provider_product_reference: string;
  readonly family: {
    readonly id: string;
    readonly name: string;
    readonly segment: string;
  };
  readonly group: string;
  readonly cost_price_currency: string;
  readonly cost_price_amount: number;
  readonly manufacturing_country: string;
  readonly printed: boolean;
  readonly delivery_estimation: string;
  readonly status: string;
  readonly title: string;
  readonly target_channels: {
    readonly id: string;
    readonly name: string;
  }[];
  readonly restock: boolean;
  readonly units: {
    readonly id: string;
    readonly size: { id: string; lookiero: string };
    readonly color: { id: string; name: string };
    readonly quantity: number;
  }[];
  readonly medias: {
    readonly id: string;
    readonly url: string;
  }[];
  readonly features: {
    readonly id: string; // feature_id
    readonly name: string;
    readonly type: string;
    readonly multipe: boolean;
    readonly unit: string;
    readonly values: {
      readonly id: string; // feature_value_id
      readonly value: string;
      readonly qualifier?: {
        readonly id: string; // feature_id
        readonly values: {
          readonly id: string;
          readonly value: string;
        }[]; // feature_value_id[]
      };
    }[];
  }[];
  readonly selling_prices: SellingPrice[];
} & PricePropertiesInterface;

type SuggestDeliveryEstimationDto = {
  readonly delivery_estimation: string | null;
};

const orderLineResponseDtoToOrderLineProjection = (orderLine: OrderLineResponseDto): OrderLine => {
  const prices = convertDTOToPrices(orderLine);
  const sellingPrices = convertDTOToSellingPrices(orderLine);

  return {
    id: orderLine.id,
    status: orderLine.status,
    providerProductReference: orderLine.provider_product_reference,
    title: orderLine.title,
    deliveryEstimation: orderLine.delivery_estimation ? new Date(orderLine.delivery_estimation) : null,
    family: orderLine.family
      ? {
          id: orderLine.family.id,
          name: orderLine.family.name,
          category: orderLine.family.category,
          segment: orderLine.family.segment,
        }
      : ({} as Family),
    group: orderLine.group,
    targetChannels: orderLine.target_channels ? orderLine.target_channels.map(({ id }) => id) : [],
    printed: orderLine.printed,
    costPriceCurrency: orderLine.cost_price_currency,
    costPriceAmount: orderLine.cost_price_amount,
    prices,
    manufacturingCountry: orderLine.manufacturing_country,
    units: orderLine.units
      ? orderLine.units.map(unit => ({
          id: unit.id,
          quantity: unit.quantity,
          colorId: unit.color.id,
          sizeId: unit.size.id,
        }))
      : [],
    medias: orderLine.medias,
    colors: orderLine.colors,
    features: orderLine.features.map(feature => ({ id: feature.id, values: feature.values })),
    neckline: orderLine.neckline,
    riskLevel: orderLine.risk_level,
    sellingPrices,
  };
};

const orderLineResponseDtoToOrderLinePreviewProjection = (orderLine: OrderLinePreviewResponseDto): OrderLinePreview => {
  const prices = convertDTOToPrices(orderLine);
  const sellingPrices = convertDTOToSellingPrices(orderLine);

  return {
    id: orderLine.id,
    group: orderLine.group,
    costPriceCurrency: orderLine.cost_price_currency,
    costPriceAmount: orderLine.cost_price_amount,
    prices,
    deliveryEstimation: new Date(orderLine.delivery_estimation),
    providerProductReference: orderLine.provider_product_reference,
    title: orderLine.title,
    status: orderLine.status,
    medias: orderLine.medias?.map(({ id }) => id) || [],
    manufacturingCountry: orderLine.manufacturing_country,
    features: orderLine.features,
    familyId: orderLine.family?.id,
    printed: orderLine.printed,
    targetChannels: orderLine.target_channels ? orderLine.target_channels.map(({ id }) => id) : [],
    restock: orderLine.restock,
    order: {
      id: orderLine.order.id,
      number: orderLine.order.number,
      season: orderLine.order.season,
      status: orderLine.order.status,
      provider: orderLine.order.provider
        ? {
            id: orderLine.order.provider.id,
            name: orderLine.order.provider.name,
            currency: orderLine.order.provider.currency,
            operation: orderLine.order.provider.operation,
          }
        : undefined,
      brandId: orderLine.order.brand_id,
    },
    units: orderLine.units
      ? orderLine.units.map(({ id, color, size, quantity }) => ({
          id,
          color: {
            id: color.id,
            name: color.name,
          },
          size: {
            id: size.id,
            lookiero: size.lookiero,
          },
          quantity: quantity,
        }))
      : [],
    segment: orderLine.family?.segment,
    sellingPrices,
  };
};

const suggestDeliveryEstimationDtoToDate = (dto: SuggestDeliveryEstimationDto): Date | null =>
  dto.delivery_estimation ? new Date(dto.delivery_estimation) : null;

export {
  orderLineResponseDtoToOrderLineProjection,
  orderLineResponseDtoToOrderLinePreviewProjection,
  suggestDeliveryEstimationDtoToDate,
};
