import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import SegmentView from "@src/core/projection/segment/model/SegmentView";
import { Segment } from "@src/core/projection/segment/model/Segment";

class HttpSegmentView implements SegmentView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Segment> {
    try {
      const response = await this.httpClient.post("/search-segments", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener las segmentos del servicio");
      }

      const result = await response.json();

      return result.result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener las segmentos del servicio");
    }
  }
}

export default HttpSegmentView;
