import React, { useContext, useCallback, useMemo, useState, ReactNode } from "react";
import invariant from "ts-invariant";
import { ReceivedProduct } from "../../_behaviors/useReceivePurchase";

interface PurchaseProductCacheApi {
  readonly cachedProducts: ReceivedProduct[];
  readonly storeProductsInCache: (products: ReceivedProduct[]) => void;
  readonly getCachedProductById: (productId: string) => ReceivedProduct;
  readonly updateCachedProduct: (product: ReceivedProduct) => void;
  readonly clearProductCache: () => void;
}

interface PurchaseProductCacheProps {
  readonly children: ReactNode;
}

const UsePurchaseProductCacheContext = React.createContext<PurchaseProductCacheApi>(
  undefined as unknown as PurchaseProductCacheApi,
);

export const UsePurchaseProductCacheProvider: React.FC<PurchaseProductCacheProps> = ({ children }) => {
  const [products, setProducts] = useState<Record<string, ReceivedProduct>>({});

  const cachedProducts = useMemo(() => {
    return Object.values(products);
  }, [products]);

  const storeProductsInCache = useCallback(
    (products: ReceivedProduct[]) =>
      setProducts(prevProducts => {
        products.forEach(product => (prevProducts[product.id] = product));
        return { ...prevProducts };
      }),
    [],
  );

  const getCachedProductById = useCallback((id: string) => products[id] || {}, [products]);

  const updateCachedProduct = useCallback(
    (product: ReceivedProduct) => {
      const stale = products[product.id] || {};

      const cleanedProductVariants = product.productVariants.filter(
        variant => Boolean(variant.quantity) && Boolean(variant.costPriceAmount),
      );

      const updated = { ...stale, ...product, productVariants: cleanedProductVariants };

      setProducts(prevProducts => ({ ...prevProducts, [product.id]: updated }));
    },
    [products],
  );

  const clearProductCache = useCallback(() => {
    setProducts({});
  }, []);

  const purchaseProductCacheApi = {
    cachedProducts,
    storeProductsInCache,
    getCachedProductById,
    updateCachedProduct,
    clearProductCache,
  };

  return (
    <UsePurchaseProductCacheContext.Provider value={purchaseProductCacheApi}>
      {children}
    </UsePurchaseProductCacheContext.Provider>
  );
};

const usePurchaseProductCache = (): PurchaseProductCacheApi => {
  const purchaseProductCacheApi = useContext(UsePurchaseProductCacheContext);

  invariant(
    purchaseProductCacheApi,
    "You are calling usePurchaseProductCache hook outside <UsePurchaseProductCacheProvider />",
  );

  return purchaseProductCacheApi;
};

export default usePurchaseProductCache;
