import BrandOrigin from "../../../../projection/brand/model/BrandOrigin";
import BrandOriginView from "../../../../projection/brand/model/BrandOriginView";

class InMemoryBrandOriginView implements BrandOriginView {
  public async list(): Promise<BrandOrigin[]> {
    try {
      const brandOriginList = Object.values(BrandOrigin);

      return Promise.resolve(brandOriginList);
    } catch (error) {
      throw new Error("obtener los origenes de las marcas del servicio");
    }
  }
}

export default InMemoryBrandOriginView;
