import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";
import HttpClient from "../../../../../shared/delivery/HttpClient";
import OrderStatusView from "../../../../projection/orderStatus/model/OrderStatusView";

class HttpOrderStatusView implements OrderStatusView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<string[]> {
    try {
      const response = await this.httpClient.post("/list-order-statuses", {});

      if (!response.ok) {
        throw new Error("obtener los estados de pedido del servicio");
      }

      const { result } = await response.json();

      return result;
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener los estados de pedido del servicio");
    }
  }
}

export default HttpOrderStatusView;
