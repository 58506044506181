import { Command } from "@lookiero/messaging.js";

type ChangeBundlePriorityProps = {
  readonly bundleId: string;
  readonly prevId: string | null;
  readonly nextId: string | null;
};

class ChangeBundlePriority extends Command {
  readonly bundleId: string;
  readonly prevId: string | null;
  readonly nextId: string | null;

  public constructor({ bundleId, prevId, nextId }: ChangeBundlePriorityProps) {
    super();
    this.bundleId = bundleId;
    this.prevId = prevId;
    this.nextId = nextId;
  }

  public messageName(): string {
    return "ChangeBundlePriority";
  }
}

export default ChangeBundlePriority;
