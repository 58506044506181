import { Query } from "@lookiero/messaging.js";
import { PagedOrderLineCriteria } from "../../model/OrderLineView";

class SearchOrderLinesByCriteria extends Query {
  public readonly criteria: PagedOrderLineCriteria;

  public constructor(criteria: PagedOrderLineCriteria) {
    super();
    this.criteria = criteria;
  }

  protected messageName(): string {
    return "SearchOrderLinesByCriteria";
  }
}

export default SearchOrderLinesByCriteria;
