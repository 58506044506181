import { AggregateRoot } from "@lookiero/messaging.js";
import OrderPlaced from "./OrderPlaced";
import OrderStatus from "./../../orderStatus/model/OrderStatus";
import OrderStarted from "./OrderStarted";
import OrderCompleted from "./OrderCompleted";
import OrderRegistered from "./OrderRegistered";
import DeliveryEstimationBulkChanged from "./DeliveryEstimationBulkChanged";
import { Order as OrderReadModel } from "../../../../core/projection/order/model/Order";

class Order extends AggregateRoot {
  public id: string;
  public providerId: string | undefined;
  public seasonId: string;
  public brandId: string;
  public recommendationId: string;
  public placedOn: Date;
  public deliveredOn: Date[];
  public subtotal: number;
  public status: OrderStatus;
  public b2b: boolean | undefined;
  public deliveryEstimation: Date | null;
  public segment: string | undefined;

  public constructor(
    id: string,
    providerId: string | undefined,
    seasonId: string,
    brandId: string,
    recommendationId: string,
    placedOn: Date,
    deliveredOn: Date[],
    subtotal: number,
    status: OrderStatus,
    b2b: boolean | undefined,
    deliveryEstimation: Date | null,
    segment: string | undefined,
  ) {
    super();

    this.id = id;
    this.providerId = providerId;
    this.seasonId = seasonId;
    this.brandId = brandId;
    this.recommendationId = recommendationId;
    this.placedOn = placedOn;
    this.deliveredOn = deliveredOn;
    this.subtotal = subtotal;
    this.status = status;
    this.b2b = b2b;
    this.deliveryEstimation = deliveryEstimation;
    this.segment = segment;
  }

  public static isFullyEditableFromReception(status: OrderStatus): boolean {
    return [OrderStatus.DRAFT, OrderStatus.PLACED].includes(status);
  }

  public static place(
    orderId: string,
    providerId: string | undefined,
    seasonId: string,
    brandId: string,
    recommendationId: string,
    b2b: boolean,
    segment: string,
  ): Order {
    const instance: Order = new Order(
      orderId,
      providerId,
      seasonId,
      brandId,
      recommendationId,
      new Date(),
      [],
      0,
      OrderStatus.PLACED,
      b2b,
      null,
      segment,
    );

    instance.record(new OrderPlaced(instance.id));

    return instance;
  }

  public static start(
    orderId: string,
    seasonId: string,
    brandId: string,
    recommendationId: string,
    b2b: boolean,
    segment: string,
  ): Order {
    const instance: Order = new Order(
      orderId,
      undefined,
      seasonId,
      brandId,
      recommendationId,
      new Date(),
      [],
      0,
      OrderStatus.DRAFT,
      b2b,
      null,
      segment,
    );

    instance.record(new OrderStarted(instance.id));

    return instance;
  }

  public complete(providerId: string, recommendationId: string, b2b: boolean, segment: string): void {
    if (![OrderStatus.DRAFT, OrderStatus.PLACED].includes(this.status)) {
      throw new Error("Error al completar el pedido.");
    }

    this.providerId = providerId;
    this.b2b = b2b;
    this.recommendationId = recommendationId;
    this.segment = segment;

    this.record(new OrderCompleted(this.id));
  }

  public register(): void {
    if (this.status !== OrderStatus.PLACED) {
      throw new Error("Error al registrar el pedido.");
    }

    this.status = OrderStatus.REGISTERED;

    this.record(new OrderRegistered(this.id));
  }

  public static isRegistrable(order: OrderReadModel): boolean {
    if (order.status !== OrderStatus.PLACED) {
      return false;
    }

    return Boolean(order.orderLines.length) && order.orderLines.every(ol => ol.units.length > 0);
  }
  public bulkChangeDeliveryEstimation(deliveryEstimation: Date): void {
    this.deliveryEstimation = deliveryEstimation;

    return this.record(new DeliveryEstimationBulkChanged(this.id));
  }
}
export default Order;
