import { QueryHandler } from "@lookiero/messaging.js";
import SearchProductsByRestockCriteria from "./SearchProductsByRestockCriteria";
import RestockProduct from "../../model/RestockProduct";
import RestockProductView from "../../model/RestockProductView";

class SearchProductsByRestockCriteriaHandler implements QueryHandler<SearchProductsByRestockCriteria> {
  private restockProductView: RestockProductView;

  public constructor(restockProductView: RestockProductView) {
    this.restockProductView = restockProductView;
  }

  public async handle({
    providerProductReference,
    brandId,
    familyId,
    features,
  }: SearchProductsByRestockCriteria): Promise<RestockProduct[]> {
    return await this.restockProductView.listForCriteria({ providerProductReference, brandId, familyId, features });
  }
}

export default SearchProductsByRestockCriteriaHandler;
