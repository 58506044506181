import { Command } from "@lookiero/messaging.js";
import FormFeatureValue from "../../../../../ui/componentLibrary/_common/FormFeatureValue";

export type AddOrderLineFromBuyingAssistantParameters = {
  readonly orderId: string;
  readonly orderLineId: string;
  readonly familyId: string;
  readonly providerProductReference: string;
  readonly medias: string[];
  readonly features: { id: string; values: FormFeatureValue[] }[];
  readonly neckline: string;
  readonly targetChannels: string[];
  readonly riskLevel: number | null;
  readonly colors: string[];
};

class AddOrderLineFromBuyingAssistant extends Command {
  public readonly orderId: string;
  public readonly orderLineId: string;
  public readonly familyId: string;
  public readonly providerProductReference: string;
  public readonly medias: string[];
  public readonly features: { id: string; values: FormFeatureValue[] }[];
  public readonly neckline: string;
  public readonly targetChannels: string[];
  public readonly riskLevel: number | null;
  public readonly colors: string[];

  public constructor({
    orderId,
    orderLineId,
    familyId,
    providerProductReference,
    medias,
    features,
    neckline,
    targetChannels,
    riskLevel,
    colors,
  }: AddOrderLineFromBuyingAssistantParameters) {
    super();
    this.orderId = orderId;
    this.orderLineId = orderLineId;
    this.familyId = familyId;
    this.providerProductReference = providerProductReference;
    this.medias = medias;
    this.features = features;
    this.neckline = neckline;
    this.targetChannels = targetChannels;
    this.riskLevel = riskLevel;
    this.colors = colors;
  }

  public messageName(): string {
    return "AddOrderLineFromBuyingAssistant";
  }
}

export default AddOrderLineFromBuyingAssistant;
