import { AggregateRoot } from "@lookiero/messaging.js";
import OrderLineUnit from "./OrderLineUnit";
import OrderLineAdded from "./OrderLineAdded";
import OrderLineEdited from "./OrderLineEdited";
import OrderLineMarkedAsRestock from "./OrderLineMarkedAsRestock";
import OrderLineUnmarkedAsRestock from "./OrderLineUnmarkedAsRestock";
import OrderLineRemoved from "./OrderLineRemoved";
import OrderLineCanceled from "./OrderLineCanceled";
import OrderLineClosed from "./OrderLineClosed";
import OrderLineStatus from "../../orderLineStatus/model/OrderLineStatus";
import { Prices } from "../../product/model/Product";
import { SellingPrices } from "@src/core/projection/prices/model/Prices";

type FeatureValue = {
  readonly id: string; // feature_value_id
  readonly qualifier?: {
    readonly id: string; // feature_id
    readonly values: { id: string }[]; // feature_value_id
  };
};

type Feature = { id: string; values: FeatureValue[] };

class OrderLine extends AggregateRoot {
  public orderId: string;
  public id: string;
  public providerProductReference: string;
  public title: string;
  public deliveryEstimation: Date | null;
  public familyId: string | null;
  public group: string | null;
  public printed: boolean | null;
  public costPriceCurrency: string | null;
  public costPriceAmount: number | null;
  public sellingPrices: SellingPrices;
  public units: OrderLineUnit[] | null;
  public medias: string[] | null;
  public features: Feature[] | null;
  public manufacturingCountry: string | null;
  public neckline: string | null;
  public targetChannels: string[];
  public riskLevel: number | null;
  public colors: string[] | null;

  public constructor(
    orderId: string,
    id: string,
    providerProductReference: string,
    title: string,
    deliveryEstimation: Date | null,
    familyId: string | null,
    group: string | null,
    printed: boolean | null,
    costPriceCurrency: string | null,
    costPriceAmount: number | null,
    sellingPrices: SellingPrices,
    units: OrderLineUnit[] | null,
    medias: string[] | null,
    features: Feature[] | null,
    manufacturingCountry: string | null,
    neckline: string | null,
    targetChannels: string[],
    riskLevel: number | null,
    colors: string[] | null,
  ) {
    super();

    this.orderId = orderId;
    this.id = id;
    this.providerProductReference = providerProductReference;
    this.title = title;
    this.deliveryEstimation = deliveryEstimation;
    this.familyId = familyId;
    this.group = group;
    this.printed = printed;
    this.costPriceCurrency = costPriceCurrency;
    this.costPriceAmount = costPriceAmount;
    this.sellingPrices = sellingPrices;
    this.units = units;
    this.medias = medias;
    this.features = features;
    this.manufacturingCountry = manufacturingCountry;
    this.neckline = neckline;
    this.targetChannels = targetChannels;
    this.riskLevel = riskLevel;
    this.colors = colors;
  }

  public static fromReception(
    orderId: string,
    id: string,
    providerProductReference: string,
    title: string,
    deliveryEstimation: Date | null,
    familyId: string,
    printed: boolean,
    costPriceCurrency: string,
    costPriceAmount: number,
    sellingPrices: SellingPrices,
    units: OrderLineUnit[],
    features: { id: string; values: FeatureValue[] }[],
    manufacturingCountry: string | null,
    targetChannels: string[],
    riskLevel: number | null,
  ): OrderLine {
    const instance = new OrderLine(
      orderId, // orderId
      id, // id
      providerProductReference, // providerProductReference
      title, // title
      deliveryEstimation, // deliveryEstimation
      familyId, // familyId
      null, // group
      printed, // printed
      costPriceCurrency, // costPriceCurrency
      costPriceAmount, // costPriceAmount
      sellingPrices, // prices
      units, // units
      null, // medias
      features, // features
      manufacturingCountry, // manufacturingCountry
      null, // neckline
      targetChannels, // targetChannels
      riskLevel, // riskLevel
      null, // colors
    );

    instance.record(new OrderLineAdded({ orderId: instance.orderId, orderLineId: instance.id }));

    return instance;
  }

  public editFromReception(
    providerProductReference: string,
    title: string,
    deliveryEstimation: Date,
    familyId: string,
    printed: boolean,
    costPriceCurrency: string,
    costPriceAmount: number,
    sellingPrices: SellingPrices,
    features: Feature[],
    units: OrderLineUnit[],
    manufacturingCountry: string,
    targetChannels: string[],
    riskLevel: number | null,
  ): void {
    this.providerProductReference = providerProductReference;
    this.title = title;
    this.deliveryEstimation = deliveryEstimation;
    this.familyId = familyId;
    this.printed = printed;
    this.costPriceCurrency = costPriceCurrency;
    this.costPriceAmount = costPriceAmount;
    this.sellingPrices = sellingPrices;
    this.features = features;
    this.manufacturingCountry = manufacturingCountry;
    this.units = units;
    this.targetChannels = targetChannels;
    this.riskLevel = riskLevel;

    this.record(new OrderLineEdited({ orderId: this.orderId, orderLineId: this.id }));
  }

  public static fromBuyingAssistant(
    orderId: string,
    orderLineId: string,
    familyId: string,
    providerProductReference: string,
    medias: string[],
    features: Feature[],
    neckline: string,
    targetChannels: string[],
    riskLevel: number | null,
    colors: string[],
  ): OrderLine {
    const instance = new OrderLine(
      orderId, // orderId
      orderLineId, // id
      providerProductReference, // providerProductReference
      "", // title
      null, // deliveryEstimation
      familyId, // familyId
      null, // group
      null, // printed
      null, // costPriceCurrency
      null, // costPriceAmount
      {} as SellingPrices, // prices
      null, // units
      medias, // medias
      features, // features
      null, // manufacturingCountry
      neckline, // neckline
      targetChannels, // targetChannels
      riskLevel, // riskLevel
      colors, // colors
    );

    instance.record(new OrderLineAdded({ orderId: instance.orderId, orderLineId: instance.id }));

    return instance;
  }

  public static isFullyEditableFromBuyingAssistant(status: string): boolean {
    return [OrderLineStatus.DRAFT, OrderLineStatus.PLACED].includes(status);
  }

  public editFromBuyingAssistant(
    familyId: string,
    providerProductReference: string,
    medias: string[],
    features: Feature[],
    colors: string[],
    neckline: string,
    targetChannels: string[],
    riskLevel: number | null,
  ): void {
    this.familyId = familyId;
    this.providerProductReference = providerProductReference;
    this.medias = medias;
    this.features = features;
    this.colors = colors;
    this.neckline = neckline;
    this.targetChannels = targetChannels;
    this.riskLevel = riskLevel;

    this.record(new OrderLineEdited({ orderId: this.orderId, orderLineId: this.id }));
  }

  public markAsRestock(group: string): void {
    this.group = group;

    this.record(new OrderLineMarkedAsRestock({ orderId: this.orderId, orderLineId: this.id }));
  }

  public unmarkAsRestock(): void {
    this.group = null;

    this.record(new OrderLineUnmarkedAsRestock({ orderId: this.orderId, orderLineId: this.id }));
  }

  public remove(): void {
    this.record(new OrderLineRemoved({ orderId: this.orderId, orderLineId: this.id }));
  }

  public cancel(): void {
    this.record(new OrderLineCanceled({ orderId: this.orderId, orderLineId: this.id }));
  }

  public close(): void {
    this.record(new OrderLineClosed({ orderId: this.orderId, orderLineId: this.id }));
  }
}

export default OrderLine;
export type { Feature, Prices };
