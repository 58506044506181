import { AggregateRoot } from "@lookiero/messaging.js";
import BundleCreated from "./BundleCreated";
import BundleEdited from "./BundleEdited";
import BundleNoteEdited from "./BundleNoteEdited";
import StepStatus from "./StepStatus";
import BundlePriorityChanged from "./BundlePriorityChanged";
import BundleDeleted from "./BundleDeleted";
import StepFashionLabsAccomplished from "./StepFashionLabsAccomplished";
import StepTaggingAccomplished from "./StepTaggingAccomplish";
import StepTaggingReverted from "./StepTaggingReverted";
import StepFashionLabsReverted from "./StepFashionLabsReverted";

class Bundle extends AggregateRoot {
  public id: string;
  public location: string;
  public numberOfPallets: number;
  public tags: string[] | null;
  public note: string;
  public purchases: { readonly id: string }[];
  public stepFashionLabs: string;
  public stepTagging: string;
  public prevId: string | null;
  public nextId: string | null;

  public constructor(
    id: string,
    location: string,
    numberOfPallets: number,
    tags: string[] | null,
    note: string,
    purchases: { id: string }[],
    stepFashionLabs: string,
    stepTagging: string,
  ) {
    super();
    this.id = id;
    this.location = location;
    this.numberOfPallets = numberOfPallets;
    this.tags = tags;
    this.note = note;
    this.purchases = purchases;
    this.stepFashionLabs = stepFashionLabs;
    this.stepTagging = stepTagging;
    this.prevId = null;
    this.nextId = null;
  }

  public static create(
    id: string,
    location: string,
    numberOfPallets: number,
    tags: string[] | null,
    note: string,
    purchases: { id: string }[],
  ): Bundle {
    const instance = new Bundle(
      id,
      location,
      numberOfPallets,
      tags,
      note,
      purchases,
      StepStatus.PENDING,
      StepStatus.PENDING,
    );

    instance.record(new BundleCreated(instance.id));

    return instance;
  }

  public edit(location: string, numberOfPallets: number, tags: string[]): void {
    this.location = location;
    this.numberOfPallets = numberOfPallets;
    this.tags = tags;

    this.record(new BundleEdited(this.id));
  }

  public editNote(note: string): void {
    this.note = note;

    this.record(new BundleNoteEdited(this.id));
  }

  public accomplishStepFashionLabs(): void {
    if (this.stepFashionLabs === StepStatus.PENDING) {
      this.stepFashionLabs = StepStatus.STARTED;
    } else {
      this.stepFashionLabs = StepStatus.COMPLETED;
    }

    this.record(new StepFashionLabsAccomplished(this.id));
  }

  public revertStepFashionLabs(): void {
    if (this.stepFashionLabs === StepStatus.STARTED) {
      this.stepFashionLabs = StepStatus.PENDING;
    } else {
      this.stepFashionLabs = StepStatus.STARTED;
    }

    this.record(new StepFashionLabsReverted(this.id));
  }

  public accomplishStepTagging(): void {
    if (this.stepTagging === StepStatus.PENDING) {
      this.stepTagging = StepStatus.STARTED;
    } else {
      this.stepTagging = StepStatus.COMPLETED;
    }

    this.record(new StepTaggingAccomplished(this.id));
  }

  public revertStepTagging(): void {
    if (this.stepTagging === StepStatus.STARTED) {
      this.stepTagging = StepStatus.PENDING;
    } else {
      this.stepTagging = StepStatus.STARTED;
    }

    this.record(new StepTaggingReverted(this.id));
  }

  public changePriority(prevId: string | null, nextId: string | null): void {
    this.prevId = prevId;
    this.nextId = nextId;

    this.record(new BundlePriorityChanged(this.id));
  }

  public delete(): void {
    this.record(new BundleDeleted({ bundleId: this.id }));
  }
}

export default Bundle;
