import { QueryHandler } from "@lookiero/messaging.js";
import BundleView from "../../model/BundleView";
import ViewBundlePriority from "./ViewBundlePriority";

class ViewBundlePriorityHandler implements QueryHandler<ViewBundlePriority> {
  private readonly bundleView: BundleView;

  public constructor(bundleView: BundleView) {
    this.bundleView = bundleView;
  }

  public async handle(): Promise<string[]> {
    return await this.bundleView.viewBundlesPriority();
  }
}

export default ViewBundlePriorityHandler;
