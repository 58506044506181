import { CommandHandler } from "@lookiero/messaging.js";
import OrderRepository from "../../model/OrderRepository";
import BulkChangeDeliveryEstimation from "./BulkChangeDeliveryEstimation";

class BulkChangeDeliveryEstimationHandler implements CommandHandler<BulkChangeDeliveryEstimation> {
  private orderRepository: OrderRepository;

  public constructor(orderRepository: OrderRepository) {
    this.orderRepository = orderRepository;
  }

  public async execute({ orderId, deliveryEstimation }: BulkChangeDeliveryEstimation): Promise<void> {
    const order = await this.orderRepository.get(orderId);

    if (!order) {
      throw new Error(`There is no Order for bulk: ${orderId}`);
    }

    order.bulkChangeDeliveryEstimation(deliveryEstimation);

    await this.orderRepository.bulkChangeDeliveryEstimation(order);
  }
}

export default BulkChangeDeliveryEstimationHandler;
