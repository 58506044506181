import HttpClient from "../../../../../shared/delivery/HttpClient";
import ProviderView from "../../../../projection/provider/model/ProviderView";
import Provider from "../../../../projection/provider/model/Provider";
import {
  providerOptionsResponseDtoToProviderOptionsProjections,
  providerResponseDtoToProviderProjection,
} from "./responseMapper";
import ProviderOptions from "@src/core/projection/provider/model/ProviderOptions";
import UnAuthorizedUserError from "@src/shared/domain/user/model/UnAuthorizedUserError";

class HttpProviderView implements ProviderView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Provider[]> {
    try {
      const response = await this.httpClient.post("/search-providers-by-criteria", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener los proveedores del servicio");
      }

      const result = await response.json();

      return result.result.map(providerResponseDtoToProviderProjection);
    } catch (error) {
      throw error instanceof UnAuthorizedUserError ? error : new Error("obtener los proveedores del servicio");
    }
  }
  public async searchOptions(): Promise<ProviderOptions> {
    try {
      const response = await this.httpClient.post("/search-provider-options", {});

      if (!response.ok) {
        throw new Error("obtener las opciones de proveedores del servicio");
      }

      const { result } = await response.json();

      return providerOptionsResponseDtoToProviderOptionsProjections(result);
    } catch (error) {
      throw error instanceof UnAuthorizedUserError
        ? error
        : new Error("obtener las opciones de proveedores del servicio");
    }
  }
}

export default HttpProviderView;
