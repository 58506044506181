import { Query } from "@lookiero/messaging.js";

export type SearchBundlesByCriteriaParameters = {
  readonly tags?: string[];
  readonly stepFashionLabs?: string;
  readonly stepTagging?: string;
  readonly createdOn?: string;
  readonly brand?: string;
  readonly season?: string;
  readonly page: number;
  readonly perPage: number;
};
class SearchBundlesByCriteria extends Query {
  public readonly tags?: string[];
  public readonly stepFashionLabs?: string;
  public readonly stepTagging?: string;
  public readonly createdOn?: string;
  public readonly brand?: string;
  public readonly season?: string;
  public readonly page: number;
  public readonly perPage: number;

  public constructor({
    tags,
    stepFashionLabs,
    stepTagging,
    createdOn,
    brand,
    season,
    page = 1,
    perPage = -1,
  }: SearchBundlesByCriteriaParameters) {
    super();
    this.stepFashionLabs = stepFashionLabs;
    this.stepTagging = stepTagging;
    this.tags = tags;
    this.brand = brand;
    this.season = season;
    this.createdOn = createdOn;
    this.page = page;
    this.perPage = perPage;
  }
  public messageName(): string {
    return "SearchBundlesByCriteria";
  }
}

export default SearchBundlesByCriteria;
