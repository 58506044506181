import { QueryHandler } from "@lookiero/messaging.js";
import UserView from "../../model/UserView";
import IsUserLoggedIn from "./IsUserLoggedIn";

class IsUserLoggedInHandler implements QueryHandler<IsUserLoggedIn> {
  private readonly view: UserView;

  public constructor(view: UserView) {
    this.view = view;
  }

  public async handle(): Promise<boolean> {
    return await this.view.isUserLoggedIn();
  }
}

export default IsUserLoggedInHandler;
