import { DomainEvent } from "@lookiero/messaging.js";

type OrderLineEditedParameters = {
  readonly orderLineId: string;
  readonly orderId: string;
};

class OrderLineEdited extends DomainEvent {
  public readonly orderId: string;
  public readonly orderLineId: string;

  public constructor({ orderId, orderLineId }: OrderLineEditedParameters) {
    super();
    this.orderId = orderId;
    this.orderLineId = orderLineId;
  }

  public messageName(): string {
    return "OrderLineEdited";
  }
}

export default OrderLineEdited;
