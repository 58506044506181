import { DomainEvent } from "@lookiero/messaging.js";

type BundleDeletedParameters = {
  readonly bundleId: string;
};

class BundleDeleted extends DomainEvent {
  public readonly bundleId: string;

  public constructor({ bundleId }: BundleDeletedParameters) {
    super();
    this.bundleId = bundleId;
  }

  public messageName(): string {
    return "BundleDeleted";
  }
}

export default BundleDeleted;
