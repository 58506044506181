import { CommandHandler } from "@lookiero/messaging.js";
import UserRepository from "@src/core/domain/user/model/UserRepository";
import User from "@src/core/domain/user/model/User";
import Login from "@src/core/domain/user/command/login/Login";
import Authenticator from "../../model/Autheticator";

class LoginHandler implements CommandHandler<Login> {
  private readonly repository: UserRepository;
  private readonly authenticator: Authenticator;

  constructor(repository: UserRepository, authenticator: Authenticator) {
    this.repository = repository;
    this.authenticator = authenticator;
  }

  public async execute({ credentials }: Login): Promise<void> {
    const token = await this.authenticator.authenticate(credentials);
    const user = User.login(token, credentials.username);
    return this.repository.save(user);
  }
}

export default LoginHandler;
