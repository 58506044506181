import { QueryHandler } from "@lookiero/messaging.js";
import BundleStepView from "../model/BundleStepView";
import ListBundleSteps from "./ListBundleSteps";

class ListStepsHandler implements QueryHandler<ListBundleSteps> {
  private bundleStepView: BundleStepView;

  public constructor(bundleStepView: BundleStepView) {
    this.bundleStepView = bundleStepView;
  }

  public async handle(): Promise<string[]> {
    return await this.bundleStepView.list();
  }
}

export default ListStepsHandler;
