import { DomainEvent } from "@lookiero/messaging.js";

type OrderLineClosedParams = {
  readonly orderLineId: string;
  readonly orderId: string;
};

class OrderLineClosed extends DomainEvent {
  public readonly orderId: string;
  public readonly orderLineId: string;

  public constructor({ orderId, orderLineId }: OrderLineClosedParams) {
    super();
    this.orderId = orderId;
    this.orderLineId = orderLineId;
  }

  public messageName(): string {
    return "OrderLineClosed";
  }
}

export default OrderLineClosed;
