import { QueryHandler } from "@lookiero/messaging.js";
import Bundle from "../../model/Bundle";
import BundleView from "../../model/BundleView";
import SearchBundlesByCriteria from "./SearchBundlesByCriteria";

class SearchBundlesByCriteriaHandler implements QueryHandler<SearchBundlesByCriteria> {
  private readonly bundleView: BundleView;

  public constructor(bundleView: BundleView) {
    this.bundleView = bundleView;
  }

  public async handle({
    tags,
    stepFashionLabs,
    stepTagging,
    createdOn,
    brand,
    season,
    page,
    perPage,
  }: SearchBundlesByCriteria): Promise<Bundle[]> {
    return await this.bundleView.searchByCriteria({
      tags,
      stepFashionLabs,
      stepTagging,
      createdOn,
      brand,
      season,
      page,
      perPage,
    });
  }
}

export default SearchBundlesByCriteriaHandler;
