import { QueryHandler } from "@lookiero/messaging.js";
import ListColors from "./ListColors";
import Color from "../../model/Color";
import ColorView from "../../model/ColorView";

class ListColorsHandler implements QueryHandler<ListColors> {
  private colorView: ColorView;

  public constructor(colorView: ColorView) {
    this.colorView = colorView;
  }

  public async handle(/*query: ListColors*/): Promise<Color[]> {
    return await this.colorView.list();
  }
}

export default ListColorsHandler;
