import { CommandHandler } from "@lookiero/messaging.js";
import EditPurchase from "./EditPurchase";
import PurchaseRepository from "../../model/PurchaseRepository";

class EditPurchaseHandler implements CommandHandler<EditPurchase> {
  private purchaseRepository: PurchaseRepository;

  public constructor(purchaseRepository: PurchaseRepository) {
    this.purchaseRepository = purchaseRepository;
  }

  public async execute({
    purchaseId,
    invoiceNumber,
    invoiceDate,
    providerDeliveryNoteNumber,
    providerDeliveryNoteDate,
    entryDate,
    warehouseLocation,
    segment,
  }: EditPurchase): Promise<void> {
    const purchase = await this.purchaseRepository.get(purchaseId);

    if (!purchase) {
      return;
    }

    purchase.edit(
      invoiceNumber,
      invoiceDate,
      providerDeliveryNoteNumber,
      providerDeliveryNoteDate,
      entryDate,
      warehouseLocation,
      segment,
    );

    await this.purchaseRepository.edit(purchase);
  }
}

export default EditPurchaseHandler;
