import { Query } from "@lookiero/messaging.js";

type ViewRestockProductOfGroupParameters = {
  readonly group: string;
};

class ViewRestockProductOfGroup extends Query {
  public readonly group: string;

  public constructor({ group }: ViewRestockProductOfGroupParameters) {
    super();

    this.group = group;
  }

  public messageName(): string {
    return "ViewRestockProductOfGroup";
  }
}

export default ViewRestockProductOfGroup;
