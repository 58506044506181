import { QueryHandler } from "@lookiero/messaging.js";
import PurchasePreview from "../../model/PurchasePreview";
import PurchaseView from "../../model/PurchaseView";
import SearchPurchasesByCriteria from "./SearchPurchasesByCriteria";

class SearchPurchasesByCriteriaHandler implements QueryHandler<SearchPurchasesByCriteria> {
  private readonly purchaseView: PurchaseView;

  public constructor(purchaseView: PurchaseView) {
    this.purchaseView = purchaseView;
  }
  public async handle({ criteria }: SearchPurchasesByCriteria): Promise<PurchasePreview[]> {
    return await this.purchaseView.searchByCriteria(criteria);
  }
}

export default SearchPurchasesByCriteriaHandler;
