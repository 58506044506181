import { CommandHandler } from "@lookiero/messaging.js";
import Provider from "../../model/Provider";
import ProviderRepository from "../../model/ProviderRepository";
import CreateProvider from "./CreateProvider";

class CreateBrandHandler implements CommandHandler<CreateProvider> {
  private providerRepository: ProviderRepository;

  public constructor(providerRepository: ProviderRepository) {
    this.providerRepository = providerRepository;
  }

  public async execute({
    providerId,
    providerName,
    providerAddress,
    providerTin,
    providerCountry,
    providerCurrency,
    providerOperationType,
    providerPaymentMethod,
  }: CreateProvider): Promise<void> {
    const provider = Provider.create(
      providerId,
      providerName,
      providerAddress,
      providerTin,
      providerCountry,
      providerCurrency,
      providerOperationType,
      providerPaymentMethod,
    );

    await this.providerRepository.create(provider);
  }
}

export default CreateBrandHandler;
