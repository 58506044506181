import { Command } from "@lookiero/messaging.js";

type EditBundleNoteParametres = {
  readonly bundleId: string;
  readonly note: string;
};

class EditBundleNote extends Command {
  readonly bundleId: string;
  readonly note: string;

  public constructor({ bundleId, note }: EditBundleNoteParametres) {
    super();
    this.bundleId = bundleId;
    this.note = note;
  }

  public messageName(): string {
    return "EditBundleNote";
  }
}

export default EditBundleNote;
